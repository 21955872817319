<template>
  <v-dialog
      v-model="createOptionDialog"
      width="800"
      persistent
  >
    <template v-slot:activator="{ on, attrs }">
      <v-icon v-bind="attrs" v-on="on" size="35">mdi-plus</v-icon>
    </template>

    <v-card class="d-flex flex-column fill-height">
      <v-overlay :value="loading" absolute>
        <v-progress-circular
            indeterminate
            size="64"
        ></v-progress-circular>
      </v-overlay>
      <v-card-title class="form-header">
        <span style="font-size: 16px">UPLOAD IMAGE</span>
        <v-spacer/>
        <v-btn :disabled="loading" icon small @click="createOptionDialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text class="pa-5 overflow-y-auto" style="height: 520px">
        <v-form v-model="valid" ref="formData" class="fill-height">
          <v-layout class="fill-height column">
            <div style="flex: none; height: 80px; border-bottom: 1px solid lightgray">
              <v-layout align-center class="fill-height">
                <div style="flex: 1" class="flex pl-1">
                  <span style="font-size: 14px; font-weight: bold" class="mt-1">Field</span>
                  <div style="width: 100%;">
                    <v-select
                        clearable
                        outlined
                        dense
                        v-model="field"
                        :items="allowFields"
                        :rules="[rules.requiredItems]"
                        label="Select Field"
                        item-text="name"
                        item-value="alias_name"
                        return-object
                        class="select-box"
                    ></v-select>
                  </div>
                </div>
              </v-layout>
            </div>
            <v-layout class="fill-height pb-2" align-center justify-center>
              <file-upload
                  :disabled="!field"
                  :multiple="field && field.is_multi"
                  extensions="jpg, jpeg, png"
                  style="border-radius: 8px; width: 100%; height: 100%; border: 1px dashed green;"
                  :style="{'border-color': !field ? 'gray' : color}"
                  :maximum="!!field ? 10 : 1"
                  ref="upload"
                  v-model="file"
                  :drop="!!field"
                  class="pt-2"
              >
                <template>
                  <v-layout column justify-center class="py-6 fill-height" style="align-items: center; ">
                    <v-icon size="80">mdi-cloud-upload-outline</v-icon>
                    <h3>Upload image file</h3>
                    <span style="font-size: 13px; color: var(--v-primary-base)">Support file: *.jpg | *.jpeg | *.png</span>
                    <span style="font-size: 13px; color: red" v-if="!field">(Please select the field before selecting the image)</span>
                    <span style="font-size: 13px" v-else>Click to select file or drop it here</span>
                  </v-layout>
                </template>
              </file-upload>
            </v-layout>
            <div style="flex: none; height: 30px; width: 100%" v-if="file && file.length">
              <v-layout class="fill-height">
                <v-spacer/>
                <v-btn
                    small
                    @click="removeCurrentFile"
                    class="elevation-0"
                    dark
                    color="red"
                >
                  <v-icon size="22" class="mr-1">mdi-delete</v-icon>
                  Clear
                </v-btn>
              </v-layout>
            </div>
            <div style="flex: none; max-height: 150px; width: 100%;" v-if="file && file.length" class="mt-3">
              <v-list dense height="100%" class="overflow-y-auto">
                <v-list-item v-for="(image, index) of file" :key="index" style="height: 40px">
                  <v-list-item-avatar>
                    <v-icon :color="image.error ? 'red' : 'green'">{{ image.error ? 'mdi-alert-circle' : 'mdi-image'}}</v-icon>
                  </v-list-item-avatar>
                  <v-list-item-title>
                    {{ image.name }}
                  </v-list-item-title>
                  <v-list-item-action-text style="width: 150px; color: red">
                    {{ image.error ? 'Unsupported file' : '' }}
                  </v-list-item-action-text>
                  <v-list-item-action>
                    <v-btn icon x-small class="elevation-0" dark color="red" @click="file.splice(index, 1)">
                      <v-icon>mdi-close-thick</v-icon>
                    </v-btn>
                  </v-list-item-action>
                </v-list-item>
              </v-list>
            </div>
          </v-layout>
        </v-form>
      </v-card-text>
      <v-divider/>
      <v-card-actions>
        <v-spacer/>
        <v-btn :color="validData ? 'primary' : 'error'" class="ml-1" width="165" @click="saveImage">
          <v-icon class="mr-1">mdi-upload-outline</v-icon>
          Upload
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {
  uploadImage
} from "@/backend"
import VueUploadComponent from 'vue-upload-component'

export default {
  name: "PopupUploadImage",
  components: {
    'file-upload': VueUploadComponent
  },
  data() {
    return {
      field: undefined,
      name: undefined,
      color: 'green',
      loading: false,
      valid: false,
      validData: true,
      file: undefined,
      extensions: ['jpg', 'jpeg', 'png'],
      rules: {
        requiredName: value => (!!value && !!value.trim()) || 'Name is required',
        requiredItems: value => !!value || 'Item is required'
      },
      createOptionDialog: false
    }
  },
  props: {
    currentRecord: {type: Object, default: () => {}},
  },
  computed: {
    allowFields() {
      return this.currentRecord.images.filter(field => field.is_multi || (!field.is_multi && !field.value.length))
    }
  },
  mounted() {
  },
  watch: {
    field (val) {
      this.file = undefined
    },
    file(val) {
      if (val && val.length) {
        this.validData = true
        let totalError = 0
        for (let i = 0; i < val.length; i++) {
          let extension = val[i].name.split('.').pop()
          val[i].error = !this.extensions.some(val => val.toUpperCase() === extension.toUpperCase())
          if (!this.extensions.some(val => val.toUpperCase() === extension.toUpperCase())) totalError = totalError + 1
        }
        if (totalError === this.file.length) this.validData = false
      } else {
        this.validData = false
      }
    },
    createOptionDialog(val) {
      if (!val) {
        this.field = undefined
        this.file = undefined
        this.color = 'green'
        this.validData = false
        this.$refs.formData.resetValidation()
      }
    }
  },
  methods: {
    removeCurrentFile() {
      this.file = undefined
      this.validData = false
      this.color = 'green'
    },
    async saveImage() {
      this.$refs.formData.validate()
      if (!this.valid || !this.validData) return
      try {
        this.loading = true
        for (let i = 0; i < this.file.length; i++) {
          if (!this.file[i].error) {
            const formData = new FormData()
            formData.append('file', this.file[i].file)
            formData.append('alias_name', this.field.alias_name)
            await uploadImage({surveyId: this.currentRecord.survey_id, id: this.currentRecord.id, data: formData})
          }
        }
        this.$store.commit('message/SHOW_SUCCESS', 'Success')
        this.createOptionDialog = false
      } catch (e) {
      } finally {
        this.$emit('refreshData')
        this.loading = false
      }
    }
  }
}
</script>

<style scoped>
/deep/
.edit-input .v-input__control {
  min-height: 0 !important;
  height: 28px !important;
}

/deep/
.file-uploads label {
  cursor: pointer !important;
}
</style>
