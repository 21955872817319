<template>
  <v-dialog
      v-model="deleteOptionDialog"
      max-width="500"
      persistent
  >
    <v-card>
      <v-overlay :value="loading" absolute>
        <v-progress-circular
            indeterminate
            size="64"
        ></v-progress-circular>
      </v-overlay>
      <v-card-title class="popup-header" style="background-color: red">
        <span style="font-size: 16px">CONFIRM DELETE</span>
        <v-spacer/>
        <v-btn :disabled="loading" icon small @click="deleteOptionDialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text class="px-4 py-8 overflow-y-auto" style="max-height: 65vh">
        <v-layout column v-if="ref.surveys && ref.surveys.length">
          <span>You cannot delete this choice because the following surveys are using it:</span>
          <span style="font-weight: bold" v-for="(item, index) in ref.surveys" :key="index">&#9679; {{
              item.name
            }}</span>
        </v-layout>
        <v-layout column v-else-if="ref.children && ref.children.length">
          <span style="color: red; font-size: 16px">You must delete all its children before delete this:</span>
          <span style="font-weight: bold" v-for="(item, index) in ref.children" :key="index">&#9679; {{
              item.name
            }}</span>
        </v-layout>
        <v-layout v-else>
          <span>Are you sure you want to delete the option: <b> {{ currentOption.name }}</b></span>
        </v-layout>
      </v-card-text>

      <v-card-actions class="popup-footer">
        <v-spacer/>
        <v-btn v-if="(!ref.surveys || !ref.surveys.length) && (!ref.children || !ref.children.length)" width="100"
               :loading="loading" class="elevation-0" color="#ff07079c" @click="deleteOption()" dark>
          Delete
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {
  deleteChoice, getRefChoice
} from "@/backend"

export default {
  name: "DeleteItemOption",
  data() {
    return {
      ref: {},
      loading: false,
      deleteOptionDialog: false,
      currentId: undefined,
      currentOption: {}
    }
  },
  mounted() {
  },
  watch: {},
  methods: {
    async openDialog(choice, optionId) {
      this.ref = {}
      this.currentId = optionId
      this.currentOption = JSON.parse(JSON.stringify(choice))
      this.deleteOptionDialog = true
      await this.getRef()
    },
    async getRef() {
      try {
        this.loading = true
        this.ref = {}
        const res = await getRefChoice({id: this.currentId, choiceId: this.currentOption.id})
        this.ref = res.data
      } catch (e) {
      } finally {
        this.loading = false
      }
    },
    async deleteOption() {
      this.loading = true
      try {
        await deleteChoice({choiceId: this.currentOption.id, id: this.currentId})
        this.$store.commit('message/SHOW_SUCCESS', 'Success')
        this.$emit('removeChoice', this.currentOption)
        this.deleteOptionDialog = false
      } catch (e) {
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style scoped>

</style>
