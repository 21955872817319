<template>
  <v-dialog
      v-model="deleteSurveyFormDialog"
      max-width="500"
      persistent
  >
    <v-card>
      <v-card-title class="popup-header" style="background-color: red">
        <span style="font-size: 16px">DELETE SURVEY FORM</span>
        <v-spacer/>
        <v-btn :disabled="loading" icon small @click="deleteSurveyFormDialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text class="px-4 py-8">
        <p style="color: red; font-weight: bold; font-size: 16px" v-if="isAdmin">This action will delete all records of the survey.</p>
        Are you sure you want to delete the survey form: <b>{{ currentSurveyForm.name }}</b>
      </v-card-text>
      <v-card-actions class="popup-footer">
        <v-spacer/>
        <v-btn width="100" class="elevation-0" color="#ff07079c" :loading="loading" @click="deleteSurvey()" dark>Delete</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {
  deleteExampleSurvey,
  deleteSurvey
} from "@/backend";
import {mapState} from "@/store/ults";
export default {
  name: "PopupDeleteUser",
  data () {
    return {
      isAdmin: true,
      loading: false,
      deleteSurveyFormDialog: false,
      currentSurveyForm: {}
    }
  },
  computed: {
    ...mapState("auth", ["currentUser"]),
  },
  mounted() {
    this.isAdmin = this.currentUser.is_admin
  },
  watch: {
  },
  methods: {
    openDialog (surveyForm) {
      this.currentSurveyForm = JSON.parse(JSON.stringify(surveyForm))
      this.deleteSurveyFormDialog = true
    },
    async deleteSurvey () {
      this.loading = true
      try {
        this.isAdmin ? await deleteSurvey(this.currentSurveyForm.id) : await deleteExampleSurvey(this.currentSurveyForm.id)
        this.$store.commit('message/SHOW_SUCCESS', 'Success')
        this.$emit('getSurvey')
        this.deleteSurveyFormDialog = false
      } catch (e) {
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style scoped>

</style>
