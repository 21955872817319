<template>
  <v-dialog
      v-model="updateUserDialog"
      max-width="500"
      persistent
  >
    <UserForm ref="userForm" :loading.sync="loading" :passwordRequired="false" :currentUser.sync="currentUser" :roles="roles" :submit="updateUser" :closeDialog="closeDialog" title="Update user"/>
  </v-dialog>
</template>

<script>
import {
  updateUser
} from "@/backend";
import UserForm from "@/components/home/management/user/userManagement/UserForm";

export default {
  name: "PopupUpdateUser",
  components: {UserForm},
  data() {
    return {
      id: undefined,
      loading: false,
      updateUserDialog: false,
      valid: false,
      currentUser: {},
      rules: {
        requiredUserName: value => (!!value && !!value.trim()) || 'User name is required',
        requiredEmail: value => (!!value && !!value.trim()) || 'Email is required',
        role: value => !!value || 'Role is required'
      },
      phoneRules: [
        v => !!v || 'Phone number is required',
        v => /\(?([0-9]{3})\)?([ .-]?)([0-9]{3})\2([0-9]{4})/.test(v) || 'Phone number must be valid'
      ]
    }
  },
  props: {
    roles: {type: Array, default: () => []},
  },
  mounted() {
  },
  methods: {
    openDialog(user) {
      this.updateUserDialog = true
      this.currentUser = JSON.parse(JSON.stringify(user))
      this.currentUser.role_ids = this.currentUser.roles.map(role => role.id)
      this.$nextTick(() => {
        this.$refs.userForm.setData(this.currentUser)
      })
    },
    async updateUser(data) {
      data.active = this.currentUser.active
      if (!data.password) delete data.password
      try {
        this.loading = true
        await updateUser({
          id: this.currentUser.id,
          data: data
        })
        this.$store.commit('message/SHOW_SUCCESS', 'Success')
        this.$emit('getUser')
        this.updateUserDialog = false
      } catch (e) {
      } finally {
        this.loading = false
      }
    },
    closeDialog() {
      this.updateUserDialog = false
    }
  }
}
</script>

<style scoped>

</style>
