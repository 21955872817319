<template>
  <v-layout style="width: 100%; height: 100%">
    <Option/>
  </v-layout>
</template>

<script>
import Option from '@/components/home/management/option/OptionList'

export default {
    components: { Option }
}
</script>
