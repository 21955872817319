<template>
  <v-dialog
      v-model="createOptionDialog"
      width="800"
      persistent
  >
    <template v-slot:activator="{ on, attrs }">
      <div style="border: #fafafa 2px solid; border-radius: 4px; margin-top: -4px" class="mr-1">
        <v-btn
            :disabled="$attrs['loading']"
            v-bind="attrs"
            v-on="on"
            width="122"
            min-width="0"
            color="info"
            class="elevation-0">
          <v-icon class="mr-1">mdi-form-select</v-icon>
          Create
        </v-btn>
      </div>
    </template>

    <v-card class="d-flex flex-column fill-height">
      <v-overlay :value="loading" absolute>
        <v-progress-circular
            indeterminate
            size="64"
        ></v-progress-circular>
      </v-overlay>
      <v-card-title class="form-header">
        <span style="font-size: 16px">CREATE OPTION</span>
        <v-spacer/>
        <v-btn :disabled="loading" icon small @click="createOptionDialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text class="pa-5 overflow-y-auto" style="height: 58vh">
        <v-form v-model="valid" ref="formData">
          <v-layout class="fill-height" column>
            <div style="flex: none; height: 80px; border-bottom: 1px solid lightgray">
              <v-layout align-center class="fill-height">
                <div style="flex: 1" class="flex pr-1">
                  <span style="font-size: 14px; font-weight: bold" class="mt-1">Name</span>
                  <div style="width: 100%;">
                    <v-text-field class="mt-1" outlined dense v-model="optionName" label="Name" :rules="[rules.requiredName]"></v-text-field>
                  </div>
                </div>
                <div style="flex: 1" class="flex pl-1">
                  <span style="font-size: 14px; font-weight: bold" class="mt-1">Reference</span>
                  <div style="width: 100%;">
                    <v-autocomplete
                        clearable
                        @change="getOptionDetail"
                        class="mt-1 select-box"
                        outlined
                        dense
                        v-model="referenceId"
                        label="Option"
                        :items="allOption"
                        item-text="name"
                        item-value="id">
                    </v-autocomplete>
                  </div>
                </div>
              </v-layout>
            </div>
            <div style="flex: none; height: 60px;">
              <v-layout class="fill-height" align-center justify-end>
                <v-btn color="info" class="ml-1" width="160.27" @click="options.push({name: undefined, ref_ids: []})">Add choice</v-btn>
              </v-layout>
            </div>
            <div class="flex d-flex fill-height flex-column overflow-y-auto">
              <div v-for="(item, index) of options" :key="index">
                <span style="font-size: 14px; font-weight: bold" class="mt-0">Choice {{ index + 1 }}</span>
                <div style="width: 100%;">
                  <v-layout>
                    <v-text-field
                        class="mt-1"
                        outlined
                        dense
                        v-model="options[index].name"
                        :label="'Choice ' + (index + 1)"
                        :rules="[rules.requiredItem, rules.uniqueValue]"
                        style="flex: 1"
                    ></v-text-field>
                    <v-autocomplete
                        deletable-chips
                        small-chips
                        style="flex: 1"
                        v-if="referenceId"
                        multiple
                        class="mt-1 ml-2"
                        outlined
                        dense
                        :rules="[rules.requiredItems]"
                        v-model="options[index].ref_ids"
                        :items="optionDetail"
                        label="Reference"
                        item-text="name"
                        item-value="id"
                        >
                    </v-autocomplete>
                    <v-btn class="mt-1" v-if="options.length > 1" icon color="error" @click="options.splice(index, 1)">
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                  </v-layout>
                </div>
              </div>
            </div>
          </v-layout>
        </v-form>
      </v-card-text>
      <v-divider/>
      <v-card-actions>
        <v-spacer/>
        <v-btn color="primary" class="ml-1" width="160.27" @click="saveOption"><v-icon class="mr-1">mdi-content-save</v-icon>Save</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {
  createOption, getOptionDetail
} from "@/backend"

export default {
  name: "PopupCreateOption",
  components: {
  },
  data() {
    return {
      loading: false,
      valid: false,
      optionDetail: [],
      options: [{name: undefined, ref_ids: []}],
      referenceId: undefined,
      optionName: undefined,
      rules: {
        requiredName: value => (!!value && !!value.trim()) || 'Name is required',
        requiredItem: value => (!!value && !!value.trim()) || 'Item is required',
        uniqueValue: value => this.checkUnique(value) || 'Option name already exists',
        requiredItems: value => value.length || 'Item is required'
      },
      createOptionDialog: false
    }
  },
  props: {
    allOption: {type: Array, default: () => []},
},
  computed: {
  },
  mounted() {
  },
  watch: {
    createOptionDialog (val) {
      if (!val) {
        this.optionName = undefined
        this.referenceId = undefined
        this.options = [{name: undefined, ref_ids: []}]
        this.$refs.formData.resetValidation()
      }
    }
  },
  methods: {
    async getOptionDetail () {
      try {
        if (!this.referenceId) return
        this.loading = true
        const res = await getOptionDetail({id: this.referenceId, data: {per_page: 'all'}})
        this.optionDetail = res.data
      } catch (e) {
      } finally {
        this.loading = false
      }
    },
    checkUnique (optionName) {
      return (this.options.filter(choice => String(choice.name) === String(optionName)).length <= 1)
    },
    async saveOption () {
      this.$refs.formData.validate()
      if (!this.valid) return
      try {
        this.loading = true
        const res = await createOption({
          name: this.optionName,
          ref_id: this.referenceId,
          choices: this.options
        })
        this.$store.commit('message/SHOW_SUCCESS', 'Success')
        this.createOptionDialog = false
        this.$emit('getOption')
      } catch (e) {
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style scoped>
.create-content {
  height: 100%;
  border-radius: 8px;
  border: 1px solid rgba(137, 63, 242, 0.1);
  box-shadow: inset 0 0 4px var(--v-tab-base);
  background-color: #ffffff;
}
/deep/
.edit-input .v-input__control {
  min-height: 0 !important;
  height: 28px !important;
}
</style>
