<template>
  <div style="width: 100%">
    <div
        class="upload-files-dropzone"
        :class="{ 'upload-files-dropzone--highlight': highlight }"
        @drop="dropHandler($event)"
        @dragover="dragOverHandler($event)"
        @dragenter="dragEnterHandler"
        @dragleave="dragLeaveHandler"
        @click.stop="selectFile"
    >
      <div class="normal-text">
        Drop your files here or click to select files.
      </div>
      <div class="second-text">( {{ accept }} )</div>
      <v-icon size="48">mdi-upload</v-icon>
    </div>
    <div  v-if="files.length" class="list-file" style="overflow-y: auto; border: 1px solid lightgrey; border-radius: 5px; margin: 10px">
      <v-list dense width="100%" class="py-0">
        <v-subheader style="background-color: #F1F9FF">
          Files
          <v-spacer/>
          Total: {{files.length}}
        </v-subheader>
        <v-list-item-group color="secondary">
          <v-list-item
              v-for="(file, i) in files"
              :key="i"
          >
            <v-list-item-icon class="mr-3">
              <v-icon color="secondary">mdi-vector-rectangle</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title v-text="file.name"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
      <!--      <div v-for="(file, i) of files" :key="i">{{ file.name }}</div>-->
    </div>
    <input
        ref="file-input"
        type="file"
        :accept="accept"
        style="display: none"
        multiple
        @change.prevent="handleInputFiles($refs['file-input'].files)"
    />
  </div>
</template>

<script>
export default {
  props: {
    accept: {
      type: String,
      default: "*" // e.g. accept=".geojson, .json, .shp, .shx, .dbf, .prj, .cpg, .kml, .kmz"
    },

    multiple: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      highlight: false,
      files: []
    };
  },

  methods: {
    selectFile() {
      this.$refs['file-input'].click()
    },
    dropHandler(ev) {
      // Prevent default behavior (Prevent file from being opened)
      ev.preventDefault();

      const files = [];

      if (ev.dataTransfer.items) {
        // Use DataTransferItemList interface to access the file(s)
        for (let i = 0; i < ev.dataTransfer.items.length; i++) {
          // If dropped items aren't files, reject them
          if (ev.dataTransfer.items[i].kind === "file") {
            const file = ev.dataTransfer.items[i].getAsFile();
            files.push(file);
          }
        }
      } else {
        // Use DataTransfer interface to access the file(s)
        for (let i = 0; i < ev.dataTransfer.files.length; i++) {
          const file = ev.dataTransfer.files[i];
          files.push(file);
        }
      }

      // Pass event to removeDragData for cleanup
      this.removeDragData(ev);

      this.highlight = false;

      this.handleInputFiles(this.validateFiles(files));
    },

    validateFiles(files) {
      return files.filter(f => this.isFileAcceptable(f))
    },

    isFileAcceptable(file) {
      if (this.accept === "*") return true

      return this.accept.includes(`.${this.getFileExtension(file)}`)
    },

    getFileExtension(file) {
      return file.name.split(".").pop()
    },

    dragOverHandler(ev) {
      // Prevent default behavior (Prevent file from being opened)
      ev.preventDefault()
    },

    removeDragData(ev) {
      if (ev.dataTransfer.items) {
        // Use DataTransferItemList interface to remove the drag data
        ev.dataTransfer.items.clear()
      } else {
        // Use DataTransfer interface to remove the drag data
        ev.dataTransfer.clearData()
      }
    },

    dragEnterHandler() {
      this.highlight = true
    },

    dragLeaveHandler() {
      this.highlight = false
    },

    handleInputFiles(files) {
      if (files.length === 0) return

      this.files = this.multiple ? [...files] : files[0]

      this.$emit("change", this.files)

      // MUST assign after set to files and emit change
      this.$refs["file-input"].value = ""
    },

    clear() {
      this.$refs["file-input"].value = ""
      this.files = []
    }
  }
};
</script>

<style scoped>
.layer-upload {
  padding: 5px 10px;
}

.upload-files-dropzone {
  margin: 10px;
  padding: 14px;
  text-align: center;
  cursor: pointer;
  border: 1px dashed #0379cd;
  border-radius: 8px;
}

.upload-icon {
  width: 36px;
  height: 36px;
  font-size: 32px;
  line-height: 36px;
  margin-top: 10px;
}

.upload-files-dropzone * {
  pointer-events: none;
}

.normal-text {
  font-size: 16px;
  color: rgba(26, 213, 26, 0.87);
  line-height: 20px;
}

.second-text {
  font-size: 14px;
  color: rgb(119 151 255 / 99%);
  line-height: 20px;
}
.list-file {
  max-height: 40vh;
}
@media only screen and (max-height: 750px) {
  .list-file {
    max-height: 24vh;
  }
}
</style>
