<template>
  <v-dialog
      v-model="updateRecordDialog"
      max-width="75vw"
      fullscreen
      persistent
  >
    <v-card>
      <v-card-title class="popup-header">
        <span style="font-size: 16px; text-transform: uppercase">Record Detail</span>
        <v-spacer/>
        <v-btn icon small @click="updateRecordDialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text class="pa-4" style="position: relative">
        <v-overlay :value="loading">
          <v-progress-circular
              indeterminate
              size="64"
          ></v-progress-circular>
        </v-overlay>
        <v-layout style="height: calc(100vh - 150px)">
          <div class="d-flex flex-column" style="flex: 2; height: 100%;">
            <div class="flex d-flex pb-2" style="flex: 8; min-height: 0">
              <div class="flex" style="flex: 2">
                <MiniMap
                    ref="map"
                    v-if="updateRecordDialog"
                    :focusable="true"
                    :editable.sync="editable"
                    :modified.sync="modified"
                    :area="currentRecord.area_in_ha"
                    :displayArea="true"
                    @updateGeometry="updateGeometry"/>
              </div>
              <div class="flex d-flex flex-column pl-2" style="flex: 1; border-radius: 8px"
                   v-if="currentRecord.images && currentRecord.images.length">
                <CarouselsImage
                    @getDetail="getDetail"
                    @deleteImage="deleteImage"
                    :currentRecord.sync="currentRecord"
                    :images.sync="listImage"
                    :currentImage.sync="selectedImage"/>
              </div>
            </div>
            <div class="flex pt-2" style="flex: 3; border-radius: 8px">
              <v-data-table
                  class="custom-table"
                  height="100%"
                  style="height: calc(100% - 40px)"
                  hide-default-footer
                  :headers="executiveHeaders"
                  :items="executive"
                  item-key="name"
              >
                <template v-slot:top>
                  <div
                      style="width: 150px; height: 38px; background-color: var(--v-primary-base); border-top-right-radius: 12px;">
                    <v-layout class="fill-height px-2" align-center>
                      <span style="color: #ffffff; text-transform: capitalize; font-size: 16px; font-weight: bold">executive detail</span>
                    </v-layout>
                  </div>
                </template>
                <template v-slot:[`item.dateOfSurvey`]="{ item }">
                  {{ convertDate(currentRecord.created_at) }}
                </template>
              </v-data-table>
            </div>
          </div>
          <div style="flex: 1" class="d-flex flex pl-3 fill-height">
            <div style="width: 100%; height: 100%; border: 1px solid lightgray; border-radius: 8px"
                 class="pa-2 d-flex flex-column">
              <div style="height: 37px; width: 100%; flex: none;">
                <v-layout class="fill-height" justify-end>
                  <div
                      style="height: 38px; width: 28%; min-width: 150px; flex: none; background-color: var(--v-primary-base); border-top-left-radius: 12px; color: white; font-weight: bold"
                      class="px-2">
                    <v-layout align-center class="fill-height">
                      Display mode:
                      <v-spacer/>
                      <v-tooltip bottom color="#00000066">
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn v-bind="attrs" v-on="on" @click="modeTab = !modeTab" small icon color="white">
                            <v-icon size="21">
                              {{ !modeTab ? 'mdi-arrange-send-backward' : 'mdi-format-list-bulleted' }}
                            </v-icon>
                          </v-btn>
                        </template>
                        <span>{{ modeTab ? 'List' : 'Tab' }}</span>
                      </v-tooltip>
                    </v-layout>
                  </div>
                </v-layout>
              </div>
              <v-tabs
                  fixed-tabs
                  color="primary"
                  background-color="primary"
                  v-if="modeTab"
                  v-model="tab"
                  align-with-title
              >
                <v-tab
                    class="ml-0"
                    v-for="(section, key) of currentRecord.sections"
                    :key="key"
                >
                  {{ key }}
                </v-tab>
              </v-tabs>
              <v-tabs-items v-model="tab" v-if="modeTab" class="fill-height overflow-y-auto">
                <v-tab-item
                    v-for="(section, key) of currentRecord.sections"
                    :key="key"
                >
                  <v-card flat>
                    <v-card-text style="height: 100%; overflow-y: auto">
                      <v-simple-table class="fill-height overflow-y-auto">
                        <tbody>
                        <tr v-for="(item, index) in section" :key="index">
                          <td style="min-width: 140px" class="pl-7">{{ item.name }}</td>
                          <td>
                            <v-layout class="fill-height" align-center>
                              {{ item.value }}
                              <v-spacer/>
                              <v-btn
                                  v-if="types.includes(item.type) && currentRecord && parseInt(currentRecord.status) === 1"
                                  @click="editField(item)"
                                  icon
                                  x-small
                              >
                                <v-icon>mdi-pencil</v-icon>
                              </v-btn>
                            </v-layout>
                          </td>
                        </tr>
                        </tbody>
                      </v-simple-table>
                    </v-card-text>
                  </v-card>
                </v-tab-item>
              </v-tabs-items>
              <v-simple-table class="fill-height overflow-y-auto" v-if="!modeTab"
                              style="border-radius: 0">
                <tbody v-for="(section, key) of currentRecord.sections">
                <tr v-if="section.length">
                  <td colspan="2"
                      style="background-color: var(--v-primary-base); height: 60px; border-bottom: 1px solid lightgray; font-size: 16px; color: #ffffff; font-weight: bold">
                    {{ key }}
                  </td>
                </tr>
                <tr v-for="(item, index) in section" :key="index">
                  <td style="min-width: 140px" class="pl-7">{{ item.name }}</td>
                  <td>
                    <v-layout class="fill-height" align-center>
                      {{ item.value }}
                      <v-spacer/>
                      <v-btn
                          @click="editField(item)"
                          icon
                          x-small
                          v-if="types.includes(item.type) && currentRecord && parseInt(currentRecord.status) === 1"
                      >
                        <v-icon>mdi-pencil</v-icon>
                      </v-btn>
                    </v-layout>
                  </td>
                </tr>
                </tbody>
              </v-simple-table>
            </div>
          </div>
        </v-layout>
        <DeleteImage ref="deleteForm" @updateListImage="image => updateListImage('delete', image)"/>
      </v-card-text>
      <v-divider/>
      <v-card-actions class="popup-footer">
        <v-spacer/>
        <div v-if="currentRecord && parseInt(currentRecord.status) === 1">
          <v-btn width="100" color="error" @click="updateRecord('reject')" dark class="mr-3">Reject</v-btn>
          <v-btn width="100" color="primary" @click="updateRecord('verify')" dark>Verify</v-btn>
        </div>
        <div v-else>
          <v-btn width="160" color="warning" @click="updateRecord('pending')" dark>Back to pending</v-btn>
        </div>
      </v-card-actions>
    </v-card>
    <EditField ref="editForm" :currentRecord="currentRecord" @refreshData="getDetail()"/>
  </v-dialog>
</template>

<script>
import {
  getRecordDetail,
  updateStatusRecord
} from "@/backend"
import {mapState} from "@/store/ults"
import MiniMap from "@/components/Map"
import config from "@/config.json"
import utils from '@/ultis/genUUID'
import convertDate from "@/ultis/convertDate"
import randomColor from "@/ultis/randomColor"
import getColorByIndex from '@/ultis/colorByIndex'
import Chart from "chart.js/auto"
import sleep from "@/ultis/sleep"
import ax from 'axios'
import DeleteImage from "@/components/home/dashboard/records/DeleteImage";
import EditField from "@/components/home/dashboard/records/EditField";
import CarouselsImage from "@/components/CarouselsImage";

let axios
export default {
  name: "RecordDetail",
  components: {CarouselsImage, EditField, DeleteImage, MiniMap},
  data() {
    return {
      types: ['text', 'numeric', 'int', 'email', 'date', 'boolean'],
      listImage: [],
      weatherData: undefined,
      reports: require('@/assets/reports.json'),
      chartData: [],
      myChart: undefined,
      chartLegend: [],
      transformedData: [],
      modeTab: false,
      tabMonitoring: null,
      tab: null,
      updatedGeometry: undefined,
      executiveHeaders: [
        {
          text: 'Name',
          align: 'start',
          sortable: false,
          value: 'name',
        },
        {
          text: 'Mobile No',
          align: 'start',
          sortable: false,
          value: 'phone',
        },
        {
          text: 'Email ID',
          align: 'start',
          sortable: false,
          value: 'email',
        },
        {
          text: 'Date of Survey',
          align: 'start',
          sortable: false,
          value: 'dateOfSurvey',
        }
      ],
      executive: [],
      selectedImage: {},
      id: undefined,
      loading: false,
      updateRecordDialog: false,
      valid: false,
      currentRecord: {},
    }
  },
  props: {},
  mounted() {
    const axiosConfig = {
      baseURL: config.weatherHost,
      timeout: 30000
    }
    axios = ax.create(axiosConfig)
  },
  computed: {
    ...mapState("dashboard", ["surveys"]),
    modified() {
      return (parseInt(this.currentRecord.status) === 3)
    },
    editable() {
      return (this.currentRecord && parseInt(this.currentRecord.status) === 1)
    }
  },
  watch: {
    updateRecordDialog(val) {
      if (!val) {
        this.modeTab = false
        this.tab = null
        this.selectedImage = {}
        this.executive = []
        this.currentRecord = {}
        this.updatedGeometry = undefined
        this.chartData = []
        this.transformedData = []
        this.tabMonitoring = null
        this.listImage = []
      }
    }
  },
  methods: {
    editField(field) {
      this.$refs.editForm.openDialog(field)
    },
    async deleteImage() {
      this.$refs.deleteForm.openDialog(this.selectedImage, this.currentRecord)
    },
    updateListImage(type, currentImage) {
      switch (type) {
        case 'delete':
          let index = this.listImage.findIndex(image => image.src === currentImage.src)
          if (index >= 0) this.listImage.splice(index, 1)
          if (this.selectedImage === currentImage) this.selectedImage = this.listImage.length ? this.listImage[0] : {}
          let currentField = this.currentRecord.images.find(val => val.alias_name === currentImage.alias_name)
          if (currentField) {
            index = currentField.value.findIndex(image => image === currentImage.src)
            if (index >= 0) currentField.value.splice(index, 1)
          }
          break
        case 'update':
          break
      }
    },
    openDialog(record) {
      this.currentRecord = {}
      this.getDetail(record)
      this.updateRecordDialog = true
    },
    updateGeometry(feature) {
      this.updatedGeometry = feature.features[0].geometry
    },
    async getDetail(record) {
      try {
        this.loading = true
        if (!record) record = this.currentRecord
        const res = await getRecordDetail({id: record.id, surveyId: record.survey_id})
        this.currentRecord = res.data
        this.listImage = []
        this.currentRecord.images.forEach(field => {
          field.value.forEach(link => {
            this.listImage.push({
              alias_name: field.alias_name,
              name: field.name,
              link: link,
              src: this.getImage(link),
              id: utils.getUUID()
            })
          })
        })
        this.selectedImage = this.listImage.length ? this.listImage[0] : {}
        this.executive = [this.currentRecord.owner]
        if (this.currentRecord.geometry) this.displayGeometry(JSON.parse(this.currentRecord.geometry), '#14fc03', 'origin_vector')
        if (this.currentRecord.updated_geometry) this.displayGeometry(JSON.parse(this.currentRecord.updated_geometry), '#fc0303', 'updated_vector')
        this.loading = false
        await this.$refs.map.reSize()
        await this.$emit('getData')
      } catch (e) {
        // this.updateRecordDialog = false
        console.log(e)
        this.loading = false
      } finally {
        this.loading = false
      }
    },
    displayGeometry(geometry, color, id) {
      switch (geometry.type.toUpperCase()) {
        case 'POINT':
          this.$refs.map.displayVector(geometry, 'marker', color, id)
          break
        case 'POLYGON':
          this.$refs.map.displayVector(geometry, 'polygon', color, id)
          break
      }
    },
    getImage(link) {
      return config.image_view_prefix + link
    },
    convertDate(date) {
      return convertDate.dateFormatted(date)
    },
    async updateRecord(action) {
      try {
        this.loading = true
        await updateStatusRecord({
          id: this.currentRecord.id,
          surveyId: this.currentRecord.survey_id,
          action: action,
          updated_geometry: this.updatedGeometry ? this.updatedGeometry : undefined
        })
        this.$store.commit('message/SHOW_SUCCESS', 'Success')
        this.$emit('getData')
        this.updateRecordDialog = false
      } catch (e) {

      } finally {
        this.loading = false
      }
    },
    transformData() {
      let tmpData = []
      this.reports.forEach(val => {
        for (const valKey in val.metadata) {
          let ind = tmpData.findIndex(val => val.label === valKey)
          if (ind >= 0) {
            tmpData[ind].data.push(val.metadata[valKey])
            tmpData[ind].layers.push({
              date: val.date,
              tileUrl: val.layers[valKey]
            })
          } else tmpData.push({
            label: valKey,
            data: [val.metadata[valKey]],
            layers: [{
              date: val.date,
              tileUrl: val.layers[valKey]
            }]
          })
        }
      })
      this.transformedData = []
      tmpData.forEach((val, index) => {
        this.transformedData.push({
          name: val.label,
          data: [],
          layers: val.layers
        })
        val.data.forEach(el => {
          for (const elKey in el) {
            let ind = this.transformedData[index].data.findIndex(val => val.label === elKey)
            if (ind >= 0) {
              this.transformedData[index].data[ind].data.push(el[elKey])
            } else this.transformedData[index].data.push({
              label: elKey,
              data: [el[elKey]]
            })
          }
        })
      })
      this.changeDisplayChart()
    },
    updateDisplayChart(legend) {
      this.myChart.setDatasetVisibility(legend.datasetIndex, legend.hidden)
      this.myChart.update()
    },
    async changeDisplayChart(key) {
      this.chartData = []
      if (!key) key = this.transformedData[0].name
      let index = this.transformedData.findIndex(val => val.name === key)
      this.$refs.map.addMultiImageToMap(this.transformedData[index].layers)
      this.transformedData[index].data.forEach((val, index) => {
        if (val.label !== 'min' && val.label !== 'max' && val.label !== 'mean') {
          this.chartData.push({
            type: 'bar',
            label: val.label,
            data: val.data,
            barPercentage: 0.8,
            backgroundColor: getColorByIndex.getColor(val.label.replaceAll(' ', '')),
            borderColor: getColorByIndex.getColor(val.label.replaceAll(' ', '')),
            borderWidth: 1,
            stack: 'adadad',
            yAxisID: 'y'
          })
        } else {
          this.chartData.unshift({
            type: 'line',
            label: val.label,
            data: val.data,
            fill: false,
            pointRadius: 7,
            pointHoverRadius: 7,
            borderColor: randomColor.getColor(index),
            stack: index,
            yAxisID: 'y1'
          })
        }
      })
      await sleep(200)
      this.displayChart(key)
    },
    displayChart(key) {
      const htmlLegendPlugin = {
        id: 'htmlLegend' + key,
        afterUpdate: (chart, args, options) => {
          this.chartLegend = chart.options.plugins.legend.labels.generateLabels(chart)
        }
      }
      const data = {
        labels: this.reports.map(val => val.date),
        datasets: this.chartData
      }
      let ctx = document.getElementById('myChart' + key)
      try {
        this.myChart = new Chart(ctx, {
          data: data,
          options: {
            pointBorderColor: '#e8e8e8',
            pointBorderWidth: 3,
            pointBackgroundColor: '#893FF2',
            plugins: {
              legend: {display: true}
            },
            scales: {
              x: {
                stacked: true,
                grid: {
                  display: false
                }
              },
              y: {
                title: {
                  text: 'Rai',
                  display: true
                },
                stacked: true,
                position: 'left',
                grid: {
                  display: true
                }
              },
              y1: {
                title: {
                  text: '',
                  display: true
                },
                stacked: true,
                position: 'right',
                grid: {
                  display: true
                }
              }
            },
            tooltips: {
              callbacks: {
                label: function (tooltipItem) {
                  return tooltipItem.yLabel;
                }
              }
            }
          },
          plugins: [htmlLegendPlugin]
        })
      } catch (e) {
      }
    }
  }
}
</script>

<style scoped>
/deep/
.custom-table .v-data-table__wrapper {
  border: 1px solid lightgray !important;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  border-top-right-radius: 8px;
}

/deep/
.v-tabs-slider-wrapper {
  display: none !important;
}

/deep/
.v-tabs-items .v-window-item--active {
  height: 100%;
}
</style>
