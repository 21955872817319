<template>
  <v-layout style="width: 100%; height: 100%">
    <Payment/>
  </v-layout>
</template>

<script>
import Payment from '@/components/home/payment'

export default {
    components: { Payment }
}
</script>
