<template>
  <v-layout style="width: 100%; height: 100%">
    <UserList/>
  </v-layout>
</template>

<script>
import UserList from '@/components/home/management/user/userManagement/UserList'
import {mapState} from "@/store/ults";

export default {
  components: {UserList},
  computed: {
    ...mapState("auth", ["currentUser"]),
  },
  mounted() {
    if (!this.currentUser.is_admin) this.$router.push({path: '/app'})
  }
}
</script>
