<template>
  <v-dialog
      v-model="dialog"
      width="500"
      persistent
  >
    <v-card class="d-flex flex-column fill-height">
      <v-overlay :value="loading" absolute>
        <v-progress-circular
            indeterminate
            size="64"
        ></v-progress-circular>
      </v-overlay>
      <v-card-title class="form-header">
        <span style="font-size: 16px">Assign</span>
        <v-spacer/>
        <v-btn :disabled="loading" icon small @click="dialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text class="pa-5" style="max-height: 58vh; overflow-y: auto">
        <v-form v-model="valid" ref="formData" class="fill-height">
          <v-layout class="fill-height" column>
            <v-list outlined>
              <v-list-item-group
                  v-model="selectedUsers"
                  multiple
              >
              <template v-for="(user, i) in users">
                <v-divider v-if="!user" :key="`divider-${i}`"></v-divider>
              <v-list-item dense :key="i" :value="user">
                <template v-slot:default="{ active }">
                  <v-list-item-content>
                    <v-list-item-title v-text="user.name"></v-list-item-title>
                  </v-list-item-content>

                  <v-list-item-action>
                    <v-checkbox
                        :input-value="active"
                        color="info accent-4"
                    ></v-checkbox>
                  </v-list-item-action>
                </template>
              </v-list-item>
              </template>
              </v-list-item-group>
            </v-list>
          </v-layout>
        </v-form>
      </v-card-text>
      <v-divider/>
      <v-card-actions class="justify-center">
        <v-spacer/>
        <v-btn @click="save" width="120" color="primary">Save</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>


import {assignee, getAllUser} from "@/backend";

export default {
  data() {
    return{
      dialog: false,
      valid: false,
      loading: false,
      users: [],
      selectedUsers: [],
      survey: {}
    }
  },
  watch: {
    dialog(val) {
      if(val) this.getUser()
    }
  },
  methods: {
    openDialog(survey) {
      this.dialog = true
      this.survey = survey
    },
    async getUser() {
      try {
        this.selectedUsers = []
        this.users = []
        this.loading = true

        const res = await getAllUser({per_page: 'all', active: 'true'})
        this.users = res.data
        this.survey.users.forEach(user => {
          let selectedUser = this.users.find(val => val.id === user)
          if (selectedUser) this.selectedUsers.push(selectedUser)
        })
      } catch (error) {
      } finally {
        this.loading = false
      }
    },
    async save() {
      try {
        if(!this.selectedUsers.length) {
          this.$store.commit('message/SHOW_ERROR', 'User is required')
          return
        }
        await assignee({surveyId: this.survey.id, data: {
          user_ids: this.selectedUsers.map(user => user.id)
          }})
        this.$store.commit('message/SHOW_SUCCESS', 'Success')
        this.$emit('getSurvey')
        this.dialog = false
      } catch (error) {
      } finally {
        this.loading = false
      }
    },
  }
}
</script>

<style scoped>

</style>