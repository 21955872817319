var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-layout',{staticClass:"fill-height",attrs:{"column":""}},[_c('v-overlay',{attrs:{"value":_vm.dialog}},[_c('v-layout',{staticStyle:{"height":"100vh","width":"100vw"},attrs:{"align-center":"","justify-center":""}},[_c('v-btn',{staticStyle:{"position":"absolute","top":"10px","right":"10px","z-index":"2"},attrs:{"fab":"","color":"white","light":"","small":""},on:{"click":function($event){_vm.dialog = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1),_c('v-carousel',{attrs:{"height":"90vh","hide-delimiters":"","hide-delimiter-background":"","show-arrows-on-hover":""},scopedSlots:_vm._u([{key:"prev",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"fab":"","small":"","color":"white"}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"#333333"}},[_vm._v("mdi-chevron-left")])],1)]}},{key:"next",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"fab":"","small":"","color":"white"}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"#333333"}},[_vm._v("mdi-chevron-right")])],1)]}}]),model:{value:(_vm.currentSlider),callback:function ($$v) {_vm.currentSlider=$$v},expression:"currentSlider"}},_vm._l((_vm.images),function(image,i){return _c('v-carousel-item',{key:i,staticClass:"custom-carousel",staticStyle:{"height":"90vh"}},[_c('div',{staticStyle:{"width":"auto","height":"auto","text-align":"-webkit-center"}},[_c('v-img',{attrs:{"contain":"","max-height":"90vh","max-width":"90vw","src":image.src}})],1)])}),1)],1)],1),_c('div',{staticClass:"flex d-flex justify-center align-center mb-1 image-block"},[_c('div',{staticStyle:{"border-radius":"8px","width":"100%","height":"100%","background-size":"auto 100%","background-position":"center","position":"relative"},style:({'background-image': _vm.selectedImage.src ? ("url(" + (_vm.selectedImage.src)) : '', 'cursor': _vm.selectedImage.src ? 'pointer' : ''}),on:{"click":_vm.openImage}},[(_vm.selectedImage.src)?_c('v-btn',{staticClass:"delete-btn",attrs:{"title":"Delete image","fab":"","small":""},nativeOn:{"click":function($event){$event.stopPropagation();return (function () { return _vm.$emit('deleteImage'); }).apply(null, arguments)}}},[_c('v-icon',{attrs:{"size":"30","color":"red"}},[_vm._v("mdi-delete-circle-outline")])],1):_vm._e(),(!_vm.selectedImage.src)?_c('v-layout',{staticClass:"fill-height",attrs:{"align-center":"","justify-center":""}},[_c('v-icon',{attrs:{"size":"90"}},[_vm._v("mdi-camera-marker-outline")])],1):_vm._e(),_c('div',{staticClass:"px-2 pt-2 display-image"},[_vm._v(" "+_vm._s(_vm.selectedImage.name ? _vm.selectedImage.name : 'Image')+" ")])],1)]),_c('div',{staticClass:"flex d-flex flex-wrap mt-1 align-center overflow-y-auto slider-image"},[_c('v-slide-group',{attrs:{"multiple":"","show-arrows":""}},[_c('v-slide-item',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
var toggle = ref.toggle;
return [_c('div',{staticClass:"ma-1 card-image"},[_c('v-layout',{staticStyle:{"width":"100%","height":"100%"},attrs:{"align-center":"","justify-center":""}},[_c('UploadImageForm',{attrs:{"currentRecord":_vm.currentRecord},on:{"refreshData":function () { return _vm.$emit('getDetail'); }}})],1)],1)]}}])}),_vm._l((_vm.images),function(image,index){return _c('v-slide-item',{key:index,scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
var toggle = ref.toggle;
return [_c('div',{key:index,staticClass:"ma-1 image-display",style:({'border': _vm.selectedImage.id === image.id ? '2px solid #4aa94e' : '1px solid lightgray'}),on:{"click":function($event){_vm.selectedImage = image}}},[_c('img',{attrs:{"alt":"image","src":image.src,"width":"100%","height":"100%"}})])]}}],null,true)})})],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }