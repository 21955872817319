<template>
  <div class="d-flex fill-height justify-center flex-column pa-2" style="width: 100%" v-if="currentUser.is_sysadmin">
    <div style="width: 100%; height: 120px; border-bottom: 1px solid lightgrey; flex: none">
      <v-layout align-center justify-center column class="fill-height">
        <h1 class="d-flex"><v-icon color="#333333" size="40" class="mr-1">mdi-account-multiple</v-icon>Company</h1>
        <v-container fluid class="flex-fill overflow-y-hidden py-0" style="height: 40px; max-width: 1550px">
          <v-layout justify-end align-center class="fill-height">
          </v-layout>
        </v-container>
      </v-layout>
    </div>
    <v-container fluid class="d-flex overflow-y-hidden flex-column" style="height: 100%; max-width: 1550px">
      <v-layout fill-height style="overflow-y: auto">
        <v-data-table
            height="100%"
            fixed-header
            style="height: 100%; width: 100%"
            :headers="userHeaders"
            :items="users"
            :search="search"
            :loading="loading"
            :items-per-page="10"
            class="row-pointer order-table d-flex flex-column"
            hide-default-footer
            :server-items-length.sync="totalItem"
            loading-text="Loading... Please wait"
            :options.sync="options"
            :page.sync="page"
        >
          <template v-slot:top>
            <v-layout class="flex-wrap py-2">
              <v-layout class="pb-2">
                <v-spacer/>
                <div style="width: 255px">
                  <v-text-field
                      class="ml-1"
                      outlined
                      dense
                      hide-details
                      v-model="search"
                      @input="debounceInput"
                      append-icon="mdi-magnify"
                      label="Search"
                  ></v-text-field>
                </div>
              </v-layout>
            </v-layout>
          </template>
          <template v-slot:[`item.action`]="{ item }">
            <v-btn icon min-height="0" min-width="0" width="30" height="30" color="green"
                   @click="() => $refs.updateTokenForm.openDialog(item)">
              <v-icon size="22">mdi-plus</v-icon>
            </v-btn>
            <v-btn icon min-height="0" min-width="0" width="30" height="30" color="green"
                   @click="() => $refs.updateUserForm.openDialog(item)">
              <v-icon size="22">mdi-account-edit</v-icon>
            </v-btn>
          </template>
          <template v-slot:[`item.verified`]="{ item }">
            <v-icon color="primary" v-if="item.verified">mdi-check-circle</v-icon>
            <v-icon color="error" v-else>mdi-close-circle</v-icon>
          </template>
          <template v-slot:[`item.created_at`]="{ item }">
            {{ convertDate(item.created_at) }}
          </template>
        </v-data-table>
      </v-layout>
      <div class="text-center pa-3" style="flex: none; height: auto">
        <v-pagination :total-visible="5" v-model="page" :length="pageCount" id="task_pagination"></v-pagination>
      </div>
    </v-container>
    <PopupUpdateUser @getUser="getData" ref="updateUserForm"/>
    <PopupUpdateToken @getUser="getData" ref="updateTokenForm"/>
  </div>
</template>

<script>
import { getListCompany } from "@/backend"
import { mapState } from "@/store/ults"
import { debounce } from "lodash"
import PopupUpdateUser from "./UpdateUser"
import PopupUpdateToken from "@/components/home/management/system/UpdateToken"
import convertDate from "@/ultis/convertDate"
export default {
  name: 'index',
  components: {
    PopupUpdateToken,
    PopupUpdateUser,
  },
  data() {
    return {
      options: {},
      page: 1,
      search: '',
      totalItem: 100,
      pageCount: 0,
      loading: false,
      userHeaders: [
        {text: "Company Name", align: "left", sortable: true, value: "name",},
        { text: "Token", value: "credit_token", sortable: true, align: "start" },
        { text: "Phone", value: "phone", sortable: false, align: "start" },
        { text: "Email", value: "email", sortable: false, align: "start" },
        { text: "Total survey", value: "surveys_count", sortable: true, align: "start" },
        { text: "Total record", value: "records_count", sortable: true, align: "start" },
        { text: "Verified", value: "verified", sortable: false, align: "start" },
        { text: "Created at", value: "created_at", sortable: true, align: "start" },
        { text: "", value: "action", sortable: false, align: "end" }
      ],
      users: []
    };
  },
  computed: {
    ...mapState("auth", ["currentUser"])
  },
  watch: {
    options: {
      handler () {
        this.getData()
      },
      deep: true
    }
  },
  created() {
    if (!this.currentUser.is_sysadmin) this.$router.push({ path: '/app' })
  },
  methods: {
    convertDate(date) {
      return convertDate.dateFormatted(date)
    },
    getParam () {
      let sort
      if (this.options.sortBy.length && this.options.sortBy.length) {
        if (this.options.sortDesc[0]) sort = '-' + this.options.sortBy[0]
        else sort = this.options.sortBy[0]
      }
      return {per_page: 10, page: this.options.page, sort: sort, search: this.search}
    },
    debounceInput: debounce(function (e) {
      this.options.page = 1
      this.getData()
    }, 500),
    async getData() {
      try {
        this.users = []
        this.loading = true
        const res = await getListCompany(this.getParam())
        this.users = res.data.data
        this.totalItem = res.data.total
        this.pageCount = res.data.last_page
      } catch (error) {
      } finally {
        this.loading = false
      }
    }
  },
};
</script>
<style scoped>
/deep/
.v-chip__content {
  width: 180px !important;
}
/deep/
.v-data-table > .v-data-table__wrapper tbody tr.v-data-table__expanded__content {
  box-shadow: inset 0px 4px 6px -5px rgb(50 50 50 / 75%),
  inset 0px -4px 8px -5px rgb(50 50 50 / 0%);
}
/deep/
.order-table th {
  background-color: var(--v-primary-base) !important;
  color: white !important;
}
/deep/
.list-image th {
  background-color: #6686a3 !important;
}
/deep/
.v-data-table__wrapper {
  border: 1px lightgrey solid;
  border-radius: 8px;
}
</style>
