<template>
  <v-layout class="fill-height">
    <div class="flex mr-1" style="flex: 2;">
      <v-menu
          ref="menu"
          v-model="menu"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
              outlined
              dense
              hide-details
              v-model="currentDateRanger"
              label="Date Ranger"
              placeholder="Date Ranger"
              readonly
              v-bind="attrs"
              v-on="on"
          ></v-text-field>
        </template>
        <v-date-picker
            v-model="currentDateRanger"
            range
        ></v-date-picker>
      </v-menu>
    </div>
    <div class="flex mx-1" style="flex: 2;">
      <v-autocomplete
          @change="getDistrict"
          v-model="currentState"
          :items="states"
          label="All State"
          placeholder="All State"
          outlined
          hide-details
          dense
          item-text="name"
          item-value="id"
          class="select-box"
          :disabled="loading"
          clearable
      >
      </v-autocomplete>
    </div>
    <div class="flex mx-1" style="flex: 2;">
      <v-autocomplete
          @change="getSubDistrict"
          v-model="currentDistrict"
          :items="districts"
          label="All District"
          placeholder="All District"
          outlined
          hide-details
          dense
          item-text="name"
          item-value="id"
          class="select-box"
          :disabled="loading"
          clearable
      >
      </v-autocomplete>
    </div>
    <div class="flex mx-1" style="flex: 2;">
      <v-autocomplete
          v-model="currentSubDistrict"
          :items="subDistricts"
          label="All SubDistricts"
          placeholder="All SubDistrict"
          outlined
          hide-details
          dense
          item-text="name"
          item-value="id"
          class="select-box"
          :disabled="loading"
          clearable
      >
      </v-autocomplete>
    </div>
    <div class="flex mx-1" style="flex: 2;">
      <v-autocomplete
          v-model="currentType"
          :items="surveys"
          label="All Type"
          placeholder="All Type"
          outlined
          hide-details
          dense
          item-text="name"
          item-value="id"
          class="select-box"
          :disabled="loading"
          clearable
      >
      </v-autocomplete>
    </div>
    <div class="flex ml-1" style="flex: 2;">
      <v-autocomplete
          v-model="currentStatus"
          :items="listStatus"
          label="Monitoring status"
          placeholder="Monitoring status"
          outlined
          hide-details
          dense
          item-text="name"
          item-value="code"
          class="select-box"
          :disabled="loading"
          clearable
      >
      </v-autocomplete>
    </div>
  </v-layout>
</template>

<script>
import {mapState} from "@/store/ults";
import {getDistrict, getSubDistrict} from "@/backend";

export default {
  name: "FilterForm",
  data() {
    return {
      listStatus: [{name: 'Active', code: true}, {name: 'Inactive', code: false}],
      status: [
        {
          name: 'All Farms',
          code: undefined,
          color: '#4aa94e'
        },
        {
          name: 'Pending Farms',
          code: 1,
          color: '#8008fa'
        },
        {
          name: 'Verified Farms',
          code: 2,
          color: '#2974ff'
        },
        {
          name: 'Modified Farms',
          code: 3,
          color: '#ff6229'
        },
        {
          name: 'Rejected Farms',
          code: 4,
          color: '#ff2929'
        }
      ],
      loading: false,
      menu: false,
      districts: [],
      subDistricts: [],
    }
  },
  props: {
    dateRanger: {type: Array, default: []},
    state: {type: Number, default: undefined},
    district: {type: Number, default: undefined},
    subDistrict: {type: Number, default: undefined},
    monitoringStatus: {type: Boolean, default: true},
    type: {type: String, default: undefined}
  },
  computed: {
    ...mapState("dashboard", ["states", "surveys"]),
    currentDateRanger: {
      get() {
        return this.dateRanger
      },
      set(val) {
        this.$emit('update:dateRanger', val)
      }
    },
    currentSeason: {
      get() {
        return this.season
      },
      set(val) {
        this.$emit('update:season', val)
      }
    },
    currentCountry: {
      get() {
        return this.country
      },
      set(val) {
        this.$emit('update:country', val)
      }
    },
    currentState: {
      get() {
        return this.state
      },
      set(val) {
        this.$emit('update:state', val)
      }
    },
    currentDistrict: {
      get() {
        return this.district
      },
      set(val) {
        this.$emit('update:district', val)
      }
    },
    currentSubDistrict: {
      get() {
        return this.subDistrict
      },
      set(val) {
        this.$emit('update:subDistrict', val)
      }
    },
    currentType: {
      get() {
        return this.type
      },
      set(val) {
        this.$emit('update:type', val)
      }
    },
    currentStatus: {
      get() {
        return this.monitoringStatus
      },
      set(val) {
        this.$emit('update:monitoringStatus', val)
      }
    }
  },
  methods: {
    async getDistrict(stateId) {
      try {
        this.loading = true
        this.districts = []
        this.subDistricts = []
        const res = await getDistrict({parent_id: stateId})
        this.districts = res.data
      } catch (e) {

      } finally {
        this.loading = false
      }
    },
    async getSubDistrict(districtId) {
      try {
        this.loading = true
        this.subDistricts = []
        const res = await getSubDistrict({parent_id: districtId})
        this.subDistricts = res.data
      } catch (e) {

      } finally {
        this.loading = false
      }
    },
    refreshFilter() {
      this.currentState = undefined
      this.getDistrict()
    },
    downloadData(name, status, type) {
      this.$emit('downloadData', name, status, type)
    }
  }
}
</script>

<style scoped>
</style>
