<template>
  <v-dialog v-model="paymentDialog" persistent width="450">
    <template v-slot:activator="{ on, attrs }">
      <div style="border: #fafafa 2px solid; border-radius: 4px; margin-top: -4px" class="mr-1">
        <v-btn
            :disabled="$attrs['loading']"
            v-bind="attrs"
            v-on="on"
            width="250"
            min-width="0"
            color="info"
            class="elevation-0">
          <v-icon class="mr-1">mdi-credit-card-outline</v-icon>
          Purchase token
        </v-btn>
      </div>
    </template>
    <v-card>
      <v-card-title class="form-header">
        <span style="font-size: 16px; text-transform: uppercase">Purchase token</span>
        <v-spacer/>
        <v-btn :disabled="loading" icon small @click="paymentDialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text style="height: 290px" class="px-4 pt-2 pb-0">
        <v-form @submit="submitPayment" onSubmit="return false;" v-model="valid" ref="formData" class="fill-height">
          <v-layout class="fill-height" column>
            <v-layout style="font-size: 14px" column>
              <span class="pt-2">
                User Name:
                <v-text-field hide-details readonly class="pt-1" disabled v-model="currentUser.name" outlined
                              dense></v-text-field>
              </span>
              <span class="pt-2">
                Email:
                <v-text-field hide-details readonly class="pt-1" disabled v-model="currentUser.email" outlined
                              dense></v-text-field>
              </span>
            </v-layout>
            <v-layout class="fill-height pt-0" column justify-end>
              <v-divider/>
              <div style="height: 100px" class="mt-2">
                <v-layout>
                  <span style="font-size: 14px">Token:</span>
                  <v-spacer/>
                  <b>1$ for 1 token</b>
                </v-layout>
                <v-text-field
                    @change="checkAmountToken"
                    type="number"
                    :min="10" :step="10" :max="100000"
                    :rules="[rules.token]"
                    class="pt-1"
                    label="Token"
                    v-model="token"
                    outlined dense>
                </v-text-field>
              </div>
            </v-layout>
          </v-layout>
        </v-form>
      </v-card-text>
      <v-card-actions class="pt-0 pb-3">
        <v-layout class="fill-height" align-center justify-center>
          <v-btn :loading="loading" color="primary" width="100%" height="40" @click="submitPayment">Checkout</v-btn>
        </v-layout>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {mapState} from "@/store/ults";
import {getPaymentLink} from "@/backend"

export default {
  name: "PaymentToken",
  data() {
    return {
      valid: false,
      loading: false,
      paymentDialog: false,
      token: undefined,
      rules: {
        token: value => !!value || 'Token is required'
      }
    }
  },
  computed: {
    ...mapState("auth", ["currentUser"]),
  },
  watch: {
    paymentDialog(val) {
      this.token = undefined
      if (!val) this.$refs.formData.resetValidation()
    }
  },
  methods: {
    checkAmountToken() {
      this.token = Math.ceil(this.token/10)*10
    },
    async submitPayment() {
      try {
        this.loading = true
        this.$refs.formData.validate()
        if (!this.valid) return
        const res = await getPaymentLink({data: {amount: this.token}})
        window.location.href = res.data.url
        this.paymentDialog = false
      } catch (e) {

      } finally {
        this.loading = false
      }
    },
  }
}
</script>

<style scoped>
</style>
