<template>
  <v-layout column class="fill-height">
    <div style="flex: none; height: 200px" class="pt-1">
      <h2 style="flex: none">Field</h2>
      <v-layout align-center class="pt-1">
        <div class="flex fill-height pr-2" style="flex: 2">
          <span style="font-size: 14px; font-weight: bold" class="mt-1">Name</span>
          <div style="width: 100%;">
            <v-text-field
                :disabled="checkDisable('name')"
                @change="genAliasName"
                class="mt-1"
                outlined
                dense
                v-model="currentName"
                label="Name"
                :rules="[rules.requiredName]">
            </v-text-field>
          </div>
        </div>
        <div class="flex fill-height pr-2" style="flex: 2">
          <span style="font-size: 14px; font-weight: bold" class="mt-1">Code</span>
          <div style="width: 100%;">
            <v-text-field
                :disabled="checkDisable('alias_name')"
                class="mt-1"
                outlined
                dense
                v-model="currenAliasName"
                label="Code"
                :rules="[rules.requiredCode]">
            </v-text-field>
          </div>
        </div>
        <div class="flex fill-height pl-2" style="flex: 2">
          <span style="font-size: 14px; font-weight: bold" class="mt-0">Type</span>
          <div style="width: 100%;">
            <v-select
                :disabled="checkDisable('type')"
                class="mt-1"
                outlined
                dense
                v-model="currentType"
                :items="config"
                label="Type"
                item-value="code"
                item-text="name"
                @change="changeType">
            </v-select>
          </div>
        </div>
        <div class="flex fill-height pl-4" style="flex: none; width: 100px">
          <div class="flex fill-height pl-2">
            <span style="font-size: 14px; font-weight: bold" class="mt-0">Required</span>
            <v-switch
                :disabled="checkDisable('required')"
                class="mt-1"
                v-model="currentRequired"
                label=""
            ></v-switch>
          </div>
        </div>
      </v-layout>
      <v-layout align-center class="pt-0">
        <div class="flex d-flex fill-height" style="flex: 2">
          <div style="flex: 1" class="flex" :class="currentType === 'combobox' ? 'pr-2' : 0">
            <span style="font-size: 14px; font-weight: bold" class="mt-0">Description</span>
            <div style="width: 100%;">
              <v-text-field
                  class="mt-1"
                  outlined
                  dense
                  v-model="currentFieldDescription"
                  label="Description">
              </v-text-field>
            </div>
          </div>
          <div style="flex: 1" class="flex pl-2" v-if="allowRefField">
            <span style="font-size: 14px; font-weight: bold" class="mt-0">Reference</span>
            <div style="width: 100%;">
              <v-autocomplete
                  :disabled="checkDisable('ref')"
                  class="mt-1"
                  outlined
                  dense
                  :items="allowFields"
                  v-model="currentConfig.ref"
                  item-text="name"
                  item-value="alias_name"
                  label="Reference">
              </v-autocomplete>
            </div>
          </div>
        </div>
      </v-layout>
    </div>
    <div class="d-flex flex fill-height pt-2 flex-column" style="border-top: 1px solid lightgray">
      <v-layout class="fill-height" v-if="currentType === 'image'">
        <v-checkbox
            :disabled="checkDisable('is_multi')"
            class="mr-4"
            v-model="currentConfig.is_multi"
            label="Multiple image"
        ></v-checkbox>
        <v-checkbox
            :disabled="checkDisable('camera')"
            class="ml-4"
            v-model="currentConfig.camera"
            label="Must use the camera"
        ></v-checkbox>
      </v-layout>
      <v-layout column class="fill-height" v-if="listTypeWithMinMax.includes(type)">
        <div style="flex: none; width: 100%; height: 40px">
          <v-layout class="fill-height" align-center>
            <v-checkbox
                :disabled="checkDisable('number_only')"
                class="mt-0"
                v-if="currentType === 'text'"
                v-model="currentConfig.number_only"
                hide-details
                label="Only number"
            />
          </v-layout>
        </div>
        <div style="flex: 1; min-width: 0; height: 100%" class="d-flex">
          <div class="flex fill-height pr-2 flex-column" style="flex: 1">
            <span style="font-size: 14px; font-weight: bold" class="mt-0">Min</span>
            <div style="width: 100%;">
              <v-text-field
                  :disabled="checkDisable('min')"
                  class="mt-1"
                  outlined
                  dense
                  type="number"
                  @change="currentType === 'int' || currentType === 'text' ? currentConfig.min = Math.ceil(currentConfig.min) : ''"
                  v-model.number="currentConfig.min"
                  label="Min">
              </v-text-field>
            </div>
          </div>
          <div class="flex fill-height pl-2 flex-column" style="flex: 1">
            <span style="font-size: 14px; font-weight: bold" class="mt-0">Max</span>
            <div style="width: 100%;">
              <v-text-field
                  :disabled="checkDisable('max')"
                  class="mt-1"
                  outlined
                  dense
                  @change="changeMaxValue"
                  :min="currentType === 'text' ? 1 : ''"
                  type="number"
                  v-model.number="currentConfig.max"
                  label="Max">
              </v-text-field>
            </div>
          </div>
        </div>
      </v-layout>
      <v-layout class="fill-height" v-if="listTypeWithoutMinMax.includes(type)">
      </v-layout>
      <v-layout v-if="currentType === 'combobox'" column class="py-2" style="height: calc(100% - 100px)">
        <div style="height: 40px; flex: none">
          <v-layout align-center class="fill-height">
            <div class="flex d-flex fill-height align-center" style="flex: 1">
              <v-checkbox
                  :disabled="checkDisable('is_multi')"
                  @change="changeMultiSelect('is_multi')"
                  v-model="currentConfig.is_multi"
                  label="Choose multi option"
              />
            </div>
            <div class="flex d-flex fill-height align-center" style="flex: 1">
              <v-checkbox
                  :disabled="checkDisable('ref')"
                  @change="changeMultiSelect('is_ref')"
                  v-model="currentConfig.is_ref"
                  label="Ref to section"
              />
            </div>
            <div class="flex d-flex fill-height align-center" style="flex: 1">
              <v-checkbox
                  v-if="!currentUser.is_sysadmin"
                  :disabled="checkDisable('option')"
                  @change="changeComboboxOption"
                  v-model="currentIsSelectOption"
                  label="Select your option"
              />
            </div>
            <div class="flex d-flex fill-height align-center justify-end" style="flex: 1">
              <v-btn v-if="!currentIsSelectOption" color="info" class="ml-1" width="160.27"
                     @click="addOption">Add option
              </v-btn>
              <v-autocomplete
                  :disabled="checkDisable('option')"
                  v-if="currentIsSelectOption"
                  class="mt-1"
                  outlined
                  dense
                  @change="() => $emit('getOptionDetail')"
                  :rules="[rules.requiredItem]"
                  v-model="currentConfig.option"
                  :items="fieldOptions"
                  hide-details
                  label="Select Option"
                  item-text="name"
                  item-value="id"
              ></v-autocomplete>
            </div>
          </v-layout>
        </div>
        <div class="flex fill-height pr-2 flex-column overflow-y-auto">
          <v-layout align-center v-for="(item, index) of currentConfig.values" :key="index"
                    v-if="!currentIsSelectOption">
            <div style="width: 100%; flex: 1;" :class="currentConfig.is_ref ? 'pr-1' : ''"
                 class="d-flex justify-center flex-column">
              <span style="font-size: 14px; font-weight: bold" class="mt-0">Option{{ index + 1 }}</span>
              <div style="width: 100%;">
                <v-layout>
                  <v-text-field
                      class="mt-1"
                      outlined
                      dense
                      v-model="currentConfig.values[index].name"
                      :label="'Option ' + (index + 1)"
                      :rules="[rules.requiredItem]"
                  >
                  </v-text-field>
                </v-layout>
              </div>
            </div>
            <div style="width: 100%; flex: 1;" class="pl-1 d-flex justify-center flex-column"
                 v-if="currentConfig.is_ref">
              <span style="font-size: 14px; font-weight: bold" class="mt-0">Ref</span>
              <div style="width: 100%;">
                <v-layout>
                  <v-autocomplete
                      :disabled="checkDisable('ref')"
                      class="mt-1"
                      outlined
                      dense
                      v-model="currentConfig.values[index].section"
                      :items="sections"
                      label="Section"
                      item-text="name"
                      item-value="name"
                      :rules="[rules.requiredItem]"
                  ></v-autocomplete>
                </v-layout>
              </div>
            </div>
            <div style="width: 40px; height: 100%; flex: none" v-if="currentConfig.values.length > 1">
              <v-layout align-center justify-center>
                <v-btn icon color="error"
                       @click="currentConfig.values.splice(index, 1)">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-layout>
            </div>
          </v-layout>
          <v-data-iterator
              v-if="currentIsSelectOption"
              :items="currentOptionDetail"
              item-key="name"
              :items-per-page="10"
              :page="page"
              hide-default-footer
          >
            <template v-slot:default="{ items }">
              <v-layout v-for="(item, index) of items" :key="index">
                <div
                    style="flex: 1;"
                    :class="currentConfig.is_ref ? 'pr-1' : ''"
                    class="d-flex justify-center flex-column">
                  <span style="font-size: 14px; font-weight: bold" class="mt-0">
                    Option {{ (page - 1) * 10 + index + 1 }}</span>
                  <div style="width: 100%;">
                    <v-layout>
                      <v-text-field
                          class="mt-1"
                          outlined
                          dense
                          v-model="item.name"
                          readonly
                          :label="'Option ' + ((page -1) * 10 + index + 1)"
                          :rules="[rules.requiredItem]">
                      </v-text-field>
                    </v-layout>
                  </div>
                </div>
                <div
                    style="flex: 1;"
                    class="pl-1 d-flex justify-center flex-column"
                    v-if="currentConfig.is_ref">
                  <span style="font-size: 14px; font-weight: bold" class="mt-0">Ref</span>
                  <div style="width: 100%;">
                    <v-layout>
                      <v-autocomplete
                          :disabled="checkDisable('ref')"
                          class="mt-1"
                          outlined
                          dense
                          v-model="currentConfig.values ? currentConfig.values[index].section : item.section"
                          :items="sections"
                          label="Section"
                          item-text="name"
                          item-value="name"
                          :rules="[rules.requiredItem]"
                      ></v-autocomplete>
                    </v-layout>
                  </div>
                </div>
              </v-layout>
            </template>
          </v-data-iterator>
        </div>
        <div class="text-center pt-1" style="flex: none" v-if="currentIsSelectOption">
          <v-pagination
              class="mx-3 mt-1 custom-pagination"
              :total-visible="5"
              v-model="page"
              :length="Math.ceil( optionDetail.length / 10)">
          </v-pagination>
        </div>
      </v-layout>
      <v-layout v-if="currentType === 'custom'" column class="py-2" style="height: calc(100% - 100px)">
      </v-layout>
      <div style="height: 80px; flex: none; border-top: 1px solid lightgray">
        <v-layout align-center class="fill-height">
          <v-autocomplete
              outlined
              dense
              v-model="currentSection"
              :items="sections"
              hide-details
              label="Section"
              item-text="name"
              item-value="name"
          ></v-autocomplete>
          <v-spacer/>
          <v-layout v-if="statusEdit" justify-end>
            <v-btn color="warning" width="160.27" class="mr-1" @click="() => $emit('cancelEdit')">Cancel</v-btn>
            <v-btn color="primary" width="160.27" class="ml-1" @click="() => $emit('updateField')">Update Field</v-btn>
          </v-layout>
          <v-btn v-else-if="!currentVisible" color="primary" class="ml-1" @click="() => $emit('addToSection')">Add to
            section
          </v-btn>
          <v-btn v-else color="warning" class="ml-1" @click="() => $emit('cancelView')">Cancel</v-btn>
        </v-layout>
      </div>
    </div>
  </v-layout>
</template>

<script>
import {mapState} from "@/store/ults";

export default {
  name: "FieldInfo",
  data() {
    return {
      page: 1,
      listTypeWithMinMax: ['text', 'numeric', 'int'],
      listTypeWithoutMinMax: ['email', 'date', 'boolean'],
      rules: {
        requiredName: value => (!!value && !!value.trim()) || 'Name is required',
        requiredCode: value => (!!value && !!value.trim() && /^[a-z_]+$/.test(value)) || 'Code is invalid',
        requiredItem: value => (!!value && !!value.trim()) || 'Item is required',
      },
      phoneRules: [
        v => !!v || 'Phone number is required',
        v => /\(?([0-9]{3})\)?([ .-]?)([0-9]{3})\2([0-9]{4})/.test(v) || 'Phone number must be valid'
      ]
    }
  },
  props: {
    systemFields: {type: Array, default: () => []},
    currentField: {
      type: Object, default: () => {
      }
    },
    oldSection: {
      type: Object, default: () => {
      }
    },
    editing: {type: Boolean, default: false},
    section: {type: String, default: ''},
    sections: {type: Array, default: () => []},
    fieldConfig: {
      type: Object, default: () => {
      }
    },
    config: {type: Array, default: () => []},
    fieldOptions: {type: Array, default: () => []},
    optionDetail: {type: Array, default: () => []},
    name: {type: String, default: ''},
    aliasName: {type: String, default: ''},
    type: {type: String, default: ''},
    required: {type: Boolean, default: false},
    isSelectOption: {type: Boolean, default: false},
    fieldDescription: {type: String, default: ''},
    visible: {type: Boolean, default: false}
  },
  computed: {
    ...mapState("auth", ["currentUser"]),
    currentOptionDetail: {
      get() {
        return this.optionDetail
      },
      set(val) {
        this.$emit('update:optionDetail', val)
      }
    },
    currentVisible: {
      get() {
        return this.visible
      },
      set(val) {
        this.$emit('update:visible', val)
      }
    },
    statusEdit: {
      get() {
        return this.editing
      },
      set(val) {
        this.$emit('update:editing', val)
      }
    },
    currentSection: {
      get() {
        return this.section
      },
      set(val) {
        this.$emit('update:section', val)
      }
    },
    currentFieldDescription: {
      get() {
        return this.fieldDescription
      },
      set(val) {
        this.$emit('update:fieldDescription', val)
      }
    },
    currentIsSelectOption: {
      get() {
        return this.isSelectOption
      },
      set(val) {
        this.$emit('update:isSelectOption', val)
      }
    },
    currentConfig: {
      get() {
        return this.fieldConfig
      },
      set(val) {
        this.$emit('update:fieldConfig', val)
      }
    },
    currentRequired: {
      get() {
        return this.required
      },
      set(val) {
        this.$emit('update:required', val)
      }
    },
    currentName: {
      get() {
        return this.name
      },
      set(val) {
        this.$emit('update:name', val)
      }
    },
    currenAliasName: {
      get() {
        return this.aliasName
      },
      set(val) {
        this.$emit('update:aliasName', val)
      }
    },
    currentType: {
      get() {
        return this.type
      },
      set(val) {
        this.$emit('update:type', val)
      }
    },
    allowType() {
      return this.config.filter(val => val.code !== 'custom')
    },
    allowNextSection() {
      let tmpSections = JSON.parse(JSON.stringify(this.sections))
      tmpSections.push({name: 'Submit Form', fields: []})
      return tmpSections
    },
    allowRefField() {
      return (this.currentType === 'combobox' && this.isSelectOption && this.currentConfig.option)
    },
    allowFields() {
      let currentOption = this.fieldOptions.find(option => option.id === this.currentConfig.option)
      if (!currentOption.ref_id) return []
      let fields = []
      this.sections.forEach(section => {
        section.fields.forEach(field => {
          if (field.type === 'combobox' && field.rules.option === currentOption.ref_id) fields.push(field)
        })
      })
      return fields
    },
    dragOptions() {
      return {
        animation: 300,
        group: 'description',
        disabled: false,
        ghostClass: 'ghost'
      }
    }
  },
  methods: {
    changeMaxValue(val) {
      this.currentType === 'text' ? this.currentConfig.max < 1 ? this.currentConfig.max = 1 : this.currentConfig.max = Math.ceil(this.currentConfig.max) : ''
      this.currentType === 'int' ? this.currentConfig.max = Math.ceil(this.currentConfig.max) : ''
    },
    addOption() {
      this.currentConfig.values.push({name: '', section: ''})
    },
    checkDisable(type) {
      return this.systemFields.some(val => val === type)
    },
    removeRef() {
      if (this.currentConfig.values) this.currentConfig.values.forEach(value => {
        value.section = null
      })
    },
    changeMultiSelect(type) {
      this.removeRef()
      switch (type) {
        case 'is_multi':
          if (this.currentConfig.is_multi) this.currentConfig.is_ref = false
          break
        case 'is_ref':
          if (this.currentConfig.is_ref) this.currentConfig.is_multi = false
          break
      }
    },
    changeComboboxOption(val) {
      this.currentConfig = undefined
      this.currentOptionDetail = []
      this.currentConfig = val ? {option: undefined} : {values: [{name: '', section: ''}], is_ref: false}
    },
    changeType(val) {
      this.currentType = val
      if (val === 'combobox') this.currentConfig = {values: [{name: '', section: ''}], is_ref: false}
      else this.currentConfig = {}
    },
    genAliasName() {
      if (this.statusEdit && this.currentField.is_system) return
      this.currenAliasName = this.name.toLowerCase().replace(/[^a-zA-Z]+/g, "_")
      if (this.currenAliasName[this.currenAliasName.length - 1] === "_") this.currenAliasName = this.currenAliasName.slice(0, -1)
      if (this.currenAliasName[0] === "_") this.currenAliasName = this.currenAliasName.substring(1)
    },
  }
}
</script>

<style scoped>

</style>
