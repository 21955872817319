<template>
  <v-layout style="width: 100%; height: 100%">
    <System/>
  </v-layout>
</template>

<script>
import System from '@/components/home/management/system'

export default {
    components: { System }
}
</script>
