<template>
  <v-layout class="fill-height ma-2 overflow-y-auto">
    <div style="height: 100%; width: 100%">
      <v-expansion-panels
          v-model="panel"
      >
        <draggable
            style="width: 100%"
            v-bind="dragOptions"
            v-model="listSection"
            handle=".dragSection"
            @end=""
            @start="checkValidSection"
            group="row"
        >
          <transition-group type="transition" :name="!dragSection ? 'flip-list' : null">
            <v-expansion-panel
                :disabled="statusEdit"
                v-for="(item, i) in listSection"
                :key="'section' + i"
            >
              <v-expansion-panel-header class="px-2 py-1" style="height: 65px">
                <v-layout class="fill-height" align-center>
                  <div style="flex: none; width: 30px; cursor: move" class="dragSection">
                    <v-icon>mdi-drag</v-icon>
                  </div>
                  <v-layout class="fill-height" justify-center column>
                    <span v-if="!item.editing" class="text-truncate">{{ item.name }}</span>
                    <div style="width: 200px; flex: none; height: 20px" class="text-truncate">
                      <v-layout class="fill-height" align-center>
                        <div style="width: 180px; flex: none">
                          <div
                              class="d-flex align-center fill-height"
                              v-if="!item.editing"
                              style="font-size: 11px; color: #777c85;">
                            Next section:
                            <v-menu
                                absolute
                                offset-y
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <div
                                    class="px-1 ml-1 text-truncate"
                                    :title="item.next_section ? item.next_section + ' (Click to edit)' : 'Select section'"
                                    v-bind="attrs" v-on="on"
                                    style="text-transform: none; color: var(--v-accent-base); cursor: pointer"
                                >
                                  {{ item.next_section ? item.next_section : 'Select section' }}
                                </div>
                              </template>
                              <v-list dense>
                                <v-list-item v-for="(section, index) in allowNextSection" :key="index"
                                             @click="() => $emit('addNextSection', item, section)" color="#893FF2">
                                  <v-list-item-title>{{ section.name }}</v-list-item-title>
                                </v-list-item>
                              </v-list>
                            </v-menu>
                          </div>
                        </div>
                        <div style="flex: 1; min-height: 0;" class="flex">
                          <v-btn
                              v-if="item.next_section"
                              title="Remove"
                              @click.native.stop="() => $emit('removeNextSection', item)"
                              x-small
                              icon
                              color="error">
                            <v-icon>mdi-close</v-icon>
                          </v-btn>
                        </div>
                      </v-layout>
                    </div>
                    <v-text-field
                        v-if="item.editing"
                        @click.native.s.stop=""
                        class="edit-input"
                        style="margin-top: -10px"
                        dense
                        flat
                        outlined
                        solo
                        hide-details
                        v-model="item.name"
                        label="Name"
                    >
                    </v-text-field>
                  </v-layout>
                  <v-spacer/>
                  <div style="flex: none; width: 50px">
                    <v-layout class="fill-height" align-center justify-end>
                      <v-btn v-if="!statusEdit" :title="item.editing ? 'Save' : 'Rename'" color="green" icon
                             small @click.native.stop="() => $emit('editSection', item)">
                        <v-icon v-if="!item.editing" size="18">mdi-pencil</v-icon>
                        <v-icon v-else size="18">mdi-content-save-outline</v-icon>
                      </v-btn>
                      <v-btn title="Delete section" color="error" icon small
                             @click.native.stop="deleteSection(i)"
                             v-if="checkSectionDeletable(item) && !item.editing && !statusEdit">
                        <v-icon size="18">mdi-delete</v-icon>
                      </v-btn>
                    </v-layout>
                  </div>
                </v-layout>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-list dense v-if="item.fields && item.fields.length" class="elevation-1 py-0">
                  <draggable
                      v-bind="dragOptions"
                      v-model="item.fields"
                      handle=".handle"
                      @end=""
                      @start="(e) => $emit('checkValid', e, item)"
                      group="row"
                  >
                    <transition-group type="transition" :name="!drag ? 'flip-list' : null">
                      <v-list-item
                          v-for="(field, index) of item.fields"
                          :key="'field' + index"
                          @click="viewField(field, item)"
                          :style="{'border-bottom': index < item.fields.length - 1 ? '1px solid lightgray' : '','background-color': field.rules.required ? '#eb403421' : ''}"
                      >
                        <v-list-item-action class="mr-0 handle">
                          <v-icon style="cursor: move" small color="accent">mdi-drag</v-icon>
                        </v-list-item-action>
                        <v-list-item-action class="mr-2">
                          <v-icon small color="accent">{{ getIcon(field.type) }}</v-icon>
                        </v-list-item-action>
                        <v-list-item-title class="text-truncate">{{ field.name }}</v-list-item-title>
                        <v-list-item-action>
                          <v-layout v-if="!statusEdit">
                            <v-btn title="Edit field" icon x-small color="green" v-if="field.editable"
                                   class="mr-1" @click.native.stop="() => $emit('editField', field, item)">
                              <v-icon v-if="field">mdi-pencil</v-icon>
                            </v-btn>
                            <v-btn title="Delete field" icon x-small color="error"
                                   v-show="field.deletable"
                                   @click.native.stop="deleteField(item, index)">
                              <v-icon v-if="field">mdi-delete</v-icon>
                            </v-btn>
                          </v-layout>
                        </v-list-item-action>
                      </v-list-item>
                    </transition-group>
                  </draggable>
                </v-list>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </transition-group>
        </draggable>
      </v-expansion-panels>
      <v-dialog
          v-model="deleteDialog"
          max-width="500"
          persistent
      >
        <v-card>
          <v-card-title class="popup-header">
            <span style="font-size: 16px">CONFIRM DELETE</span>
            <v-spacer/>
            <v-btn :disabled="loading" icon small @click="deleteDialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>

          <v-card-text class="px-4 py-8" v-if="currentIndex">
            <p style="color: red; font-weight: bold; font-size: 16px">This action will delete "`{{currentType === 'field' ? currentSection.fields[currentIndex].name : listSection[currentIndex].fields.map(val => val.name).join(', ')}}`" fields of all records.</p>
            Are you sure you want to delete it?
            <p>This action cannot be undone</p>
          </v-card-text>
          <v-divider/>
          <v-card-actions class="popup-footer">
            <v-spacer/>
            <v-btn width="100" :loading="loading" color="warning" @click="submitDelete()" dark>Submit</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </v-layout>
</template>

<script>
import draggable from 'vuedraggable'

export default {
  name: "Section",
  components: {
    draggable
  },
  data () {
    return {
      panel: null,
      loading: false,
      deleteDialog: false,
      dragSection: false,
      drag: false,
      currentType: undefined,
      currentIndex: undefined,
      currentSection: undefined
    }
  },
  props: {
    currentInfo: {type: Object, default: () => {}},
    editing: {type: Boolean, default: false},
    currentSurvey: {type: Array, default: () => []},
    isEdit: {type: Boolean, default: false},
    oldSectionIndex: {},
    oldSectionDrag: {}
  },
  watch: {
    deleteDialog (val) {
      if (!val) this.currentIndex = undefined
    }
  },
  computed: {
    statusEdit: {
      get() {
        return this.editing
      },
      set(val) {
        this.$emit('update:editing', val)
      }
    },
    currentOldSectionDrag: {
      get() {
        return this.oldSectionDrag
      },
      set(val) {
        this.$emit('update:oldSectionDrag', val)
      }
    },
    currentOldSectionIndex: {
      get() {
        return this.oldSectionIndex
      },
      set(val) {
        this.$emit('update:oldSectionIndex', val)
      }
    },
    listSection: {
      get() {
        return this.currentSurvey
      },
      set(val) {
        this.$emit('update:currentSurvey', val)
      }
    },
    allowNextSection() {
      let tmpSections = JSON.parse(JSON.stringify(this.listSection))
      tmpSections.push({name: 'Submit Form', fields: []})
      return tmpSections
    },
    dragOptions() {
      return {
        animation: 300,
        group: 'description',
        disabled: false,
        ghostClass: 'ghost'
      }
    }
  },
  methods: {
    viewField (field, item) {
      if (!this.statusEdit) this.$emit('viewField', field, item)
    },
    checkValidSection(e) {
      this.currentOldSectionIndex = e.oldIndex
      this.currentOldSectionDrag = JSON.parse(JSON.stringify(this.listSection[e.oldIndex]))
    },
    deleteSection (index) {
      if (this.currentInfo && !this.currentInfo.deletable && this.isEdit) {
        this.currentIndex = index
        this.deleteDialog = true
        this.currentType = 'section'
      }
      else this.$emit('removeSection', index)
    },
    deleteField (section, index) {
      if (this.currentInfo && !this.currentInfo.deletable && this.isEdit) {
        this.currentSection  = section
        this.currentIndex = index
        this.deleteDialog = true
        this.currentType = 'field'
      }
      else section.fields.splice(index, 1)
    },
    submitDelete () {
      switch (this.currentType) {
        case 'field':
          this.currentSection.fields.splice(this.currentIndex, 1)
          break
        case 'section':
          this.$emit('removeSection', this.currentIndex)
          break
      }
      this.deleteDialog = false
    },
    getIcon(type) {
      switch (type) {
        case 'boolean':
          return 'mdi-radiobox-marked'
        case 'text':
          return 'mdi-format-text'
        case 'date':
          return 'mdi-calendar-range'
        case 'image':
          return 'mdi-image'
        case 'email':
          return 'mdi-email'
        case 'combobox':
          return 'mdi-checkbox-outline'
        case 'int':
        case 'numeric':
          return 'mdi-numeric'
        case 'custom':
          return 'mdi-focus-field-horizontal'
      }
    },
    checkSectionDeletable(currentSection) {
      if (currentSection.fields) return (!currentSection.fields.some(field => !field.deletable))
    },
  }
}
</script>

<style scoped>

</style>
