<template>
  <v-layout class="fill-height">
    <div class="flex mx-1" style="flex: 2;">
      <v-menu
          ref="menu"
          v-model="menu"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
              flat
              solo
              dense
              hide-details
              v-model="currentDateRanger"
              label="Date Ranger"
              placeholder="Date Ranger"
              readonly
              v-bind="attrs"
              v-on="on"
          ></v-text-field>
        </template>
        <v-date-picker
            v-model="currentDateRanger"
            range
        ></v-date-picker>
      </v-menu>
    </div>
    <div class="flex mx-1" style="flex: 2;">
      <v-autocomplete
          @change="getDistrict"
          v-model="currentState"
          :items="states"
          label="All State"
          placeholder="All State"
          flat
          solo
          hide-details
          dense
          item-text="name"
          item-value="id"
          class="select-box"
          :disabled="loading"
          clearable
      >
      </v-autocomplete>
    </div>
    <div class="flex mx-1" style="flex: 2;">
      <v-autocomplete
          @change="getSubDistrict"
          v-model="currentDistrict"
          :items="districts"
          label="All District"
          placeholder="All District"
          flat
          solo
          hide-details
          dense
          item-text="name"
          item-value="id"
          class="select-box"
          :disabled="loading"
          clearable
      >
      </v-autocomplete>
    </div>
    <div class="flex mx-1" style="flex: 2;">
      <v-autocomplete
          v-model="currentSubDistrict"
          :items="subDistricts"
          label="All SubDistricts"
          placeholder="All SubDistrict"
          flat
          solo
          hide-details
          dense
          item-text="name"
          item-value="id"
          class="select-box"
          :disabled="loading"
          clearable
      >
      </v-autocomplete>
    </div>
    <div class="flex mx-1" style="flex: 2;">
      <v-autocomplete
          v-model="currentType"
          :items="surveys"
          label="All Type"
          placeholder="All Type"
          flat
          solo
          hide-details
          dense
          item-text="name"
          item-value="id"
          class="select-box"
          :disabled="loading"
          clearable
      >
      </v-autocomplete>
    </div>
    <div class="flex mx-1" style="flex: 2;">
      <v-layout class="fill-height px-1" align-center justify-end>
        <div style="border: #fafafa 2px solid; border-radius: 4px; margin-top: -4px" class="mr-1">
          <v-btn title="Refresh" height="34" width="95" min-width="0" style="font-size: 10px;"
                 @click="refreshFilter"
                 color="info" class="elevation-0">
            <v-icon size="20">mdi-refresh</v-icon>
            Refresh
          </v-btn>
        </div>
        <v-menu
            :close-on-content-click="false"
            absolute
            offset-y
            style="width: 300px"
        >
          <template v-slot:activator="{ on, attrs }">
            <div style="border: #fafafa 2px solid; border-radius: 4px; margin-top: -4px" class="ml-1">
              <v-btn
                  v-bind="attrs"
                  v-on="on"
                  :disabled="!currentType"
                  title="Download"
                  height="34"
                  width="95"
                  min-width="0"
                  style="font-size: 10px;"
                  color="info"
                  class="elevation-0">
                <v-icon size="20">mdi-download</v-icon>
                Download
              </v-btn>
            </div>
          </template>

          <v-list dense color="backgroundForm" width="230">
            <v-hover v-slot="{ hover }" v-for="(action, index) in status" :key="index">
              <v-list-item color="#893FF2" :style="{'background-color': hover ? 'var(--v-backgroundList-base)' : ''}">
                <v-list-item-title :style="{'color': action.color}">{{ action.name }}</v-list-item-title>
                <v-list-item-action>
                  <v-layout class="pr-8">
                    <v-btn title="*.geojson" color="primary" fab x-small class="mr-1 elevation-0" @click="downloadData(action.name, action.code, 'geojson')">
                      <v-icon size="16" color="white">mdi-code-json</v-icon>
                    </v-btn>
                    <v-btn title="*.xlsx" color="primary" fab x-small class="ml-1" @click="downloadData(action.name, action.code, 'xlsx')" >
                      <v-icon size="16" color="white">mdi-file-excel-outline</v-icon>
                    </v-btn>
                  </v-layout>
                </v-list-item-action>
              </v-list-item>
            </v-hover>
          </v-list>
        </v-menu>
      </v-layout>
    </div>
  </v-layout>
</template>

<script>
import {mapState} from "@/store/ults";
import {getDistrict, getSubDistrict} from "@/backend";

export default {
  name: "FilterForm",
  data() {
    return {
      status: [
        {
          name: 'All Farms',
          code: undefined,
          color: '#4aa94e'
        },
        {
          name: 'Pending Farms',
          code: 1,
          color: '#8008fa'
        },
        {
          name: 'Verified Farms',
          code: 2,
          color: '#2974ff'
        },
        {
          name: 'Modified Farms',
          code: 3,
          color: '#ff6229'
        },
        {
          name: 'Rejected Farms',
          code: 4,
          color: '#ff2929'
        }
      ],
      loading: false,
      menu: false,
      districts: [],
      subDistricts: [],
    }
  },
  props: {
    dateRanger: {type: Array, default: []},
    state: {type: Number, default: undefined},
    district: {type: Number, default: undefined},
    subDistrict: {type: Number, default: undefined},
    type: {type: String, default: undefined}
  },
  computed: {
    ...mapState("dashboard", ["states", "surveys"]),
    currentDateRanger: {
      get() {
        return this.dateRanger
      },
      set(val) {
        this.$emit('update:dateRanger', val)
      }
    },
    currentSeason: {
      get() {
        return this.season
      },
      set(val) {
        this.$emit('update:season', val)
      }
    },
    currentCountry: {
      get() {
        return this.country
      },
      set(val) {
        this.$emit('update:country', val)
      }
    },
    currentState: {
      get() {
        return this.state
      },
      set(val) {
        this.$emit('update:state', val)
      }
    },
    currentDistrict: {
      get() {
        return this.district
      },
      set(val) {
        this.$emit('update:district', val)
      }
    },
    currentSubDistrict: {
      get() {
        return this.subDistrict
      },
      set(val) {
        this.$emit('update:subDistrict', val)
      }
    },
    currentType: {
      get() {
        return this.type
      },
      set(val) {
        this.$emit('update:type', val)
      }
    }
  },
  methods: {
    async getDistrict(stateId) {
      try {
        this.loading = true
        this.districts = []
        this.subDistricts = []
        const res = await getDistrict({parent_id: stateId})
        this.districts = res.data
      } catch (e) {

      } finally {
        this.loading = false
      }
    },
    async getSubDistrict(districtId) {
      try {
        this.loading = true
        this.subDistricts = []
        const res = await getSubDistrict({parent_id: districtId})
        this.subDistricts = res.data
      } catch (e) {

      } finally {
        this.loading = false
      }
    },
    refreshFilter() {
      this.currentType = undefined
      this.currentState = undefined
      this.getDistrict()
    },
    downloadData(name, status, type) {
      this.$emit('downloadData', name, status, type)
    }
  }
}
</script>

<style scoped>

</style>
