const getRandomColor = {
  getColor(index) {
    const letters = {
      min: '#607d8b',
      max: '#e91e63',
      mean: '#00b8d4',

      Stressed: '#ff0000',
      SlightlyStressed: '#ed7d31',
      Moderate: '#ffff00',
      Healthy: '#92d050',
      VeryHealthy: '#375623',

      Open: '#c00',
      LowDense: '#ff0000',
      SlightlyDense: '#ed7d31',
      Dense: '#ffff00',
      HightlyDense: '#92d050',
      VeryHigh: '#375623',

      VeryLow: '#ff0000',
      Slightly: '#ed7d31',
      Normal: '#ffff00',
      Hightly: '#92d050',

      ExtremelyLow: '#c00',
      Low: '#ed7d31',
      High: '#92d050',

      VeryEarly: '#ed7d31',
      Early: '#ffff00',
      Timely: '#92d050',
      SlightlyLate: '#375623',
    }
    return letters[index]
  }
}
export default getRandomColor
