<template>
  <div style="position: relative; height: 100%; width:100%; overflow: hidden; border-radius: 8px" ref="mapBound">
    <div class="map-container" ref="mapContainer"/>
    <v-layout style="width: 33px; height: 200px; position: absolute; top: 10px; right: 10px" column>
      <v-btn
          v-if="editing"
          style="position: absolute; top: 5px; right: 45px; z-index: 2; font-size: 12px; border-top-left-radius: 0; border-bottom-left-radius: 0"
          min-width="0"
          min-height="0"
          width="75"
          height="29"
          @click="cancelEdit">
        cancel
      </v-btn>
      <v-btn
          v-if="editing"
          style="position: absolute; top: 5px; right: 120px; z-index: 2; font-size: 12px; border-top-right-radius: 0; border-bottom-right-radius: 0"
          min-width="0"
          min-height="0"
          width="75"
          height="29"
          @click="saveGeometry">
        save
      </v-btn>

      <v-tooltip bottom color="#000000c4" v-if="editable">
        <template v-slot:activator="{ on, attrs }">
          <v-btn v-bind="attrs" v-on="on" class="my-1" :disabled="editing" min-width="0" min-height="0" width="29"
                 height="29"
                 @click="editLayer"
                 style="z-index: 2">
            <v-icon>mdi-square-edit-outline</v-icon>
          </v-btn>
        </template>
        <span>Edit</span>
      </v-tooltip>
      <v-tooltip bottom color="#000000c4">
        <template v-slot:activator="{ on, attrs }">
          <v-btn v-bind="attrs" v-on="on" class="my-1" v-if="focusable" min-width="0" min-height="0" width="29"
                 height="29"
                 @click="zoomToLayer('origin_vector')"
                 style="z-index: 2">
            <v-icon>mdi-crosshairs-gps</v-icon>
          </v-btn>
        </template>
        <span>Zoom to</span>
      </v-tooltip>
      <v-tooltip bottom color="#000000c4">
        <template v-slot:activator="{ on, attrs }">
          <v-btn v-bind="attrs" v-on="on" class="mt-1" @click="mapFullscreen" style="" min-height="0" min-width="0"
                 height="32" width="32">
            <v-icon size="22" v-if="!isMapFullscreen">mdi-fullscreen</v-icon>
            <v-icon size="22" v-else>mdi-fullscreen-exit</v-icon>
          </v-btn>
        </template>
        <span>Fullscreen</span>
      </v-tooltip>
    </v-layout>
    <div
        v-if="modified"
        class="legend elevation-1">
      <v-layout class="fill-height py-1 px-2" column>
        <div title="Click to zoom to Updated Farm"
             style="flex: 1; padding-bottom: 2px; font-weight: bold; cursor: pointer" class="d-flex"
             @click="zoomToLayer('updated_vector')">
          <div style="border-radius: 4px; width: 18px; height: 18px; background-color: red" class="mr-3 elevation-1">
          </div>
          Updated Farm
        </div>
        <div title="Click to zoom to Original Farm"
             style="flex: 1; padding-top: 2px; font-weight: bold; cursor: pointer" class="d-flex"
             @click="zoomToLayer('origin_vector')">
          <div style="border-radius: 4px; width: 18px; height: 18px; background-color: #14fc03"
               class="mr-3 elevation-1">
          </div>
          Original Farm
        </div>
      </v-layout>
    </div>
    <div class="layer-control elevation-1" v-if="imageSelected">
      <v-layout class="fill-height py-1 px-2" column>
        <v-list dense class="overflow-y-auto py-0" style="background-color: transparent">
          <v-radio-group v-model="imageSelected" hide-details class="mt-0">
            <v-list-item v-for="(layer, index) of imageLayers" :key="index" class="px-0"
                         style="height: 35px; min-height: 0">
              <div style="font-size: 14px; width: 90px;" class="text-truncate">{{ layer.name }}</div>
              <v-list-item-action class="my-2">
                <v-radio
                    @click="changeVisibility(layer.id)"
                    class="image-radio"
                    style="width: 16px; height: 16px"
                    :value="layer.id"
                ></v-radio>
              </v-list-item-action>
            </v-list-item>
          </v-radio-group>
        </v-list>
      </v-layout>
    </div>
    <div class="popup-area elevation-1" v-if="$attrs['displayArea']">
      <v-layout class="fill-height px-2" style="font-size: 11px; color: #434448; font-weight: bold" align-center>
        Area (in ha): {{ area }}
      </v-layout>
    </div>
    <v-overlay :value="loading" absolute>
      <v-progress-circular
          indeterminate
          size="64"
      ></v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css'
// import {CompassControl, ZoomControl} from "mapbox-gl-controls"
import sleep from "@/ultis/sleep"
import utils from "@/ultis/genUUID"
import VectorLayer from "@/models/layer/vector/vector"
import RasterLayer from "@/models/layer/raster/raster"
import MapboxDraw from "@mapbox/mapbox-gl-draw"
import {CircleMode, DirectMode, DragCircleMode, SimpleSelectMode} from "@/ultis/draw"
import mapStyle from "@/assets/MapStyle/mapstyle.json";

let map
let draw
let popup
let marker
let markers = []

const clusterMarkers = {}
let markersOnScreen = {}
export default {
  name: "Map",
  data() {
    return {
      isMapFullscreen: false,
      editing: false,
      map: undefined,
      clusterId: undefined,
      loading: false,
      layers: [],
      imageLayers: [],
      imageSelected: undefined,
      currentGeometry: {},
    }
  },
  computed: {},
  props: {
    focusable: {type: Boolean, default: false},
    area: {type: String, default: '0'},
    editable: {type: Boolean, default: false},
    modified: {type: Boolean, default: false},
  },
  mounted() {
    window.mapboxgl.accessToken = 'pk.eyJ1IjoiaG9hdGllbnR1IiwiYSI6ImNrYXMwNmt4ZzA4YTIyeXAzcjZicmhsNXMifQ.9hvfCuoiO1-1cFmikE14LA'
    this.map = new window.mapboxgl.Map({
      container: this.$refs.mapContainer,
      style: mapStyle,
      center: [105.8466249704361, 21.003883312911878],
      zoom: 1,
      attributionControl: false
    })
    this.map.dragRotate.disable()
    this.map.touchZoomRotate.disableRotation()
    draw = new MapboxDraw({
      keybindings: true,
      displayControlsDefault: false,
      userProperties: true,
      controls: {
        line_string: false,
        polygon: false,
        trash: false
      },
      modes: {
        ...MapboxDraw.modes,
        draw_circle: CircleMode,
        drag_circle: DragCircleMode,
        direct_select: DirectMode,
        simple_select: SimpleSelectMode
      }
    })
    this.map.addControl(new window.mapboxgl.ScaleControl({
      maxWidth: 80,
      unit: 'metric'
    }), 'bottom-right')
    // this.map.addControl(new ZoomControl(), 'bottom-right')
    // this.map.addControl(new CompassControl(), 'bottom-right')
    this.map.addControl(draw)
    this.map.on("style.load", () => {
      this.reSize()
      // this.displayCustomCluster()
    })
    addEventListener('fullscreenchange', (e) => {
      if (!document.fullscreenElement) this.isMapFullscreen = false
    })
  },
  methods: {
    async reSize() {
      let mapCanvas = document.getElementsByClassName('mapboxgl-canvas')[0]
      mapCanvas.style.height = '100%'
      mapCanvas.style.width = '100%'
      await sleep(0)
      this.map.resize()
    },
    mapFullscreen() {
      this.isMapFullscreen = !this.isMapFullscreen
      if (this.isMapFullscreen) this.$refs.mapBound.parentElement.requestFullscreen()
      else document.exitFullscreen()
      // this.$emit('mapFullScreen')
    },
    displayCluster(data) {
      this.layers.forEach(layer => {
        this.map.removeLayer(layer)
      })
      if (this.clusterId) this.map.removeSource(this.clusterId)
      this.clusterId = utils.getUUID()
      this.layers = []
      this.map.addSource(this.clusterId, {
        type: 'geojson',
        data: data,
        cluster: true,
        clusterMaxZoom: 17,
        clusterRadius: 17
      })
      // Add layer to map
      this.map.addLayer({
        id: 'clusters' + this.clusterId,
        type: 'circle',
        source: this.clusterId,
        filter: ['has', 'point_count'],
        paint: {
          'circle-stroke-width': 3,
          'circle-stroke-color': '#fffdfd',
          'circle-color': [
            'step',
            ['get', 'point_count'],
            '#48c202',
            4,
            '#00606C',
            10,
            '#ff4f00',
            100,
            '#C01754',
            200,
            '#C734CA',
            500,
            '#66023C',
            100000,
            '#000063'
          ],
          'circle-radius': [
            'step',
            ['get', 'point_count'],
            17,
            25,
            30,
            50,
            70
          ]
        }
      })
      this.map.addLayer({
        id: 'cluster-count' + this.clusterId,
        type: 'symbol',
        source: this.clusterId,
        filter: ['has', 'point_count'],
        layout: {
          'text-field': '{point_count_abbreviated}',
          'text-font': ['Open Sans Semibold', 'Arial Unicode MS Bold'],
          'text-size': 14,
          'text-allow-overlap': true,
          'icon-allow-overlap': true,
          'icon-ignore-placement': true,
          'text-ignore-placement': true
        },
        paint: {
          "text-color": "#fafafa"
        }
      })
      this.map.addLayer({
        id: 'unclustered-point' + this.clusterId,
        type: 'circle',
        source: this.clusterId,
        filter: ['!', ['has', 'point_count']],
        paint: {
          'circle-color': '#ff0014',
          'circle-radius': 8,
          'circle-stroke-width': 3,
          'circle-stroke-color': '#ececec'
        }
      })
      // on Click layer
      this.map.on('click', 'clusters' + this.clusterId, (e) => {
        const features = this.map.queryRenderedFeatures(e.point, {
          layers: ['clusters' + this.clusterId]
        })
        const clusterId = features[0].properties.cluster_id
        this.map.getSource(this.clusterId).getClusterExpansionZoom(
            clusterId,
            (err, zoom) => {
              if (err) return
              this.map.easeTo({
                center: features[0].geometry.coordinates,
                zoom: zoom
              })
            }
        )
      })
      this.map.on('click', 'unclustered-point' + this.clusterId, async (e) => {
        await this.$emit('goToDetail', e.features[0].properties)
        if (document.fullscreenElement) await document.exitFullscreen()
        // await this.getDetail(e.features[0].properties)
        // this.displayPopup(e)
      })
      // Hover layer
      this.map.on('mouseenter', 'clusters' + this.clusterId, () => {
        this.map.getCanvas().style.cursor = 'zoom-in'
      })
      this.map.on('mouseleave', 'clusters' + this.clusterId, () => {
        this.map.getCanvas().style.cursor = ''
      })
      this.map.on('mouseenter', 'unclustered-point' + this.clusterId, () => {
        this.map.getCanvas().style.cursor = 'pointer'
      })
      this.map.on('mouseleave', 'unclustered-point' + this.clusterId, () => {
        this.map.getCanvas().style.cursor = ''
      })

      this.layers.push('clusters' + this.clusterId, 'cluster-count' + this.clusterId, 'unclustered-point' + this.clusterId)
    },
    editLayer() {
      let layer = this.layers.find(layer => layer.id === 'origin_vector')
      if (layer) {
        this.map.setLayoutProperty(layer.id, "visibility", "none")
        let feature = {
          "type": "FeatureCollection",
          "features": [
            {
              "type": "Feature",
              "properties": {},
              "geometry": layer.geometry
            }
          ]
        }
        let ids = draw.set(feature)
        draw.changeMode('direct_select', {
          featureId: ids[0],
        })
      }
      if (markers.length) {
        markers.forEach(marker => {
          marker.setDraggable(true)
        })
      }
      this.editing = true
    },
    cancelEdit() {
      let layer = this.layers.find(layer => layer.id === 'origin_vector')
      if (layer) {
        this.map.setLayoutProperty(layer.id, "visibility", "visible")
        draw.deleteAll()
      }
      if (markers.length) {
        markers.forEach(marker => {
          marker.setLngLat(this.currentGeometry.coordinates)
          marker.setDraggable(false)
        })
      }
      this.editing = false
    },
    saveGeometry() {
      if (markers.length) {
        markers.forEach(marker => {
          marker.setDraggable(false)
          this.$emit('updateGeometry', {
            type: 'featureCollection',
            features: [
              {
                type: "Feature",
                properties: {},
                geometry: {
                  type: 'Point',
                  coordinates: [marker._lngLat.lng, marker._lngLat.lat]
                }
              }
            ]
          })
        })
      } else {
        this.map.getSource('origin_vector').setData(draw.getAll())
        let layer = this.layers.find(layer => layer.id === 'origin_vector')
        this.map.setLayoutProperty(layer.id, "visibility", "visible")
        this.$emit('updateGeometry', draw.getAll())
        draw.deleteAll()
      }
      this.editing = false
    },
    displayVector(geometry, type, color, id) {
      this.currentGeometry = geometry
      switch (type) {
        case 'marker':
          this.addMarkerToMap(geometry, color)
          break
        case 'polygon':
          this.addPolygonToMap(geometry, color, id)
          break
      }
    },
    addMarkerToMap(geometry, color) {
      let currentMarker = new mapboxgl.Marker({color: color})
          .setLngLat(geometry.coordinates)
          .addTo(this.map)
      markers.push(currentMarker)
      this.submitFlyTo(geometry.coordinates)
    },
    addPolygonToMap(geometry, color, id) {
      this.map.addSource(id, {
        'type': 'geojson',
        'data': geometry
      })
      let vector = new VectorLayer({
        'id': id,
        'type': 'line',
        'geometry': geometry,
        'color': color,
        'style': {
          'line-color': color,
          'line-width': 2
        }
      })
      let layer = vector.getMapboxLayer()
      this.map.addLayer(layer)
      this.layers.push(layer)
      this.submitZoom(layer.bbox)
    },
    addMultiImageToMap(layers) {
      this.removeAllImage()
      layers.forEach((layer, index) => {
        this.addImageToMap(layer.tileUrl, layer.date, !index ? 'visible' : 'none')
      })
    },
    addImageToMap(tileUrl, name, visibility) {
      // let bbox = this.layers[0].bbox
      let bbox = [75.991245, 18.475327, 75.992994, 18.476785]
      let id = utils.getUUID()
      let raster = new RasterLayer({
        id: id,
        name: name,
        tileUrl: tileUrl,
        bbox: bbox,
        visibility: visibility
      })
      this.map.addLayer(raster.getMapboxLayer())
      this.submitZoom(bbox)
      this.imageLayers.push(raster.getMapboxLayer())
      if (visibility === 'visible') {
        this.imageSelected = raster.getMapboxLayer().id
      }
    },
    removeAllImage() {
      this.imageLayers.forEach(layer => {
        this.removeLayer(layer.id)
      })
      this.imageSelected = undefined
      this.imageLayers = []
    },
    removeLayer(layerId) {
      this.map.removeLayer(layerId)
    },
    changeVisibility(layerId) {
      this.imageLayers.forEach(layer => {
        this.map.setLayoutProperty(layer.id, 'visibility', 'none')
      })
      this.map.setLayoutProperty(layerId, 'visibility', 'visible')
    },
    zoomToLayer(layerId) {
      let layer = this.layers.find(val => val.id === layerId)
      if (layer) this.submitZoom(layer.bbox)
      else if (markers.length) {
        switch (layerId) {
          case 'updated_vector':
            this.submitFlyTo([markers[1]._lngLat.lng, markers[1]._lngLat.lat])
            break
          case 'origin_vector':
            this.submitFlyTo([markers[0]._lngLat.lng, markers[0]._lngLat.lat])
            break
        }
      }
    },
    submitFlyTo(point) {
      this.map.easeTo({
        center: point,
        zoom: 15.5
      })
    },
    submitZoom(bbox) {
      this.map.fitBounds(bbox, {
        'duration': 0,
        'padding': 130
      })
    }
  },
  destroyed() {
    if (map) {
      map.remove()
      draw = undefined
      map = undefined
      markers = []
      this.imageLayers = []
      this.layers = []
    }
  }
}
</script>

<style scoped>
.map-container {
  width: 100%;
  height: 100%;
}

.legend {
  width: 150px;
  height: 55px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  background-color: rgba(255, 255, 255, 0.69);
  border-bottom-right-radius: 8px
}

.layer-control {
  width: 150px;
  height: 110px;
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 2;
  background-color: rgba(255, 255, 255, 0.69);
  border-radius: 8px
}

.popup-area {
  min-width: 150px;
  height: 22px;
  position: absolute;
  bottom: 10px;
  left: 10px;
  z-index: 2;
  background-color: rgba(255, 255, 255, 0.69);
  border-radius: 4px
}

/deep/
.image-radio i {
  font-size: 18px;
}

/deep/
.image-radio .v-input--selection-controls__ripple {
  width: 0;
  height: 0;
}
</style>
