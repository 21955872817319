<template>
  <v-card width="100%" height="100%" class="d-flex" style="background-color: var(--v-backgroundForm-base)">
    <v-layout class="fill-height" column>
      <Dashboard />
    </v-layout>
  </v-card>
</template>

<script>
import Dashboard from "@/components/home/dashboard"
export default {
  components: {Dashboard },
  name: "index",
  data () {
    return {
      mode: 'app',
    }
  },
  methods: {
    goToLink(link) {
      if (this.$route.path !== link) this.$router.push({ path: link });
    },
  }
}
</script>

<style scoped>
/deep/
.tab-menu .v-tabs-slider-wrapper {
  top: 0 !important;
}
</style>
