<template>
  <v-dialog
      v-model="updateUserDialog"
      max-width="500"
      persistent
  >
    <v-card>
      <v-card-title class="popup-header">
        <span style="font-size: 16px">UPDATE COMPANY</span>
        <v-spacer/>
        <v-btn icon small @click="updateUserDialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text class="pa-4">
        <v-form @submit="updateUser" onSubmit="return false;" v-model="valid" ref="formUpdate">
          <v-text-field outlined dense v-model="currentUser.name" label="Company name" :rules="[rules.requiredUserName]"></v-text-field>
          <v-text-field outlined dense v-model="currentUser.code" label="Code" :rules="[rules.requiredCode]"></v-text-field>
          <v-autocomplete
              class="input-login"
              background-color="white"
              dense
              outlined
              :items="countries"
              item-text="name"
              item-value="id"
              v-model="currentUser.country_id"
              label="Country"
              placeholder="Country"
              style="color: black !important;"
              :rules="[rules.requiredCountry]"
          >
          </v-autocomplete>
        </v-form>
      </v-card-text>
      <v-divider/>
      <v-card-actions class="popup-footer">
        <v-spacer/>
        <v-btn width="100" color="primary" @click="updateUser" dark>Submit</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {
  getCountry,
  updateCompany
} from "@/backend";
export default {
  name: "PopupUpdateUser",
  data () {
    return {
      id: undefined,
      countries: [],
      loading: false,
      updateUserDialog: false,
      valid: false,
      currentUser: {},
      rules: {
        requiredUserName: value => (!!value && !!value.trim()) || 'User name is required',
        requiredCode: value => (!!value && !!value.trim()) || 'Code is required',
        requiredCountry: value => !!value || 'Country is required'
      }
    }
  },
  props: {
  },
  mounted() {
    this.getCountry()
  },
  watch: {
    updateUserDialog (val) {
      if (!val) {
        this.currentUser = {}
        if (this.$refs.formUpdate) this.$refs.formUpdate.resetValidation()
      }
    }
  },
  methods: {
    openDialog (user) {
      this.currentUser = JSON.parse(JSON.stringify(user))
      this.updateUserDialog = true
    },
    async getCountry() {
      try {
        this.loading = true
        const res = await getCountry()
        this.countries = res.data
      } catch (e) {
      } finally {
        this.loading = false
      }
    },
    async updateUser () {
      this.$refs.formUpdate.validate()
      if (this.valid) {
        this.loading = true
        try {
          await updateCompany({
            id: this.currentUser.id,
            data: this.currentUser
          })
          this.$store.commit('message/SHOW_SUCCESS', 'Success')
          this.$emit('getUser')
          this.updateUserDialog = false
        } catch (e) {
        } finally {
          this.loading = false
        }
      }
    }
  }
}
</script>

<style scoped>

</style>
