<template>
  <v-layout class="fill-height pa-2" column>
    <div style="width: 100%; height: 120px; border-bottom: 1px solid lightgrey; flex: none">
      <v-layout
          align-center
          justify-center
          column
          class="fill-height"
          style="background-color: var(--v-bar-base);border-radius: 6px""
      >
        <v-layout
            class="pt-2"
            style="font-size: 32px; font-weight: bold; width: 100%; color: #fafafa; text-transform: uppercase"
            justify-center
            align-center
        >
          <v-icon color="white" size="40" class="mr-1">mdi-currency-usd</v-icon>
          Payment
        </v-layout>
        <v-container fluid class="flex-fill overflow-y-hidden py-0" style="height: 40px; max-width: 1550px">
          <v-layout justify-end align-center class="fill-height">
            <PaymentToken/>
          </v-layout>
        </v-container>
      </v-layout>
    </div>
    <v-container fluid class="d-flex overflow-y-hidden flex-column" style="height: 100%; max-width: 1550px">
      <v-layout fill-height style="overflow-y: auto;">
        <v-data-table
            height="100%"
            fixed-header
            style="height: 100%; width: 100%"
            :headers="headers"
            :items="bills"
            :loading="loading"
            :items-per-page="10"
            class="row-pointer order-table d-flex flex-column"
            hide-default-footer
            :server-items-length.sync="totalItem"
            loading-text="Loading... Please wait"
            :options.sync="options"
            :page.sync="page"
        >
          <template v-slot:[`item.token_change`]="{ item }">
            <v-chip small :color="item.token_change > 0 ? 'primary' : 'red'" dark style="width: 50px; text-align: center">
                {{ item.token_change }}
            </v-chip>
          </template>
          <template v-slot:[`item.created_at`]="{ item }">
            {{convertDate(item.created_at)}}
          </template>
          <template v-slot:[`item.datetime_change`]="{ item }">
            {{convertDate(item.datetime_change)}}
          </template>
        </v-data-table>
      </v-layout>
      <div class="text-center pa-3" style="flex: none; height: auto">
        <v-pagination :total-visible="5" v-model="page" :length="pageCount" id="task_pagination"></v-pagination>
      </div>
    </v-container>
  </v-layout>
</template>

<script>
import {mapState} from "@/store/ults"
import PaymentToken from "@/components/home/payment/PaymentToken"
import {getPaymentHistory} from "@/backend"
import convertDate from "@/ultis/convertDate"

export default {
  name: "index",
  components: {PaymentToken},
  data () {
    return {
      options: {},
      headers: [
        {text: "Date time change", align: "left", sortable: true, value: "datetime_change",},
        {text: "Token change", align: "left", sortable: true, value: "token_change",},
        {text: "Created by", align: "left", sortable: true, value: "created_by",},
        {text: "Description", align: "left", sortable: true, value: "description"}
      ],
      bills: [],
      totalItem: 0,
      page: 1,
      pageCount: 1,
      loading: false,
      token: undefined,
      rules: {
        token: value => !!value || 'Token is required'
      }
    }
  },
  computed: {
    ...mapState("auth", ["currentUser"]),
  },
  watch: {
    options: {
      handler() {
        this.getData()
      },
      deep: true
    },
  },
  mounted() {
  },
  methods: {
    getParam() {
      let sort
      if (this.options.sortBy.length && this.options.sortBy.length) {
        if (this.options.sortDesc[0]) sort = '-' + this.options.sortBy[0]
        else sort = this.options.sortBy[0]
      }
      let param = {}
      param['per_page'] = 10
      param['page'] = this.options.page
      param['sort'] = sort
      return param
    },
    async getData() {
      try {
        this.reports = []
        this.loading = true
        const res = await getPaymentHistory(this.getParam())
        this.bills = res.data.data
        this.totalItem = res.data.total
        this.pageCount = res.data.last_page
      } catch (e) {

      } finally {
        this.firstLoad = false
        this.loading = false
      }
    },
    convertDate (date) {
      return convertDate.dateFormatted(date)
    }
  },
}
</script>

<style scoped>
/deep/
.v-data-table > .v-data-table__wrapper tbody tr.v-data-table__expanded__content {
  box-shadow: inset 0px 4px 6px -5px rgb(50 50 50 / 75%),
  inset 0px -4px 8px -5px rgb(50 50 50 / 0%);
}

/deep/
.order-table th {
  background-color: var(--v-primary-base) !important;
  color: white !important;
}

/deep/
.list-image th {
  background-color: #6686a3 !important;
}

/deep/
.v-data-table__wrapper {
  border: 1px lightgrey solid;
  border-radius: 8px;
}
</style>
