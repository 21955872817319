<template>
  <v-layout class="fill-height">
    <div class="flex d-flex flex-column pr-2" style="flex: 1">
      <span style="font-size: 14px; font-weight: bold" class="mt-1">Name</span>
      <div style="width: 100%;">
        <v-text-field class="mt-1" outlined dense v-model="currentSurveyName" label="Name"
                      :rules="[rules.requiredName]"></v-text-field>
      </div>
    </div>
    <div class="flex d-flex flex-column pl-2" style="flex: 1">
      <span style="font-size: 14px; font-weight: bold" class="mt-1">Description</span>
      <div style="width: 100%;">
        <v-text-field class="mt-1" outlined dense v-model="currentSurveyDescription"
                      label="Description"></v-text-field>
      </div>
    </div>
    <div class="flex d-flex flex-column pl-2" style="flex: 1" v-if="currentUser.is_sysadmin">
      <span style="font-size: 14px; font-weight: bold" class="mt-1">Type</span>
      <div style="width: 100%;">
        <v-text-field class="mt-1" outlined dense v-model="currentSurveyType"
                      label="Type"></v-text-field>
      </div>
    </div>
  </v-layout>
</template>

<script>
import {mapState} from "@/store/ults"

export default {
  name: "SurveyInfo",
  data() {
    return {
      rules: {
        requiredName: value => (!!value && !!value.trim()) || 'Name is required'
      }
    }
  },
  props: {
    surveyName: {type: String, default: ''},
    surveyDescription: {type: String, default: ''},
    surveyType: {type: String, default: ''},
  },
  computed: {
    ...mapState("auth", ["currentUser"]),
    currentSurveyName: {
      get() {
        return this.surveyName
      },
      set(val) {
        this.$emit('update:surveyName', val)
      }
    },
    currentSurveyDescription: {
      get() {
        return this.surveyDescription
      },
      set(val) {
        this.$emit('update:surveyDescription', val)
      }
    },
    currentSurveyType: {
      get() {
        return this.surveyType
      },
      set(val) {
        this.$emit('update:surveyType', val)
      }
    },
  },
}
</script>

<style scoped>

</style>
