<template>
  <v-card>
    <v-card-title class="popup-header">
      <span style="font-size: 16px; text-transform: uppercase">{{ title }}</span>
      <v-spacer/>
      <v-btn icon small @click="closeForm">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-card-title>

    <v-card-text class="pa-4">
      <v-form @submit="submitData" onSubmit="return false;" v-model="valid" ref="formData">
        <v-text-field outlined dense v-model="form.name" label="Name"
                      :rules="[rules.requiredUserName]"></v-text-field>
        <v-text-field outlined dense v-model="form.email" label="Email" :rules="[rules.requiredEmail]"></v-text-field>
        <v-text-field outlined dense v-model="form.password" label="Password"
                      :rules="passwordRequired ? [rules.requiredPassword] : []"
                      :type="isShowPassword ? 'text' : 'password'"
                      :append-icon="isShowPassword ? 'mdi-eye-off' : 'mdi-eye'"
                      @click:append="isShowPassword = !isShowPassword"
        >
        </v-text-field>
        <v-text-field outlined dense v-model="form.phone" label="Phone number"></v-text-field>
        <v-select multiple small-chips outlined dense v-model="form.role_ids" :items="roles" label="Role"
                  :rules="[rules.role]"
                  item-text="name" item-value="id"></v-select>
      </v-form>
    </v-card-text>
    <v-divider/>
    <v-card-actions class="popup-footer">
      <v-btn :disabled="loading" width="120" class="mr-2" color="cancel" @click="resetData">
        <v-icon class="mr-1">mdi-close</v-icon>
        Reset
      </v-btn>
      <v-spacer/>
      <v-btn :loading="loading" width="120" color="primary" @click="submitData" dark>
        <v-icon class="mr-1">mdi-check</v-icon>
        Submit
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>

export default {
  name: "UserForm",
  data() {
    return {
      valid: false,
      form: {
        password: undefined,
        phone: undefined,
        name: undefined,
        email: undefined,
        role_ids: [],
      },
      isShowPassword: false,
      rules: {
        requiredUserName: value => (!!value && !!value.trim()) || 'Name is required',
        requiredPassword: value => (!!value && !!value.trim()) || 'Password is required',
        requiredEmail: value => (!!value && !!value.trim()) || 'Email is required',
        role: value => !!value.length || 'Role is required'
      },
      phoneRules: [
        v => !!v || 'Phone number is required',
        v => /[0-9]{8,}$/.test(v) || 'Phone number must be valid'
      ]
    }
  },
  props: {
    currentUser: {
      type: Object, default: () => {
      }
    },
    loading: {type: Boolean, default: false},
    passwordRequired: {type: Boolean, default: false},
    title: {type: String, default: ''},
    roles: {type: Array, default: () => []},
    submit: {type: Function},
    closeDialog: {type: Function}
  },
  watch: {
    currentUser: {
      handler(val) {
        this.setData(val)
      },
      deep: true
    }
  },
  mounted() {
    this.resetData()
  },
  methods: {
    setData(currentUser) {
      this.form.phone = currentUser.phone
      this.form.name = currentUser.name
      this.form.email = currentUser.email
      this.form.role_ids = currentUser.role_ids
    },
    submitData() {
      this.$refs.formData.validate()
      if (this.valid) this.submit(this.form)
    },
    closeForm() {
      this.resetData()
      this.closeDialog()
    },
    resetData() {
      this.form = {
        password: undefined,
        phone: undefined,
        name: undefined,
        email: undefined,
        role_ids: [],
      }
      if (this.$refs.formData) this.$refs.formData.resetValidation()
    },
  }
}
</script>

<style scoped>

</style>
