<template>
<v-dialog width="500" persistent v-model="dialog">
  <v-card>
    <v-card-title class="popup-header">
      <span style="text-transform: uppercase">Select table column</span>
      <v-spacer/>
      <v-btn icon small @click="cancelSelect"><v-icon>mdi-close</v-icon></v-btn>
    </v-card-title>
    <v-card-text>
      <div style="flex: none; border-bottom: 1px solid lightgray; height: 50px; min-height: 50px" class="mb-1">
        <v-layout style="height: 100%;" align-center>
          <v-checkbox
              @change="changeStatusSelectAll"
              hide-details
              class="mt-0"
              label="Select All Column"
              v-model="selectAll"
          ></v-checkbox>
          <v-spacer/>
          <v-tooltip bottom color="#00000099">
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-bind="attrs" v-on="on" icon small @click="restoreFields"><v-icon>mdi-refresh</v-icon></v-btn>
            </template>
            <span>Restore</span>
          </v-tooltip>
        </v-layout>
      </div>
      <v-list style="max-height: 55vh; overflow-y: auto">
        <v-list-item v-for="(field, index) in listField" :key="index">
          <v-list-item-action>
            <v-checkbox
                @change="onSelectField"
              v-model="field.default"
              ></v-checkbox>
          </v-list-item-action>
          <v-list-item-content>
            {{ field.name }}
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-card-text>
    <v-divider/>
    <v-card-actions class="popup-footer">
      <v-spacer/>
      <v-btn width="120" color="accent" @click="changeColumnSetting">Submit</v-btn>
    </v-card-actions>
  </v-card>
</v-dialog>
</template>

<script>
export default {
  name: "ColumnSetting",
  data () {
    return {
      oldFields: [],
      dialog: false,
      selectAll: false,
    }
  },
  watch: {
    dialog (val) {
      if (!val) this.oldFields = []
    }
  },
  props: {
    fields: {type: Array, default: () => []}
  },
  computed: {
    listField: {
      get() {
        return this.fields
      },
      set(val) {
        this.$emit('update:fields', val)
      }
    }
  },
  methods: {
    openDialog () {
      this.oldFields = JSON.parse(JSON.stringify(this.fields))
      this.dialog = true
    },
    changeColumnSetting () {
      if (!this.listField.some(field => field.default)) return this.$store.commit('message/SHOW_ERROR', 'You need to select at least 1 column')
      if (this.listField.filter(field => field.default).length > 10) return this.$store.commit('message/SHOW_ERROR', 'You need to select at most 10 columns')
      this.$emit('changeColumn')
      this.dialog = false
    },
    cancelSelect () {
      this.listField = this.oldFields
      this.dialog = false
    },
    restoreFields () {
      this.listField = JSON.parse(JSON.stringify(this.oldFields))
      this.$nextTick(() => {
        this.onSelectField()
      })
    },
    onSelectField () {
      this.selectAll = !this.listField.some(field => !field.default)
    },
    changeStatusSelectAll (val) {
      this.listField.forEach(field => {
        field.default = val
      })
    }
  }
}
</script>

<style scoped>

</style>
