<template>
  <div class="d-flex fill-height justify-center flex-column pa-2" style="width: 100%">
    <div style="width: 100%; height: 120px; border-bottom: 1px solid lightgrey; flex: none">
      <v-layout
          align-center
          justify-center
          column
          class="fill-height"
          style="background-color: var(--v-bar-base);border-radius: 6px"
      >
        <v-layout
            class="pt-2"
            style="font-size: 32px; font-weight: bold; width: 100%; color: #fafafa; text-transform: uppercase"
            justify-center
            align-center
        >
          <v-icon color="white" size="40" class="mr-1">mdi-script-text-outline</v-icon>
          Survey management
        </v-layout>
        <v-container fluid class="d-flex overflow-y-hidden flex-column" style="height: 100%; max-width: 1550px">
          <v-layout justify-end align-center class="fill-height">
            <div style="border: #fafafa 2px solid; border-radius: 4px; margin-top: -4px" class="mr-1" v-if="!currentUser.is_sysadmin">
              <v-btn
                  @click="createExample"
                  id="example-survey"
                  width="250"
                  min-width="0"
                  color="info"
                  class="elevation-0">
                <v-icon class="mr-1">mdi-script-text-outline</v-icon>
                Example survey
              </v-btn>
            </div>
            <popupCreateSurvey
                ref="createForm"
                :style="{'disabled': (!(config.length && defaultConfig.length && fieldOptions.length))}"
                :loading="loading"
                :config.sync="config"
                :defaultConfig.sync="defaultConfig"
                :fieldOptions.sync="fieldOptions"
                @getSurvey="getData"/>
          </v-layout>
        </v-container>
      </v-layout>
    </div>
    <v-container fluid class="d-flex overflow-y-hidden flex-column survey-list" data-position='bottom' style="height: 100%; max-width: 1550px">
      <v-layout fill-height style="overflow-y: auto">
        <v-data-table
            height="100%"
            fixed-header
            style="height: 100%; width: 100%"
            :headers="surveyHeaders"
            :items="surveys"
            :search="search"
            :loading="loading"
            :items-per-page="10"
            class="row-pointer order-table d-flex flex-column"
            hide-default-footer
            :server-items-length.sync="totalItem"
            loading-text="Loading... Please wait"
            :options.sync="options"
            :page.sync="page"
        >
          <template v-slot:top>
            <v-layout class="flex-wrap py-2">
              <v-layout class="pb-2">
                <div style="flex: 5">
                </div>
                <div style="flex: 1">
                  <v-text-field
                      class="ml-1"
                      outlined
                      dense
                      hide-details
                      v-model="search"
                      @input="debounceInput"
                      append-icon="mdi-magnify"
                      label="Search"
                  ></v-text-field>
                </div>
              </v-layout>
            </v-layout>
          </template>
          <template v-slot:[`item.created_at`]="{ item }">
            {{ convertDate(item.created_at) }}
          </template>
          <template v-slot:[`item.active`]="{ item }">
            <v-checkbox
                @change="updateStatusSurvey(item)"
                style="width: 28px"
                class="mt-0"
                hide-details
                color="primary"
                v-model="item.active"
            ></v-checkbox>
          </template>
          <template v-slot:[`item.action`]="{ item }">
            <v-btn v-if="currentUser.is_admin" icon min-width="0" min-height="0" height="30" width="30" color="info"
                   @click="() => $refs.assignee.openDialog(item)" title="Assig">
              <v-icon size="20">mdi-account-arrow-left-outline</v-icon>
            </v-btn>
            <v-btn icon min-width="0" min-height="0" height="30" width="30" color="green"
                   @click="() => $refs.updateSurveyForm.openDialog(item)" title="Edit survey">
              <v-icon size="20">mdi-file-edit-outline</v-icon>
            </v-btn>
            <v-btn v-if="currentUser.is_admin" icon min-width="0" min-height="0" height="30" width="30" color="tab" class="ml-2"
                   @click="() => $refs.createForm.openDialog(item)" title="Copy survey">
              <v-icon size="20">mdi-content-copy</v-icon>
            </v-btn>
            <v-btn icon min-width="0" min-height="0" height="30" width="30" color="red" class="ml-2"
                   @click="() => $refs.deleteSurveyForm.openDialog(item)" title="Delete survey">
              <v-icon size="21">mdi-delete-outline</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-layout>
      <div class="text-center pa-3" style="flex: none">
        <v-pagination :total-visible="5" v-model="page" :length="pageCount" id="task_pagination"></v-pagination>
      </div>
    </v-container>
    <PopupUpdate :config.sync="config" @getSurvey="getData" :fieldOptions.sync="fieldOptions" ref="updateSurveyForm"/>
    <PopupDelete @getSurvey="getData" ref="deleteSurveyForm"/>
    <Assignee ref="assignee" @getSurvey="getData"/>
  </div>
</template>

<script>
import {
  getAllSurvey,
  updateStatusSurvey,
  getFieldConfig,
  getFieldDefaultConfig,
  getOption,
  updateProfile,
  checkToken,
  getExampleSurvey, addExampleSurvey
} from "@/backend";
import {mapState} from "@/store/ults"
import {debounce} from "lodash"
import convertDate from "@/ultis/convertDate"
import popupCreateSurvey from "./CreateSurveyForm"
import PopupUpdate from "./UpdateSurveyForm"
import PopupDelete from "./DeleteSurveyForm"
import Assignee from "@/components/home/management/survey/Assignee.vue";
import Store from "@/store";
import sleep from "@/ultis/sleep";

export default {
  components: {
    Assignee,
    PopupDelete,
    PopupUpdate,
    popupCreateSurvey
  },
  data() {
    return {
      isComplete: true,
      firstLoad: true,
      options: {},
      fieldOptions: [],
      status: true,
      defaultConfig: [],
      listStatus: [{name: 'Active', code: true}, {name: 'Inactive', code: false}],
      page: 1,
      search: '',
      totalItem: 100,
      pageCount: 0,
      loading: false,
      role: 2,
      config: [],
      surveyHeaders: [],
      surveys: []
    };
  },
  computed: {
    ...mapState("auth", ["currentUser"])
  },
  watch: {
    options: {
      handler() {
        if (!this.firstLoad) this.getData()
      },
      deep: true
    }
  },
  mounted() {
    this.surveyHeaders = this.currentUser.is_admin ? [
      {text: "Name", align: "left", sortable: true, value: "name",},
      {text: "Created at", value: "created_at", sortable: true, align: "start"},
      {text: "Published", value: "active", sortable: false, align: "start"},
      {text: "Description", value: "description", sortable: false, align: "start"},
      {text: "", value: "action", sortable: false, align: "end"}
    ] : [
      {text: "Name", align: "left", sortable: true, value: "name",},
      {text: "Created at", value: "created_at", sortable: true, align: "start"},
      {text: "Type", value: "type", sortable: false, align: "start"},
      {text: "Description", value: "description", sortable: false, align: "start"},
      {text: "", value: "action", sortable: false, align: "end"}
    ]
    this.getData()
    this.getConfig()
    this.getDefaultFields()
    if (this.currentUser.is_admin) {
      if (!this.currentUser.introduced || localStorage.getItem('intro')) this.startIntroduction()
      this.getOption()
    }
  },
  created() {
    if (!this.currentUser.is_admin && !this.currentUser.is_sysadmin) this.$router.push({path: '/app'})
  },
  methods: {
    async createExample() {
      try {
        this.loading = true
        await addExampleSurvey()
        this.$store.commit('message/SHOW_SUCCESS', 'Success')
        await this.getData()
      } catch (e) {
      } finally {
        this.loading = false
      }
    },
    async startIntroduction() {
      this.intro.exit()
      await sleep(1000)
      const steps = [{
        element: document.querySelector('.survey-list'),
        intro: "The <b>Survey Management Menu</b> will let you see all the surveys you’ve gathered",
        position: 'top-right'
      }, {
        element: document.querySelector('#btn-create-survey'),
        intro: "To create a new survey, click on <b>Create Survey Form</b>",
        position: 'right'
      }, {
        element: document.querySelector('#example-survey'),
        intro: "To create a new example survey, click on <b>Example Survey</b>",
        position: 'right'
      }, {
        element: document.querySelector('.user'),
        intro: "After creating the survey form, you’d need to create a <b>mobile user</b> to collect those surveys",
        position: 'right'
      }, {
        element: document.querySelector('.test'),
        intro: ''
      }]
      this.intro.setOptions({
        disableInteraction: true,
        showBullets: false,
        steps: steps
      }).onbeforechange(e => {
        if (this.intro._currentStep === steps.length - 1){
          this.isComplete = false
          this.$router.push({path: '/app/userManagement'})
        }
      }).oncomplete(() => {
        if (this.isComplete) this.updateUser()
      }).onexit(() => {
        if (this.isComplete) this.updateUser()
      })
      this.intro.start()
    },
    async updateUser() {
      try {
        this.loading = true
        localStorage.removeItem('intro')
        this.isHelp = false
        await updateProfile({
          introduced: true,
          name: this.currentUser.name,
          phone: this.currentUser.phone,
        })
      } catch (e) {
      } finally {
        await this.getMe()
        this.loading = false
      }
    },
    async getMe() {
      try {
        this.loading = true
        let res = await checkToken()
        Store.commit('auth/SET_USER', res.data)
      } catch (e) {
        localStorage.removeItem('jwt_token')
        window.location.href = `${location.origin}/signIn`
      } finally {
        this.loading = false
      }
    },
    async getOption() {
      try {
        this.loading = true
        const res = await getOption({per_page: 'all'})
        this.fieldOptions = res.data
      } catch (e) {
      } finally {
        this.loading = false
      }
    },
    async getDefaultFields() {
      try {
        this.loading = true
        const res = await getFieldDefaultConfig()
        this.defaultConfig = res.data
        this.defaultConfig.forEach(val => {
          val['system'] = true
        })
      } catch (e) {
      } finally {
        this.loading = false
      }
    },
    async getConfig() {
      this.loading = true
      try {
        const res = await getFieldConfig()
        this.config = res.data
      } catch (e) {
        this.$store.commit('message/SHOW_ERROR', e.message)
      } finally {
        this.loading = false
      }
    },
    getParam() {
      let sort
      if (this.options.sortBy.length && this.options.sortBy.length) {
        if (this.options.sortDesc[0]) sort = this.options.sortBy[0]
        else sort = '-' + this.options.sortBy[0]
      }
      return {
        per_page: this.options.itemsPerPage,
        page: this.options.page,
        sort: sort,
        search: this.search
      }
    },
    debounceInput: debounce(function (e) {
      this.getData()
    }, 500),
    convertDate(date) {
      return convertDate.dateFormatted(date)
    },
    async getData() {
      try {
        this.surveys = []
        this.loading = true
        const res = this.currentUser.is_admin ? await getAllSurvey(this.getParam()) : await getExampleSurvey(this.getParam())
        this.surveys = res.data.data
        this.totalItem = res.data.total
        this.pageCount = res.data.last_page
      } catch (error) {
      } finally {
        this.firstLoad = false
        this.loading = false
      }
    },
    async updateStatusSurvey(survey) {
      this.loading = true
      try {
        await updateStatusSurvey({
          id: survey.id,
          data: {
            "active": survey.active
          }
        })
        this.$store.commit('message/SHOW_SUCCESS', 'Success')
      } catch (e) {
        this.$store.commit('message/SHOW_ERROR', e.message)
      } finally {
        await this.getData()
        this.loading = false
      }
    }
  },
};
</script>
<style scoped>
/deep/
.v-chip__content {
  width: 180px !important;
}

/deep/
.v-data-table > .v-data-table__wrapper tbody tr.v-data-table__expanded__content {
  box-shadow: inset 0px 4px 6px -5px rgb(50 50 50 / 75%),
  inset 0px -4px 8px -5px rgb(50 50 50 / 0%);
}

/deep/
.order-table th {
  background-color: var(--v-primary-base) !important;
  color: white !important;
}

/deep/
.list-image th {
  background-color: #6686a3 !important;
}

/deep/
.v-data-table__wrapper {
  border: 1px lightgrey solid;
  border-radius: 8px;
}
</style>
