<template>
  <v-dialog
      v-model="deleteUserDialog"
      max-width="500"
      persistent
  >
    <v-card>
      <v-card-title class="popup-header">
        <span style="font-size: 16px">DELETE USER</span>
        <v-spacer/>
        <v-btn icon small @click="deleteUserDialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text class="px-4 py-8">
        Are you sure you want to delete the user: <b>{{ currentUser.name }}</b>
      </v-card-text>
      <v-divider/>
      <v-card-actions class="popup-footer">
        <v-spacer/>
        <v-btn width="100" color="warning" @click="deleteUser" dark>Submit</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {
  deleteUser
} from "@/backend";
export default {
  name: "PopupDeleteUser",
  data () {
    return {
      loading: false,
      deleteUserDialog: false,
      currentUser: {}
    }
  },
  mounted() {
  },
  watch: {
  },
  methods: {
    openDialog (user) {
      this.currentUser = JSON.parse(JSON.stringify(user))
      this.deleteUserDialog = true
    },
    async deleteUser () {
      this.loading = true
      try {
        await deleteUser(this.currentUser.id)
        this.$store.commit('message/SHOW_SUCCESS', 'Success')
        this.$emit('getUser')
        this.deleteUserDialog = false
      } catch (e) {
        this.$store.commit('message/SHOW_ERROR', e.message)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style scoped>

</style>
