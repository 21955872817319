<template>
  <v-dialog
      v-model="createSurveyDialog"
      fullscreen
      persistent
  >
    <template v-slot:activator="{ on, attrs }">
      <div style="border: #fafafa 2px solid; border-radius: 4px; margin-top: -4px" class="mr-1">
        <v-btn
            id="btn-create-survey"
            :disabled="$attrs['loading']"
            v-bind="attrs"
            v-on="on"
            width="250"
            min-width="0"
            color="info"
            class="elevation-0">
          <v-icon class="mr-1">mdi-script-text-outline</v-icon>
          Create survey form
        </v-btn>
      </div>
    </template>

    <v-card class="d-flex flex-column fill-height">
      <v-overlay :value="loading" absolute>
        <v-progress-circular
            indeterminate
            size="64"
        ></v-progress-circular>
      </v-overlay>
      <v-card-title class="form-header">
        <span style="font-size: 16px">CREATE SURVEY FORM</span>
        <v-spacer/>
        <v-btn :disabled="loading" icon small @click="createSurveyDialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text class="pa-0 fill-height overflow-y-auto" style="background-color: var(--v-backgroundForm-base)">
        <v-container fluid class="flex-fill fill-height" style="max-width: 1550px">
          <v-layout class="fill-height pa-2">
            <div class="d-flex flex mr-2 flex-column" style="flex: 5">
              <div class="content-survey mb-1 pa-3" style="flex: none; height: 130px">
                <v-form v-model="validInfo" ref="infoForm">
                  <h2>Survey info</h2>
                  <SurveyInfo :survey-name.sync="surveyName" :survey-description.sync="surveyDescription" :surveyType.sync="surveyType"/>
                </v-form>
              </div>
              <div class="d-flex flex fill-height content-survey mt-1 px-3 pt-3">
                <v-form v-model="valid" ref="formData" class="d-flex fill-height" style="width: 100%"
                        :disabled="visible">
                  <FieldInfo
                      :systemFields.sync="systemFields"
                      :currentField.sync="currentField"
                      :oldSection.sync="oldSection"
                      :editing.sync="editing"
                      :section.sync="section"
                      :sections.sync="sections"
                      :fieldConfig.sync="currentConfig"
                      :config.sync="config"
                      :fieldOptions.sync="fieldOptions"
                      :optionDetail.sync="optionDetail"
                      :name.sync="name"
                      :aliasName.sync="aliasName"
                      :type.sync="type"
                      :required.sync="required"
                      :isSelectOption.sync="isSelectOption"
                      :fieldDescription.sync="fieldDescription"
                      :visible.sync="visible"
                      @cancelEdit="cancelEdit"
                      @updateField="updateField"
                      @addToSection="addToSection"
                      @cancelView="cancelView"
                      @getOptionDetail="getOptionDetail"
                  />
                </v-form>
              </div>
            </div>
            <div class="d-flex flex ml-2 flex-column" style="flex: 2;">
              <div class="flex d-flex flex-column fill-height content-survey mb-1" style="">
                <div class="content-survey" style="flex: none; height: 100px; background-color: var(--v-primary-base)">
                  <v-layout class="fill-height" align-center justify-center>
                    <h1 style="text-transform: uppercase; color: #fafafa">Section data</h1>
                  </v-layout>
                </div>
                <div class="px-3" style="flex: none; height: 50px;">
                  <v-layout class="fill-height" style="border-bottom: 1px solid lightgray" align-center>
                    <v-btn color="info" small outlined @click="createNewSection">Add new section</v-btn>
                  </v-layout>
                </div>
                <Section
                    :editing.sync="editing"
                    :currentSurvey.sync="sections"
                    :oldSectionIndex.sync="oldSectionIndex"
                    :oldSectionDrag.sync="oldSectionDrag"
                    @editField="editField"
                    @addNextSection="addNextSection"
                    @removeNextSection="removeNextSection"
                    @editSection="editSection"
                    @removeSection="removeSection"
                    @checkValid="checkValid"
                    @viewField="viewField"
                />
              </div>
              <div class="content-survey mt-1" style="flex: none; height: 80px">
                <v-layout class="fill-height" align-center justify-center>
                  <v-btn :loading="loading" color="primary" width="80%" @click="createSurvey">
                    <v-icon class="mr-1">mdi-content-save</v-icon>
                    Save survey
                  </v-btn>
                </v-layout>
              </div>
            </div>
          </v-layout>
        </v-container>
      </v-card-text>
      <v-divider/>
    </v-card>
  </v-dialog>
</template>

<script>
import {
  createExampleSurvey,
  createSurvey, getOptionDetail
} from "@/backend";
import utils from '@/ultis/genUUID'
import SurveyInfo from "./surveyInfo"
import FieldInfo from "./fieldInfo"
import Section from "./section"
import {mapState} from "@/store/ults";

export default {
  name: "PopupCreateUser",
  components: {
    Section,
    FieldInfo,
    SurveyInfo,
  },
  data() {
    return {
      isAdmin: true,
      currentSurvey: {},
      systemFields: [],
      visible: false,
      disableAliasName: false,
      oldSectionDrag: undefined,
      oldSectionIndex: undefined,
      sectionDrag: undefined,
      fieldDrag: undefined,
      oldIndex: undefined,
      isSelectOption: false,
      oldSection: undefined,
      currentField: undefined,
      currentConfig: {},
      editing: false,
      loading: false,
      required: false,
      createSurveyDialog: false,
      validInfo: false,
      valid: false,
      aliasName: undefined,
      name: undefined,
      surveyName: undefined,
      surveyDescription: undefined,
      surveyType: undefined,
      fieldDescription: undefined,
      type: 'text',
      surveyForm: [],
      optionDetail: [],
      section: undefined,
      sections: [],
      listTypeWithMinMax: ['text', 'numeric', 'int'],
      listTypeWithoutMinMax: ['email', 'date', 'boolean'],
      rules: {
        requiredName: value => (!!value && !!value.trim()) || 'Name is required',
        requiredCode: value => (!!value && !!value.trim() && /[a-z_]+$/.test(value)) || 'Code is invalid',
        requiredItem: value => (!!value && !!value.trim()) || 'Item is required',
      },
      phoneRules: [
        v => !!v || 'Phone number is required',
        v => /\(?([0-9]{3})\)?([ .-]?)([0-9]{3})\2([0-9]{4})/.test(v) || 'Phone number must be valid'
      ]
    }
  },
  props: {
    config: {type: Array, default: () => []},
    defaultConfig: {type: Array, default: () => []},
    fieldOptions: {type: Array, default: () => []},
  },
  computed: {
    ...mapState("auth", ["currentUser"]),
  },
  mounted() {
    this.isAdmin = this.currentUser.is_admin
  },
  watch: {
    sections() {
      let errorItemIndex = this.sections.findIndex(section => !section.fields)
      if (errorItemIndex >= 0) {
        let index = this.sections.findIndex(section => section.name === this.sectionDrag.name)
        this.sections[index].fields.splice(this.oldIndex, 0, this.fieldDrag)
        this.sections.splice(errorItemIndex, 1)
        this.$store.commit('message/SHOW_ERROR', "You can't move field to other sections" )
      }
      let errorFieldIndex = undefined
      let errorSectionIndex = undefined
      for (let i = 0; i < this.sections.length; i++) {
        errorFieldIndex = this.sections[i].fields.findIndex(field => !field.alias_name)
        if (errorFieldIndex >= 0) errorSectionIndex = i
      }
      if (errorFieldIndex >= 0) {
        this.sections[errorSectionIndex].fields.splice(errorFieldIndex, 1)
        this.sections.splice(this.oldSectionIndex, 0, this.oldSectionDrag)
        this.$store.commit('message/SHOW_ERROR', "You can't move field to other sections" )
      }
    },
    createSurveyDialog(val) {
      this.visible = false
      if (!val) {
        this.isSelectOption = false
        this.currentSurvey = {}
        this.sections = []
        this.surveyName = undefined
        this.surveyDescription = undefined
        this.surveyType = undefined
        this.name = undefined
        this.aliasName = undefined
        this.code = undefined
        this.surveyForm = []
        this.currentConfig = {}
        this.changeType()
        this.cancelView()
        if (this.$refs.formCreate) this.$refs.formCreate.resetValidation()
      } else {
        if (Object.keys(this.currentSurvey).length === 0 && this.currentSurvey.constructor === Object) this.sections = JSON.parse(JSON.stringify(this.defaultConfig))
        this.editing = false
        this.isSelectOption = false
      }
    }
  },
  methods: {
    openDialog(currentSurvey) {
      this.currentSurvey = JSON.parse(JSON.stringify(currentSurvey))
      this.transformData()
      this.createSurveyDialog = true
    },
    transformData() {
      this.currentSurvey.layout.forEach(val => {
        val.fields.forEach(field => {
          if (field.editable && !field.vgId) {
            field.vgId = utils.getUUID()
          }
        })
      })
      this.sections = this.currentSurvey.layout
    },
    fetchSection() {
      let tmpSections = JSON.parse(JSON.stringify(this.sections))
      this.sections = []
      this.sections = tmpSections
    },
    cancelView() {
      this.visible = false
      this.resetData()
    },
    resetData() {
      this.systemFields = []
      this.required = false
      this.type = 'text'
      this.disableAliasName = false
      this.optionDetail = []
      this.name = undefined
      this.fieldDescription = undefined
      this.aliasName = undefined
      this.code = undefined
      this.currentConfig = {}
      this.changeType()
      this.$refs.formData.resetValidation()
    },
    addNextSection(section, nextSection) {
      section.next_section = nextSection.name
      this.fetchSection()
    },
    removeNextSection(section) {
      delete section.next_section
      this.fetchSection()
    },
    checkValid(e, section) {
      this.sectionDrag = JSON.parse(JSON.stringify(section))
      this.oldIndex = e.oldIndex
      this.fieldDrag = JSON.parse(JSON.stringify(section.fields[e.oldIndex]))
    },
    checkValidSection(e) {
      this.oldSectionIndex = e.oldIndex
      this.oldSectionDrag = JSON.parse(JSON.stringify(this.sections[e.oldIndex]))
    },
    viewField(currentField, currentSection) {
      this.resetData()
      if (this.editing) return
      this.editField(currentField, currentSection)
      this.getOptionDetail()
      this.editing = false
      this.visible = true
    },
    editSection(currentSection) {
      if (!currentSection.name) return (this.$store.commit('message/SHOW_ERROR', 'Section name is required'))
      if (this.sections.filter(section => section.name === currentSection.name).length > 1) {
        return (this.$store.commit('message/SHOW_ERROR', 'Section name already exists'))
      }
      currentSection.editing = !currentSection.editing
      this.fetchSection()
      this.section = undefined
    },
    changeType() {
      if (this.type === 'combobox') this.currentConfig = {values: [{name: '', section: ''}], is_ref: false}
      else this.currentConfig = {}
    },
    editField(currentField, currentSection) {
      this.resetData()
      this.systemFields = (currentField.system_fields && currentField.system_fields.length) ? currentField.system_fields : []
      this.visible = false
      if (!currentField.vgId) currentField.vgId = utils.getUUID()
      this.oldSection = JSON.parse(JSON.stringify(currentSection))
      this.currentConfig = {}
      this.section = currentSection.name
      this.currentField = JSON.parse(JSON.stringify(currentField))
      this.disableAliasName = this.currentField.is_system
      this.aliasName = this.currentField.alias_name
      this.name = this.currentField.name
      this.type = this.currentField.type
      this.required = this.currentField.rules.required
      this.fieldDescription = this.currentField.description
      this.changeType()
      if (this.listTypeWithMinMax.includes(this.type)) {
        if (this.type === 'text') {
          this.currentConfig.number_only = this.currentField.rules.number_only
        }
        this.currentConfig.min = this.currentField.rules.min
        this.currentConfig.max = this.currentField.rules.max
      } else if (this.type === 'combobox') {
        if (this.currentField.rules.values) {
          this.isSelectOption = false
          this.currentConfig.is_ref = this.currentField.rules.is_ref
          this.currentConfig.is_multi = this.currentField.rules.is_multi
          this.currentConfig.values = this.currentField.rules.values
        }
        if (this.currentField.rules.option) {
          this.isSelectOption = true
          this.currentConfig.option = this.currentField.rules.option
          this.currentConfig.ref = this.currentField.rules.ref
        }
      } else if (this.type === 'image') {
        this.currentConfig.is_multi = this.currentField.rules.is_multi
        this.currentConfig.camera = this.currentField.rules.camera
      }
      this.editing = true
      this.getOptionDetail()
    },
    cancelEdit() {
      this.editing = false
      this.resetData()
    },
    updateField() {
      this.$refs.formData.validate()
      if (!this.valid) return
      if (!this.section) return (this.$store.commit('message/SHOW_ERROR', 'Section is required'))
      let allowField = []
      this.sections.forEach(section => {
        allowField = allowField.concat(section.fields.filter(val => val.vgId !== this.currentField.vgId))
      })
      if (allowField.some(field => field.alias_name === this.aliasName)) return (this.$store.commit('message/SHOW_ERROR', 'Code already exists'))
      let currentSection = this.sections.find(section => section.name === this.section)
      if (this.currentConfig.is_ref && this.isSelectOption) {
        this.currentConfig.values = this.optionDetail
      }
      let data = {
        rules: JSON.parse(JSON.stringify(this.currentConfig)),
        description: this.fieldDescription,
        alias_name: this.aliasName,
        name: this.name,
        type: this.type,
        deletable: this.currentField.deletable,
        editable: this.currentField.editable,
        vgId: this.currentField.vgId
      }
      data.rules['required'] = this.required
      if (this.section === this.oldSection.name) {
        let index = currentSection.fields.findIndex(field => field.vgId === this.currentField.vgId)
        if (index >= 0) currentSection.fields[index] = data
      } else {
        for (let i = 0; i < this.sections.length; i++) {
          let index = this.sections[i].fields.findIndex(field => field.vgId === this.currentField.vgId)
          console.log(index)
          if (index >= 0) {
            this.sections[i].fields.splice(index, 1)
            break
          }
        }
        currentSection.fields.push(data)
      }
      this.resetData()
      this.editing = false
    },
    createNewSection() {
      let i = 1
      let name = `Section ${this.sections.length + i}`
      while (this.sections.some(section => section.name === name)) {
        i++;
        name = `Section ${this.sections.length + i}`
      }
      this.sections.push({name: name, fields: [], next_section: null, editing: false})
    },
    removeSection(index) {
      if (this.section === this.sections[index].name) this.section = undefined
      this.sections.splice(index, 1)
    },
    addToSection() {
      this.$refs.formData.validate()
      if (!this.valid) return
      if (!this.section) return (this.$store.commit('message/SHOW_ERROR', 'Section is required'))
      let currentSection = this.sections.find(section => section.name === this.section)
      let allowField = []
      this.sections.forEach(section => {
        allowField = allowField.concat(section.fields)
      })
      if (allowField.some(field => field.alias_name === this.aliasName)) return (this.$store.commit('message/SHOW_ERROR', 'Code already exists'))
      if (this.currentConfig.is_ref && this.isSelectOption) {
        this.currentConfig.values = this.optionDetail
      }
      let data = {
        rules: JSON.parse(JSON.stringify(this.currentConfig)),
        description: this.fieldDescription ? this.fieldDescription : null,
        alias_name: this.aliasName,
        name: this.name,
        type: this.type,
        deletable: true,
        editable: true,
        vgId: utils.getUUID()
      }
      let type = this.config.find(val => val.code === this.type)
      if (type && type.system_fields) data.system_fields = type.system_fields
      data.rules['required'] = this.required
      currentSection.fields.push(data)
      this.resetData()
    },
    async getOptionDetail() {
      try {
        if (!this.currentConfig.option) return
        this.loading = true
        const res = await getOptionDetail({id: this.currentConfig.option, data: {per_page: 'all', active: true}})
        this.optionDetail = res.data
      } catch (e) {
      } finally {
        this.loading = false
      }
    },
    async createSurvey() {
      this.$refs.infoForm.validate()
      if (this.sections.some(section => section.fields.length === 0)) return (this.$store.commit('message/SHOW_ERROR', 'Section must have fields'))
      if (this.validInfo) {
        this.loading = true
        this.sections.forEach(section => {
          delete section.editing
          section.fields.forEach(field => {
            if (field.rules.values) field.rules.values = field.rules.values.filter(val => !!val)
          })
        })
        try {
          let data = {
            "name": this.surveyName,
            "type": !this.isAdmin ? this.surveyType : undefined,
            "description": this.surveyDescription ? this.surveyDescription : null,
            "layout": this.sections
          }
          this.isAdmin ? await createSurvey(data) : await createExampleSurvey(data)
          this.$store.commit('message/SHOW_SUCCESS', 'Success')
          this.$emit('getSurvey')
          this.createSurveyDialog = false
        } catch (e) {
        } finally {
          this.loading = false
        }
      }
    }
  }
}
</script>

<style scoped>
.content-survey {
  border-radius: 8px;
  border: 1px solid rgba(137, 63, 242, 0.1);
  box-shadow: inset 0 0 4px var(--v-tab-base);
  background-color: #ffffff;
}

/deep/
.edit-input .v-input__control {
  min-height: 0 !important;
  height: 28px !important;
}

/deep/
.custom-pagination .v-pagination__navigation {
  width: 25px !important;
  height: 25px !important;
}

/deep/
.v-pagination__item {
  min-width: 25px !important;
  height: 25px !important;
}
</style>
