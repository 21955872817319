<template>
  <v-dialog
      v-model="createOptionDialog"
      width="800"
      persistent
  >
    <template v-slot:activator="{ on, attrs }">
      <div style="border: #fafafa 2px solid; border-radius: 4px; margin-top: -4px" class="mr-1">
        <v-btn
            :disabled="$attrs['loading']"
            v-bind="attrs"
            v-on="on"
            width="122"
            min-width="0"
            color="info"
            class="elevation-0">
          <v-icon class="mr-1">mdi-upload-outline</v-icon>
          Upload
        </v-btn>
      </div>
    </template>

    <v-card class="d-flex flex-column fill-height">
      <v-overlay :value="loading" absolute>
        <v-progress-circular
            indeterminate
            size="64"
        ></v-progress-circular>
      </v-overlay>
      <v-card-title class="form-header">
        <span style="font-size: 16px">UPLOAD OPTION</span>
        <v-spacer/>
        <v-btn :disabled="loading" icon small @click="createOptionDialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text class="pa-5 overflow-y-auto" style="height: 400px">
        <v-form v-model="valid" ref="formData" class="fill-height">
          <v-layout class="fill-height" column>
            <div style="flex: none; height: 80px; border-bottom: 1px solid lightgray">
              <v-layout align-center class="fill-height">
                <div style="flex: 1" class="flex pr-1">
                  <span style="font-size: 14px; font-weight: bold" class="mt-1">Name</span>
                  <div style="width: 100%;">
                    <v-text-field class="mt-1" outlined dense v-model="optionName" label="Name"
                                  :rules="[rules.requiredName]"></v-text-field>
                  </div>
                </div>
                <div style="flex: 1" class="flex pl-1">
                  <span style="font-size: 14px; font-weight: bold" class="mt-1">Reference</span>
                  <div style="width: 100%;">
                    <v-autocomplete
                        clearable
                        class="mt-1 select-box"
                        outlined
                        dense
                        v-model="referenceId"
                        label="Option"
                        :items="allOption"
                        item-text="name"
                        item-value="id">
                    </v-autocomplete>
                  </div>
                </div>
              </v-layout>
            </div>
            <v-layout class="fill-height py-5" align-center justify-center>
              <file-upload
                  extensions="csv, xlsx"
                  style="border-radius: 8px; width: 100%; height: 100%; border: 1px dashed green;"
                  :style="{'border-color': color}"
                  ref="upload"
                  v-model="file"
                  :drop="true"
                  class="pt-2"
              >
                <template>
                  <v-layout column justify-center class="py-6 fill-height" style="align-items: center; " v-if="!file">
                    <v-icon size="80">mdi-cloud-upload-outline</v-icon>
                    <h3>Upload option file</h3>
                    <span style="font-size: 13px; color: var(--v-primary-base)">Support file: *.csv | *.xlsx</span>
                    <span style="font-size: 13px">Click to select file or drop it here</span>
                  </v-layout>
                  <v-layout column justify-center class="py-2 fill-height" style="align-items: center;" v-else>
                    <div style="width: 150px; border: 1px solid lightgray; border-radius: 8px; position: relative">
                      <v-layout align-center justify-center class="fill-height">
                        <v-icon size="130" :color="color">mdi-microsoft-excel</v-icon>
                      </v-layout>
                    </div>
                    <div :style="{'color': color}" style="height: 50px; width: 100%; font-size: 13px;" class="mt-4">
                      {{ file[0].name }}
                    </div>
                  </v-layout>
                </template>
              </file-upload>
              <v-hover v-slot="{ hover }">
                <v-btn
                    v-if="file"
                    width="55"
                    height="100%"
                    @click="removeCurrentFile"
                    class="elevation-0 ml-2"
                    :color="hover ? 'red' : '#ff000024'"
                    dark
                    style="border: 1px solid red !important; border-radius: 8px"
                >
                  <v-icon :color="hover ? 'white' : 'red'">mdi-delete</v-icon>
                </v-btn>
              </v-hover>
            </v-layout>
          </v-layout>
        </v-form>
      </v-card-text>
      <v-divider/>
      <v-card-actions>
        <v-spacer/>
        <v-btn color="primary" class="ml-1" width="165" @click="saveOption">
          <v-icon class="mr-1">mdi-upload-outline</v-icon>
          Upload
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {
  uploadOption
} from "@/backend"
import VueUploadComponent from 'vue-upload-component'

export default {
  name: "PopupUploadOption",
  components: {
    'file-upload': VueUploadComponent
  },
  data() {
    return {
      color: 'green',
      loading: false,
      valid: false,
      validData: true,
      file: undefined,
      referenceId: undefined,
      optionName: undefined,
      extensions: ['csv', 'xlsx'],
      rules: {
        requiredName: value => (!!value && !!value.trim()) || 'Name is required',
        requiredItems: value => value.length || 'Item is required'
      },
      createOptionDialog: false
    }
  },
  props: {
    allOption: {type: Array, default: () => []},
  },
  computed: {},
  mounted() {
  },
  watch: {
    file(val) {
      if (val) {
        let extension = val[0].name.split('.').pop()
        if (!this.extensions.some(val => val.toUpperCase() === extension.toUpperCase())) {
          this.color = 'red'
          this.$store.commit('message/SHOW_ERROR', 'File is not support')
          this.validData = false
        } else {
          this.validData = true
          this.color = 'green'
        }
      } else {
        this.validData = false
      }
    },
    createOptionDialog(val) {
      if (!val) {
        this.optionName = undefined
        this.referenceId = undefined
        this.file = undefined
        this.color = 'green'
        this.validData = false
        this.$refs.formData.resetValidation()
      }
    }
  },
  methods: {
    removeCurrentFile() {
      this.file = undefined
      this.validData = false
      this.color = 'green'
    },
    async saveOption() {
      this.$refs.formData.validate()
      if (!this.valid || !this.validData) return
      try {
        this.loading = true
        const formData = new FormData()
        formData.append('file', this.file[0].file)
        formData.append('name', this.optionName)
        if (this.referenceId) formData.append('ref_id', this.referenceId)
        formData.append('is_multi', 0)
        await uploadOption(formData)
        this.$store.commit('message/SHOW_SUCCESS', 'Success')
        this.createOptionDialog = false
        this.$emit('getOption')
      } catch (e) {
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style scoped>
.create-content {
  height: 100%;
  border-radius: 8px;
  border: 1px solid rgba(137, 63, 242, 0.1);
  box-shadow: inset 0 0 4px var(--v-tab-base);
  background-color: #ffffff;
}

/deep/
.edit-input .v-input__control {
  min-height: 0 !important;
  height: 28px !important;
}

/deep/
.file-uploads label {
  cursor: pointer !important;
}
</style>
