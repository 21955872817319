<template>
  <v-layout style="width: 100%; height: 100%">
    <SurveyList/>
  </v-layout>
</template>

<script>
import SurveyList from '@/components/home/management/survey/SurveyList'

export default {
    components: { SurveyList }
}
</script>
