<template>
  <v-dialog
      v-model="updateUserDialog"
      max-width="500"
      persistent
  >
    <v-card>
      <v-card-title class="popup-header">
        <span style="font-size: 16px">UPDATE TOKEN</span>
        <v-spacer/>
        <v-btn icon small @click="updateUserDialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text class="pa-4">
        <v-form @submit="updateUser" onSubmit="return false;" v-model="valid" ref="formUpdate">
          <v-text-field outlined dense v-model="currentUser.name" readonly label="Company"></v-text-field>
          <v-text-field outlined dense v-model.number.trim="formData.amount" type="number" :min="1" label="Amount Token" :rules="[rules.required]"></v-text-field>
          <v-text-field outlined @input="!formData.cost ? formData.cost = 0 : ''" dense v-model.number.trim="formData.cost" label="Cost" type="number" :min="0" :rules="[rules.requiredCost]"></v-text-field>
          <v-text-field outlined dense v-model="formData.description" label="Description"></v-text-field>
        </v-form>
      </v-card-text>
      <v-divider/>
      <v-card-actions class="popup-footer">
        <v-spacer/>
        <v-btn width="100" color="primary" @click="updateUser" dark>Submit</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {
  updateToken
} from "@/backend"
export default {
  name: "PopupUpdateToken",
  data () {
    return {
      id: undefined,
      loading: false,
      updateUserDialog: false,
      valid: false,
      formData: {
        amount: 1,
        cost:0,
        description: undefined
      },
      currentUser: {},
      rules: {
        requiredCost: value => (value >= 0) || 'Cost is required',
        required: value => (!!value && value >= 1) || 'Token is required'
      }
    }
  },
  props: {
  },
  mounted() {
  },
  watch: {
    updateUserDialog (val) {
      if (!val) {
        this.formData = {}
        this.currentUser = {}
        if (this.$refs.formUpdate) this.$refs.formUpdate.resetValidation()
      }
    }
  },
  methods: {
    openDialog (user) {
      this.currentUser = JSON.parse(JSON.stringify(user))
      this.updateUserDialog = true
    },
    async updateUser () {
      this.$refs.formUpdate.validate()
      if (this.valid) {
        this.loading = true
        try {
          this.formData.cost = this.formData.cost ? this.formData.cost : 0
          this.formData.company_id = this.currentUser.id
          await updateToken({
            data: this.formData
          })
          this.$store.commit('message/SHOW_SUCCESS', 'Success')
          this.$emit('getUser')
          this.updateUserDialog = false
        } catch (e) {
        } finally {
          this.loading = false
        }
      }
    }
  }
}
</script>

<style scoped>

</style>
