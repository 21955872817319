<template>
<v-dialog
  v-model="deleteDialog"
  persistent
  width="500">
  <v-card :loading="loading">
    <v-card-title class="popup-header">
      <span style="font-size: 16px">DELETE IMAGE</span>
      <v-spacer/>
      <v-btn :disabled="loading" icon small @click="deleteDialog = false">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text class="px-4 py-8">
      Are you sure you want to delete the image
    </v-card-text>
    <v-divider/>
    <v-card-actions class="popup-footer">
      <v-spacer/>
      <v-btn width="100" :loading="loading" color="warning" @click="deleteImage()" dark>Submit</v-btn>
    </v-card-actions>
  </v-card>
</v-dialog>
</template>

<script>
import {removeImage} from "@/backend"

export default {
  name: "DeleteImage",
  data () {
    return {
      loading: false,
      deleteDialog: false,
      currentRecord: undefined,
      currentImage: undefined
    }
  },
  methods: {
    openDialog (image, record) {
      this.currentRecord = record
      this.currentImage = image
      this.deleteDialog = true
    },
    async deleteImage () {
      try {
        let form = {
          surveyId: this.currentRecord.survey_id,
          id: this.currentRecord.id,
          data: {
            deleted_image: this.currentImage.link,
            alias_name: this.currentImage.alias_name
          }
        }
        this.loading = true
        await removeImage(form)
        this.$emit('updateListImage', this.currentImage)
        this.$store.commit('message/SHOW_SUCCESS', 'Success')
        this.deleteDialog = false
      } catch (e) {
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style scoped>

</style>
