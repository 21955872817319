<template>
  <v-card width="100%" height="100%" class="d-flex" style="background-color: var(--v-backgroundForm-base)">
    <v-layout class="fill-height" column align-center justify-center>
<!--      <span style="font-size: 60px; text-decoration: underline">401</span>-->
      <span  style="font-size: 55px; color: #777c85">Unauthorized</span>
    </v-layout>
  </v-card>
</template>

<script>
export default {
  name: "Unauthorized"
}
</script>

<style scoped>

</style>
