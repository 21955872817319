<template>
  <v-layout class="fill-height" column>
    <v-overlay
        :value="dialog"
    >
      <v-layout style="height: 100vh; width: 100vw" align-center justify-center>
        <v-btn fab color="white" light small style="position: absolute; top: 10px; right: 10px; z-index: 2"
               @click="dialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-carousel
            v-model="currentSlider"
            height="90vh"
            hide-delimiters
            hide-delimiter-background
            show-arrows-on-hover
        >
          <template v-slot:prev="{ on, attrs }">
            <v-btn
                fab
                small
                color="white"
                v-bind="attrs"
                v-on="on"
            >
              <v-icon color="#333333">mdi-chevron-left</v-icon>
            </v-btn>
          </template>
          <template v-slot:next="{ on, attrs }">
            <v-btn
                fab
                small
                color="white"
                v-bind="attrs"
                v-on="on"
            >
              <v-icon color="#333333">mdi-chevron-right</v-icon>
            </v-btn>
          </template>
          <v-carousel-item
              class="custom-carousel"
              style="height: 90vh"
              v-for="(image, i) in images"
              :key="i"
          >
            <div
                style="width: auto; height: auto; text-align: -webkit-center;"
            >
              <v-img
                  contain
                  max-height="90vh"
                  max-width="90vw"
                  :src="image.src"
              ></v-img>
            </div>
          </v-carousel-item>
        </v-carousel>
      </v-layout>
    </v-overlay>
    <div class="flex d-flex justify-center align-center mb-1 image-block">
      <div
          @click="openImage"
          style="border-radius: 8px; width: 100%; height: 100%; background-size: auto 100%; background-position: center; position: relative"
          :style="{'background-image': selectedImage.src ? `url(${selectedImage.src}` : '', 'cursor': selectedImage.src ? 'pointer' : ''}"
      >
        <v-btn class="delete-btn" @click.native.stop="() => $emit('deleteImage')" v-if="selectedImage.src"
               title="Delete image" fab small>
          <v-icon size="30" color="red">mdi-delete-circle-outline</v-icon>
        </v-btn>
        <v-layout v-if="!selectedImage.src" class="fill-height" align-center justify-center>
          <v-icon size="90">mdi-camera-marker-outline</v-icon>
        </v-layout>
        <div class="px-2 pt-2 display-image">
          {{ selectedImage.name ? selectedImage.name : 'Image' }}
        </div>
      </div>
    </div>
    <div class="flex d-flex flex-wrap mt-1 align-center overflow-y-auto slider-image">
      <v-slide-group
          multiple
          show-arrows
      >
        <v-slide-item
            v-slot="{ active, toggle }"
        >
          <div class="ma-1 card-image">
            <v-layout style="width: 100%; height: 100%" align-center justify-center>
              <UploadImageForm @refreshData="() => $emit('getDetail')" :currentRecord="currentRecord"/>
            </v-layout>
          </div>
        </v-slide-item>
        <v-slide-item
            v-for="(image, index) in images"
            :key="index"
            v-slot="{ active, toggle }"
        >
          <div
              class="ma-1 image-display"
              @click="selectedImage = image"
              :style="{'border': selectedImage.id === image.id ? '2px solid #4aa94e' : '1px solid lightgray'}"
              :key="index">
            <img alt="image" :src="image.src" width="100%" height="100%"/>
          </div>
        </v-slide-item>
      </v-slide-group>
    </div>
  </v-layout>
</template>

<script>
import UploadImageForm from "@/components/home/dashboard/records/UploadImageForm"

export default {
  name: "CarouselsImage",
  components: {UploadImageForm},
  data() {
    return {
      dialog: false,
      currentSlider: 0
    }
  },
  props: {
    images: {type: Array, default: () => []},
    currentImage: {type: Object, default: {}},
    currentRecord: {type: Object, default: {}},
  },
  computed: {
    selectedImage: {
      get() {
        return this.currentImage
      },
      set(val) {
        this.$emit('update:currentImage', val)
      }
    }
  },
  methods: {
    openImage() {
      if (this.selectedImage.src) {
        this.currentSlider = this.images.findIndex(image => image === this.selectedImage)
        this.dialog = true
      } else {
        this.currentSlider = 0
        this.dialog = false
      }
    },
  }
}
</script>

<style scoped>
.delete-btn {
  position: absolute;
  bottom: 10px;
  right: 5px;
  z-index: 2
}

.slider-image {
  flex: 1;
  border-radius: 8px;
  border: 1px solid lightgray
}

.card-image {
  width: 52px;
  height: 52px;
  border: 1px solid lightgray;
  border-radius: 4px;
  cursor: pointer;
  padding: 3px
}

.image-display {
  width: 52px;
  height: 52px;
  border: 1px solid lightgray;
  border-radius: 4px;
  cursor: pointer;
  padding: 3px;
  position: relative
}
</style>
