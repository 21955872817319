import config from '@/config.json'
// import randomColor from "@/ultis/randomColor"
// import bbox from "@turf/bbox"

export default class RasterLayer {
  constructor(obj) {
    this._isRaster = true
    this._id = obj.id
    this._name = obj.name ? obj.name : obj.id
    this._opacity = obj.opacity ? obj.opacity : 1
    this._paint = obj.style ? obj.style : this.getDefaultPaint()
    this._tileUrl = obj.tileUrl
    this._visibility = obj.visibility ? obj.visibility : 'visible'
    this._bbox = obj.bbox ? obj.bbox : [103.596, 1.1443, 104.4309, 1.4835]
  }

  getDefaultPaint () {
    return {
      'raster-opacity': 1,
      'raster-contrast': 0,
      'raster-brightness-min': 0,
      'raster-brightness-max': 1
    }
  }

  getMapboxLayer() {
    return {
      'id': this._id,
      'name': this._name,
      'type': 'raster',
      'source': {
        'type': 'raster',
        'tiles': [config.tileHost + this._tileUrl],
        'tileSize': 256,
        'maxzoom': 18,
        'minzoom': 1,
        'bounds': this._bbox
      },
      'layout': {
        'visibility': this._visibility
      },
      'paint': this._paint
    }
  }

  get isRaster() {
    return this._isRaster
  }

  get id() {
    return this._id
  }

  get name() {
    return this._name
  }

  get opacity() {
    return this._opacity
  }

  set opacity (newValue) {
    this._opacity = newValue
  }

  get paint() {
    return this._paint
  }

  set paint (newValue) {
     this._paint = newValue
  }

  get tileUrl() {
    return this._tileUrl
  }

  get visibility() {
    return this._visibility
  }

  set visibility (newValue) {
    this._visibility = newValue
  }

  get bbox() {
    return this._bbox
  }

  set bbox (newValue) {
    this._bbox = newValue
  }
}
