<template>
  <v-dialog
      v-model="updateUserDialog"
      max-width="500"
      persistent
  >
    <v-card>
      <v-card-title class="popup-header">
        <span style="font-size: 16px">UPDATE USER</span>
        <v-spacer/>
        <v-btn icon small @click="updateUserDialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text class="pa-4">
        <v-form @submit="updateUser" onSubmit="return false;" v-model="valid" ref="formUpdate">
          <v-text-field outlined dense v-model="currentUser.name" label="User name" :rules="[rules.requiredUserName]"></v-text-field>
          <v-text-field outlined dense v-model="currentUser.email" label="Email" :rules="[rules.requiredEmail]"></v-text-field>
          <v-text-field outlined dense v-model="currentUser.phone" label="Phone number" type="number" :rules="phoneRules"></v-text-field>
          <v-select multiple small-chips outlined dense v-model="currentUser.roles" :items="roles" label="Role" :rules="[rules.role]" item-text="name" item-value="id"></v-select>
          <v-checkbox
              class="mt-0"
              hide-details
              color="primary"
              v-model="currentUser.active"
              label="Active status"
          ></v-checkbox>
        </v-form>
      </v-card-text>
      <v-divider/>
      <v-card-actions class="popup-footer">
        <v-spacer/>
        <v-btn width="100" color="primary" @click="updateUser" dark>Submit</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {
  updateUser
} from "@/backend";
export default {
  name: "PopupUpdateUser",
  data () {
    return {
      id: undefined,
      loading: false,
      updateUserDialog: false,
      valid: false,
      currentUser: {},
      rules: {
        requiredUserName: value => (!!value && !!value.trim()) || 'User name is required',
        requiredEmail: value => (!!value && !!value.trim()) || 'Email is required',
        role: value => !!value || 'Role is required'
      },
      phoneRules: [
        v => !!v || 'Phone number is required',
        v => /\(?([0-9]{3})\)?([ .-]?)([0-9]{3})\2([0-9]{4})/.test(v) || 'Phone number must be valid'
      ]
    }
  },
  props: {
    roles: {type: Array, default: () => []},
  },
  mounted() {
  },
  watch: {
    updateUserDialog (val) {
      if (!val) {
        this.currentUser = {}
        if (this.$refs.formUpdate) this.$refs.formUpdate.resetValidation()
      }
    }
  },
  methods: {
    openDialog (user) {
      this.currentUser = JSON.parse(JSON.stringify(user))
      this.currentUser.roles = this.currentUser.roles.map(role => role.id)
      this.updateUserDialog = true
    },
    async updateUser () {
      this.$refs.formUpdate.validate()
      if (this.valid) {
        this.loading = true
        try {
          await updateUser({
            id: this.currentUser.id,
            data: {
              "name": this.currentUser.name,
              "phone": this.currentUser.phone,
              "email": this.currentUser.email,
              "role_ids": this.currentUser.roles,
              "active": this.currentUser.active
            }
          })
          this.$store.commit('message/SHOW_SUCCESS', 'Success')
          this.$emit('getUser')
          this.updateUserDialog = false
        } catch (e) {
        } finally {
          this.loading = false
        }
      }
    }
  }
}
</script>

<style scoped>

</style>
