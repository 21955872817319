<template>
  <v-dialog
      v-model="deleteOptionDialog"
      max-width="500"
      persistent
  >
    <v-card>
      <v-overlay :value="loading" absolute>
        <v-progress-circular
            indeterminate
            size="64"
        ></v-progress-circular>
      </v-overlay>
      <v-card-title class="popup-header" style="background-color: red">
        <span style="font-size: 16px">DELETE OPTION: {{ currentOption.name }}</span>
        <v-spacer/>
        <v-btn :disabled="loading" icon small @click="deleteOptionDialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text class="px-4 py-8">
        <v-layout column v-if="ref.surveys && ref.surveys.length">
          <span>You cannot delete this option because the following surveys are using it or its children:</span>
          <span style="font-weight: bold" v-for="(item, index) in ref.surveys" :key="index">&#9679; {{ item.name }}</span>
        </v-layout>
        <v-layout column v-else-if="ref.optionChildren && ref.optionChildren.length">
          <span style="color: red; font-size: 16px">This action will delete all children option of it:</span>
          <span style="font-weight: bold" v-for="(item, index) in ref.optionChildren" :key="index">&#9679; {{ item.name }}</span>
          <span>Are you sure you want to delete the option: <b> {{ currentOption.name }}</b></span>
        </v-layout>
        <v-layout v-else>
          <span>Are you sure you want to delete the option: <b> {{ currentOption.name }}</b></span>
        </v-layout>
      </v-card-text>
      <v-card-actions class="popup-footer">
        <v-spacer/>
        <v-btn v-if="!ref.surveys || !ref.surveys.length" width="100" :loading="loading" class="elevation-0" color="#ff07079c" @click="deleteOption()" dark>Delete</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {
  getRef,
  deleteOption
} from "@/backend";
export default {
  name: "PopupDeleteOption",
  data () {
    return {
      loading: false,
      ref: {},
      deleteOptionDialog: false,
      currentOption: {}
    }
  },
  mounted() {
  },
  watch: {
  },
  methods: {
    async openDialog (surveyForm) {
      this.ref = {}
      this.currentOption = JSON.parse(JSON.stringify(surveyForm))
      this.deleteOptionDialog = true
      await this.getRef()
    },
    async getRef() {
      try {
        this.loading = true
        const res = await getRef(this.currentOption.id)
        this.ref = res.data
      } catch (e) {

      } finally {
        this.loading = false
      }
    },
    async deleteOption () {
      this.loading = true
      try {
        await deleteOption(this.currentOption.id)
        this.$store.commit('message/SHOW_SUCCESS', 'Success')
        this.$emit('getOption')
        this.deleteOptionDialog = false
      } catch (e) {
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style scoped>

</style>
