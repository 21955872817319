const getRandomColor = {
  getColor(index) {
    var colors = [
      '#45c04d', '#ff4c4c', '#d9aeae', '#fd9c7f',
      '#7c1e00', '#c20fde', '#02face', '#839dab',
      '#004769', '#FF6D00', '#fdaa43', '#eddf42',
      '#c8a700', '#cada61', '#777101', '#806a6a',
      '#388E3C', '#33691E', '#558B2F', '#827717',
      '#9E9D24', '#021723', '#59c2fd', '#038ea2',
      '#b3f8ff', '#00BFA5', '#1DE9B6', '#004D40',
      '#00695C', '#00796B', '#006064', '#00838F',
      '#01579B', '#0277BD', '#6200EA', '#651FFF',
      '#304FFE', '#9baaf8', '#8fad00', '#000967',
      '#283593', '#311B92', '#4527A0', '#0D47A1',
      '#2962FF', '#1565C0', '#1976D2', '#B71C1C',
      '#6c093f', '#c75eff', '#4A148C', '#6A1B9A',
      '#D32F2F', '#C2185B', '#7B1FA2', '#303F9F',
      '#512DA8', '#1976D2', '#fdd835', '#ffa000',
      '#78909c', '#546e7a', '#795548', '#3e2723'];
    return colors[index]
  }
}
export default getRandomColor
