<template>
  <v-card flat style="height: 100%; background: transparent; border-radius: 0" class="d-flex flex-column pt-0">
    <v-overlay :value="loading" absolute>
      <v-progress-circular
          indeterminate
          size="64"
      ></v-progress-circular>
    </v-overlay>
    <v-card-text class="fill-height d-flex flex-column pa-2" style="border-radius: 5px;">
      <div class="d-flex card-content flex-column">
        <div class="px-1 py-2 d-flex"
             style="height: 60px; background-color: var(--v-primary-base); border-radius: 5px; flex: none">
          <filter-form
              @downloadData="downloadData"
              :dateRanger.sync="filterForm.dateRanger"
              :state.sync="filterForm.state_id"
              :district.sync="filterForm.district_id"
              :subDistrict.sync="filterForm.sub_district_id"
              :type.sync="filterForm.survey_id"
          />
        </div>
        <div class="d-flex" style="flex: 1; min-height: 0;">
          <div class="d-flex pt-2" style="flex: 5; height: 100%;">
            <v-layout column class="fill-height">
              <div
                  class="flex d-flex"
                  style="border-radius: 8px"
                  :style="{'flex': fullscreen ? 'none' : '5', 'height': fullscreen ? '0px' : '100%'}">
                <Map ref="map" @goToDetail="(record) => $refs.recordDetail.openDialog(record)"/>
              </div>
              <div :class="fullscreen ? 'mt-0' : 'mt-2'"
                   style="height: 48px; background-color: var(--v-primary-base); flex: none; border-top-right-radius: 8px; border-top-left-radius: 8px; min-height: 0">
                <v-layout class="fill-height">
                  <div class="flex px-2 py-1 align-center" style="flex: 2; height: 100%;">
                    <v-text-field label="Search" dense hide-details flat solo v-model="recordFilter.search"
                                  append-icon="mdi-magnify">
                    </v-text-field>
                  </div>
                  <div class="flex d-flex px-1 py-1 justify-center align-center" style="flex: 6; height: 100%;">
                    <v-select
                        class="report-status-small"
                        flat
                        solo
                        dense
                        hide-details
                        v-model="recordFilter.status"
                        :items="listStatus"
                        label="Type"
                        item-value="value"
                        item-text="name"
                    >
                    </v-select>
                    <v-btn-toggle
                        class="report-status"
                        mandatory
                        dense
                        v-model="recordFilter.status"
                        tile
                        color="white"
                        group
                    >
                      <v-btn v-for="(status, index) in listStatus" width="135" height="40" :key="index"
                             style="text-transform: none; font-size: 13px" :value="status.value">
                        {{ status.name }}
                      </v-btn>
                    </v-btn-toggle>
                  </div>
                  <div class="flex d-flex px-1 py-1 align-center" style="flex: 4; height: 100%;">
                    <v-spacer/>
                    <v-tooltip bottom color="#00000099">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn v-bind="attrs" v-on="on" icon @click="() => $refs.columnSetting.openDialog()">
                          <v-icon color="white">mdi-dots-vertical</v-icon>
                        </v-btn>
                      </template>
                      <span>Display setting</span>
                    </v-tooltip>
                    <v-tooltip bottom color="#00000099" v-if="showReport">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn v-bind="attrs" v-on="on" icon @click="fullscreenReport">
                          <v-icon v-if="fullscreen" color="white">mdi-fullscreen-exit</v-icon>
                          <v-icon v-else color="white">mdi-fullscreen</v-icon>
                        </v-btn>
                      </template>
                      <span>{{ fullscreen ? 'Restore' : 'Maximize' }}</span>
                    </v-tooltip>
                    <v-tooltip bottom color="#00000099" v-if="!fullscreen">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn v-bind="attrs" v-on="on" icon @click="changeDisplayReport">
                          <v-icon v-if="showReport" color="white">mdi-chevron-down</v-icon>
                          <v-icon v-else color="white">mdi-chevron-up</v-icon>
                        </v-btn>
                      </template>
                      <span>{{ showReport ? 'Minimize' : 'Restore' }}</span>
                    </v-tooltip>
                  </div>
                </v-layout>
              </div>
              <div class="flex" style="flex: 3;"
                   :style="{'flex': showReport ? 3 : 'none', 'height': !showReport ? '0px' : ''}">
                <v-layout class="fill-height" column>
                  <v-layout class="fill-height overflow-y-hidden" id="table-report">
                    <v-data-table
                        style="width: 100%"
                        :height="tableHeight"
                        fixed-header
                        :headers="reportHeaders"
                        :items="reports"
                        :items-per-page="10"
                        class="row-pointer order-table"
                        hide-default-footer
                        :server-items-length.sync="totalItem"
                        loading-text="Loading... Please wait"
                        :options.sync="options"
                    >
                      <template v-slot:[`item.created_at`]="{ item }">
                        {{ convertDate(item.created_at) }}
                      </template>
                      <template v-slot:[`item.updated_at`]="{ item }">
                        {{ convertDate(item.updated_at) }}
                      </template>
                      <template v-slot:[`item.detail`]="{ item }">
                        <v-tooltip bottom color="#00000099">
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn v-bind="attrs" v-on="on" icon x-small color="green"
                                   @click="() => $refs.recordDetail.openDialog(item)">
                              <v-icon>mdi-file-document-outline</v-icon>
                            </v-btn>
                          </template>
                          <span>Open detail</span>
                        </v-tooltip>
                      </template>
                    </v-data-table>
                  </v-layout>
                  <div class="text-center"
                       v-if="showReport"
                       style="flex: none; height: 40px; background-color: var(--v-primary-base); border-bottom-right-radius: 8px; border-bottom-left-radius: 8px">
                    <v-layout align-center justify-center class="fill-height">
                      <v-pagination @input="fetchRecord" :total-visible="5" v-model="page"
                                    :length="pageCount"></v-pagination>
                    </v-layout>
                  </div>
                </v-layout>
              </div>
            </v-layout>
          </div>
          <div class="d-flex flex-column pt-2 pl-2" style="flex: 2; height: 100%;">
            <div class="d-flex grid-data">
              <div class="d-flex fill-height flex-column" style="flex: 1; min-height: 0; font-size: 0.8vw">
                <v-layout class="pb-1" style="flex: 1; min-height: 0">
                  <div class="flex align-center justify-center flex-column mr-1"
                       style="flex: 1; min-height: 0; background-color: var(--v-primary-base); border-top-left-radius: 8px">
                    <v-layout class="fill-height" column align-center justify-center>
                      <h2 style="color: #fafafa">{{ dashboardData.total ? dashboardData.total : 0 }}</h2>
                      <span style="font-size: 0.7vw; color: #eeeef3">Total Farms</span>
                    </v-layout>
                  </div>
                  <div class="flex align-center justify-center flex-column mx-1"
                       style="flex: 1; min-height: 0; background-color: var(--v-primary-base)">
                    <v-layout class="fill-height" column align-center justify-center>
                      <h2 style="color: #fafafa">
                        {{
                          dashboardData.totalArea && Number(dashboardData.totalArea) !== 0 ? dashboardData.totalArea : 0
                        }}
                      </h2>
                      <span style="font-size: 0.7vw; color: #eeeef3">Total Area (in ha)</span>
                    </v-layout>
                  </div>
                  <div class="flex align-center justify-center flex-column ml-1"
                       style="flex: 1; min-height: 0; background-color: var(--v-primary-base); border-top-right-radius: 8px">
                    <v-layout class="fill-height" column align-center justify-center>
                      <h2 style="color: #fafafa">
                        {{
                          dashboardData.totalExecutives && Number(dashboardData.totalExecutives) !== 0 ? dashboardData.totalExecutives : 0
                        }}
                      </h2>
                      <span style="font-size: 0.7vw; color: #eeeef3">Total Executives</span>
                    </v-layout>
                  </div>
                </v-layout>
                <v-layout class="pt-1 pb-2" style="flex: 1; min-height: 0; font-size: 0.8vw">
                  <div class="flex align-center justify-center flex-column mr-1"
                       style="flex: 1; min-height: 0; background-color: var(--v-primary-base); border-bottom-left-radius: 8px">
                    <v-layout class="fill-height" column align-center justify-center>
                      <h2 style="color: #fafafa">
                        {{ dashboardData.pendingRecords ? dashboardData.pendingRecords : 0 }}
                      </h2>
                      <span style="font-size: 0.7vw; color: #eeeef3">Pending Vertifications</span>
                    </v-layout>
                  </div>
                  <div class="flex align-center justify-center flex-column mx-1"
                       style="flex: 1; min-height: 0; background-color: var(--v-primary-base)">
                    <v-layout class="fill-height" column align-center justify-center>
                      <h2 style="color: #fafafa">
                        {{
                          dashboardData.verifiedRecords ? dashboardData.verifiedRecords : 0
                        }}</h2>
                      <span style="font-size: 0.7vw; color: #eeeef3">Verified Farms</span>
                    </v-layout>
                  </div>
                  <div class="flex align-center justify-center flex-column ml-1"
                       style="flex: 1; min-height: 0; background-color: var(--v-primary-base); border-bottom-right-radius: 8px">
                    <v-layout class="fill-height" column align-center justify-center>
                      <h2 style="color: #fafafa">{{
                          dashboardData.rejectedRecords && Number(dashboardData.rejectedRecords) !== 0 ? dashboardData.rejectedRecords : 0
                        }}</h2>
                      <span style="font-size: 0.7vw; color: #eeeef3">Rejected Farms</span>
                    </v-layout>
                  </div>
                </v-layout>
              </div>
            </div>
            <v-layout
                style="flex: 1; min-height: 0; border-radius: 8px; border: 1px solid rgba(137, 63, 242, 0.1);"
                column>
              <div class="d-flex header-monitor">
                <v-layout align-center class="fill-height px-5">
                  <h3 style="color: white">Survey Monitor</h3>
                </v-layout>
              </div>
              <v-layout column class="pa-3 overflow-y-auto" style="min-height: 0; background-color: #fafafa">
                <div style="flex: 1; min-height: 0; font-size: 8px" class="d-flex">
                  <canvas style="height: 90%; width: 90%" id="chart"></canvas>
                </div>
                <div style="height: 60px; flex: none" class="pt-1 d-flex">
                  <canvas style="height: 100%; width: 100%" id="chartV2"></canvas>
                </div>
              </v-layout>
              <div class="custom-legend px-3 pb-1 d-flex">
                <div id="legend-container" class="pt-1 d-flex elevation-1">
                  <v-list dense height="100%" width="100%" class="py-0">
                    <v-list-item
                        v-for="(label, index) of chartLegend"
                        :key="'legend-'+index"
                        style="min-height: 0;height: 30px"
                        class="px-1"
                        @click="changeDisplayChart(label)">
                      <v-list-item-action>
                        <v-icon size="22" v-if="label.hidden" :color="label.fillStyle">
                          mdi-checkbox-blank-outline
                        </v-icon>
                        <v-icon size="22" v-else :color="label.fillStyle">mdi-checkbox-marked</v-icon>
                      </v-list-item-action>
                      <span
                          class="text-truncate"
                          :title="label.text"
                          style="font-size: 12px"
                          :style="{'color': label.fillStyle, 'text-decoration': label.hidden ? 'line-through' : ''}">
                          {{ label.text }}
                        </span>
                      <v-spacer/>
                    </v-list-item>
                  </v-list>
                </div>
              </div>
            </v-layout>
          </div>
        </div>
      </div>
      <RecordDetail ref="recordDetail" @getData="fetchRecord"/>
      <ColumnSetting ref="columnSetting" :fields.sync="listChartFields" @changeColumn="setHeader"/>
    </v-card-text>
  </v-card>
</template>

<script>
import Chart from "chart.js/auto"
import Map from '@/components/Map'
import {debounce} from "lodash"
import FilterForm from "@/components/home/dashboard/Filter/FilterForm"
import {checkToken, downloadData, getDashboard, getListChartFields, getRecords, updateProfile} from "@/backend"
import convertDate from "@/ultis/convertDate"
import randomColor from "@/ultis/randomColor"
import RecordDetail from "@/components/home/dashboard/records/RecordDetail"
import config from '@/config.json'
import {mapState} from "@/store/ults"
import ColumnSetting from "@/components/home/dashboard/records/ColumnSetting"
import sleep from "@/ultis/sleep"
import STATUS from "@/constants/record-status-constants"
import Store from "@/store"

export default {
  name: "Dashboard",
  components: {ColumnSetting, RecordDetail, FilterForm, Map},
  data() {
    return {
      isComplete: true,
      listStatus: STATUS,
      tableHeight: 0,
      fullscreen: false,
      firstLoad: true,
      dashboardData: {},
      chartDataHorizontal: undefined,
      chartDataVertical: undefined,
      chartLegend: [],
      dataFarm: [],
      labels: [],
      loading: false,
      options: {},
      showReport: true,
      page: 1,
      totalItem: 100,
      pageCount: 0,
      listChartFields: [],
      reportHeaders: [],
      recordFilter: {
        status: 1,
        search: undefined,
      },
      reports: [],
      filterForm: {
        dateRanger: this.getCurrentDate(),
        state_id: undefined,
        district_id: undefined,
        sub_district_id: undefined,
        survey_id: undefined
      }
    }
  },
  props: {},
  mounted() {
    if (!this.currentUser.is_sysadmin && this.currentUser.verified) {
      this.getChartFields()
      this.getData()
      this.fetchRecord()
    } else if (this.currentUser.is_sysadmin) {
      this.$router.push({path: '/app/system_control'})
    }
    this.tableHeight = document.getElementById('table-report').offsetHeight
    if (this.currentUser.is_admin && (!this.currentUser.introduced || localStorage.getItem('intro'))) this.startIntroduction()
  },
  computed: {
    ...mapState("main", ["menu", "isHelp"]),
    ...mapState("auth", ["currentUser"]),
  },
  watch: {
    isHelp (val) {
      if (val) {
        localStorage.setItem('intro', true)
        this.startIntroduction()
      }
    },
    options: {
      handler() {
        if (!this.firstLoad) {
          this.fetchRecord()
        }
      },
      deep: true
    },
    recordFilter: {
      handler() {
        this.page = 1
        this.filterRecord()
      },
      deep: true
    },
    filterForm: {
      handler() {
        if (this.filterForm.dateRanger.length !== 2) return
        this.page = 1
        this.getData()
        this.fetchRecord()
      },
      deep: true
    }
  },
  methods: {
    async startIntroduction() {
      await sleep(1000)
      let steps = [
        {
          element: document.querySelector('.welcome'),
          intro: `
              <div style="width: 310px; height: 200px; background-color: #52A96A; text-align: center; padding: 15px; border-top-left-radius: 10px; border-top-right-radius: 10px">
                <img alt="welcome" src="/Agriculture-v2 2.png" width="100%" style="max-height: 800px; object-fit: cover">
              </div>
              <div style="width: 310px; text-align: center; padding: 15px">
                 <b style="font-size: 20px">Welcome, ${this.currentUser.name}</b>
                 <p style="font-size: 12px; padding-top: 10px">Welcome, ${this.currentUser.name}! Ready to start managing your crops? Let us help you get started</p>
              </div>
              <div style="display: flex; flex-direction: column; align-items: center; justify-items: center; margin-bottom: 15px">
                <div id="next" style="cursor: pointer; width: 130px; height: 35px; text-align: center; border-radius: 20px; background-color: #43a047; color: white; line-height: 35px; font-size: 13px">Show me around</div>
                <span id="skip" style="cursor: pointer; font-size: 13px; padding-top: 10px">Skip this tour</span>
              </div>
              `
        }
      ]
      steps = steps.concat([
        {
          element: document.querySelector('.list-survey'),
          intro: '<span>First, start by creating a new survey through the <b>survey management menu</b> highlighed above</span>',
          hidePrev: true,
        },
        {
          element: document.querySelector('.test'),
          intro: ''
        }
      ])
      this.intro.setOptions({
        disableInteraction: true,
        overlayOpacity: 0.7,
        exitOnOverlayClick: false,
        showBullets: false,
        steps: steps
      }).onbeforechange(e => {
        this.setEventIntro()
        if (this.intro._currentStep === steps.length - 1) {
          this.isComplete = false
          this.$router.push({path: '/app/survey_management'})
        }
      }).onafterchange(e => {
        this.setEventIntro()
      }).oncomplete(() => {
        if (this.isComplete) this.updateUser()
      }).onexit(() => {
        if (this.isComplete) this.updateUser()
      })
      this.intro.start()
    },
    setEventIntro () {
      let nextBtn = document.getElementById("next")
      let skipBtn = document.getElementById("skip")
      let content = document.querySelector('.introjs-tooltiptext')
      let footer = document.querySelector('.introjs-tooltipbuttons')
      let header = document.querySelector('.introjs-tooltip-header')
      if (this.intro._currentStep === 0 && this.$route.path === '/app') {
        if (content) content.style.padding = '0'
        if (header) header.style.display = 'none'
        if (footer) footer.style.display = 'none'
        if (nextBtn) nextBtn.addEventListener("click", () => {
          this.intro.nextStep()
        })
        if (skipBtn) skipBtn.addEventListener("click", () => {
          this.updateUser()
          this.intro.exit()
        })
      } else {
        if (header) header.style.display = 'block'
        if (footer) footer.style.display = 'block'
        if (content) content.style.padding = '20px'
      }
      let backBtn = document.querySelector('.introjs-prevbutton')
      if (this.intro._currentStep === 1 && backBtn && this.$route.path === '/app') {
        backBtn.style.display = 'none'
      } else if(backBtn) {
        backBtn.style.display = 'block'
      }
    },
    async updateUser() {
      try {
        this.loading = true
        localStorage.removeItem('intro')
        this.isHelp = false
        await updateProfile({
          introduced: true,
          name: this.currentUser.name,
          phone: this.currentUser.phone,
        })
      } catch (e) {
      } finally {
        await this.getMe()
        this.loading = false
      }
    },
    async getMe() {
      try {
        this.loading = true
        let res = await checkToken()
        Store.commit('auth/SET_USER', res.data)
      } catch (e) {
        localStorage.removeItem('jwt_token')
        window.location.href = `${location.origin}/signIn`
      } finally {
        this.loading = false
      }
    },
    async fullscreenReport() {
      this.fullscreen = !this.fullscreen
      await sleep(10)
      this.tableHeight = document.getElementById('table-report').offsetHeight
    },
    async getChartFields() {
      try {
        this.loading = true
        this.reportHeaders = []
        const res = await getListChartFields()
        this.listChartFields = res.data.dashboard_fields
        this.setHeader()
      } catch (e) {
        this.reportHeaders = []
      } finally {
        this.loading = false
      }
    },
    setHeader() {
      this.reportHeaders = []
      this.listChartFields.forEach(field => {
        if (field.default) this.reportHeaders.push({
          text: field.name.replaceAll('_', ' '),
          value: field.alias_name,
          sortable: true,
          align: "left"
        })
      })
      this.reportHeaders.push({text: "Detail", value: "detail", sortable: false, align: "center"})
    },
    async downloadData(name, status, type) {
      let form = {
        surveyId: this.filterForm.survey_id,
        data: JSON.parse(JSON.stringify(this.filterForm))
      }
      form.data.status = status
      form.data.type = type
      delete form.data.survey_id
      try {
        this.loading = true
        const res = await downloadData(form)
        let newWindow = window.open(config.downloadUrl + res.data.file + '?name=' + name, '_blank')
        newWindow.focus()
      } catch (e) {
      } finally {
        this.loading = false
      }
    },
    getCurrentDate() {
      let currentDate = new Date()
      let beforeDate = new Date(currentDate.getTime() - 1000 * 60 * 60 * 24 * 29)
      let day = currentDate.getDate() < 10 ? '0' + currentDate.getDate() : currentDate.getDate()
      let month = currentDate.getMonth() < 10 ? '0' + (currentDate.getMonth() + 1) : (currentDate.getMonth() + 1)
      let year = currentDate.getFullYear()

      let beforeDay = beforeDate.getDate() < 10 ? '0' + beforeDate.getDate() : beforeDate.getDate()
      let beforeMonth = beforeDate.getMonth() < 10 ? '0' + (beforeDate.getMonth() + 1) : (beforeDate.getMonth() + 1)
      let beforeYear = beforeDate.getFullYear()
      return [beforeYear + '-' + beforeMonth + '-' + beforeDay, year + '-' + month + '-' + day]
    },
    filterRecord: debounce(function (e) {
      if (this.filterForm.dateRanger.length !== 2) return
      this.fetchRecord()
    }, 500),
    async fetchRecord() {
      try {
        this.reports = []
        this.loading = true
        const resRecord = await getRecords(this.getParamRecord())
        this.reports = resRecord.data.data
        this.totalItem = resRecord.data.total
        this.pageCount = resRecord.data.last_page
        this.recordFilter.forEach(val => {
          val.status = this.recordFilter.status
        })
      } catch (e) {

      } finally {
        this.firstLoad = false
        this.loading = false
      }
    },
    getParamDashboard() {
      return JSON.parse(JSON.stringify(this.filterForm))
    },
    getParamRecord() {
      let sort
      if (this.options.sortBy.length && this.options.sortBy.length) {
        if (this.options.sortDesc[0]) sort = this.options.sortBy[0]
        else sort = '-' + this.options.sortBy[0]
      }
      let param = {...JSON.parse(JSON.stringify(this.filterForm)), ...this.recordFilter}
      param['per_page'] = 10
      param['page'] = this.page
      param['sort'] = sort
      return param
    },
    async getData() {
      try {
        this.loading = true
        const resDashboard = await getDashboard(this.getParamDashboard())
        this.dataFarm = resDashboard.data.chartData.value
        this.labels = resDashboard.data.chartData.label
        this.dashboardData = resDashboard.data
        this.$refs.map.displayCluster(this.dashboardData.map)
        if (this.chartDataVertical) this.updateChart()
        else this.displayChart()
      } catch (e) {

      } finally {
        this.loading = false
      }
    },
    changeDisplayReport() {
      this.showReport = !this.showReport
      this.$refs.map.reSize()
    },
    updateChart() {
      let colors = []
      this.labels.forEach((label, index) => {
        colors.push(randomColor.getColor(index))
      })
      this.chartDataVertical.data.labels = this.labels
      this.chartDataVertical.data.datasets = [{
        label: this.labels,
        data: this.dataFarm,
        borderColor: colors,
        barPercentage: 0.6,
        backgroundColor: colors,
      }]
      const datasets = []
      for (let i = 0; i < this.labels.length; i++) {
        datasets.push({
          axis: 'y',
          label: this.labels[i],
          data: [this.dataFarm[i]],
          borderColor: colors[i],
          barPercentage: 0.6,
          backgroundColor: colors[i],
          stack: 'Area'
        })
      }
      this.chartDataHorizontal.data.datasets = datasets
      this.chartDataVertical.update()
      this.chartDataHorizontal.update()
    },
    changeDisplayChart(legend) {
      this.chartDataHorizontal.setDatasetVisibility(legend.datasetIndex, legend.hidden)
      this.chartDataHorizontal.update()
    },
    displayChart() {
      let colors = []
      this.labels.forEach((label, index) => {
        colors.push(randomColor.getColor(index))
      })
      this.displayChartHorizontal(colors)
      this.displayChartVertical(colors)
    },
    displayChartVertical(colors) {
      let ctx = document.getElementById('chart')
      const data = {
        labels: this.labels,
        datasets: [{
          data: this.dataFarm,
          borderColor: colors,
          barPercentage: 0.6,
          backgroundColor: colors,
        }]
      }
      this.chartDataVertical = new Chart(ctx, {
        type: 'bar',
        data: data,
        options: {
          scales: {
            x: {
              ticks: {
                font: {
                  size: 11
                }
              }
            },
            y: {
              ticks: {
                font: {
                  size: 11
                }
              },
              title: {
                text: 'Total',
                display: true
              },
            }
          },
          plugins: {
            legend: {display: false}
          },
        },
      })
    },
    displayChartHorizontal(colors) {
      let ctx = document.getElementById('chartV2')
      const htmlLegendPlugin = {
        id: 'htmlLegend',
        afterUpdate: (chart, args, options) => {
          this.chartLegend = chart.options.plugins.legend.labels.generateLabels(chart)
        }
      }
      const data = {
        labels: [''],
        datasets: []
      }
      for (let i = 0; i < this.labels.length; i++) {
        data.datasets.push({
          axis: 'y',
          label: this.labels[i],
          data: [this.dataFarm[i]],
          borderColor: colors[i],
          barPercentage: 0.6,
          backgroundColor: colors[i],
          stack: 'Area'
        })
      }
      this.chartDataHorizontal = new Chart(ctx, {
        type: 'bar',
        data: data,
        axis: 'y',
        options: {
          indexAxis: 'y',
          plugins: {
            legend: {display: false}
          },
          scales: {
            x: {
              ticks: {
                font: {
                  size: 10
                },
              },
              stacked: true,
              grid: {
                display: false
              }
            },
            y: {
              ticks: {
                font: {
                  size: 10
                },
              },
              title: {
                text: 'Total',
                display: true
              },
              stacked: true,
              grid: {
                display: true
              }
            }
          },
        },
        plugins: [htmlLegendPlugin]
      })
    },
    convertDate(date) {
      return convertDate.dateFormatted(date)
    },
    // transformNumber(number) {
    //   return numberFormat.numberWithCommas(number)
    // }
  }
}
</script>

<style scoped>
.card-content {
  border-radius: 5px;
  width: 100%;
  height: 100%;
  /*border: 1px solid rgba(137, 63, 242, 0.2);*/
  /*box-shadow: inset 0 0 4px var(--v-tab-base);*/
  background-color: #fafafa;
}

/deep/
.filter-select .v-input__slot {
  min-height: 0 !important;
}

/deep/
.filter-select .v-input__append-inner {
  margin-top: 2px !important;
}

/deep/
.month-picker .v-input__slot {
  min-height: 0 !important;
}

/deep/
.month-picker .v-input__prepend-inner {
  margin-top: 4px !important;
}

/deep/
.v-select__selection {
  color: #128600 !important;
  text-transform: uppercase;
  font-weight: 500;
}

/deep/
.select-input .v-input__control {
  box-shadow: 1px 2px 2px rgba(128, 124, 133, 0.2), inset 0px 0px 4px rgba(137, 63, 242, 0.33);
}

/deep/
.small-radio .v-input--selection-controls__input:hover .v-input--selection-controls__ripple:before {
  background: currentColor;
  transform: scale(0.8);
  transition: none;
}

#legend-container {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  flex: none;
  border: 1px solid lightgray;
  border-radius: 8px
}

.header-monitor {
  flex: none;
  height: 48px;
  width: 100%;
  background-color: var(--v-primary-base);
  border-top-left-radius: 8px;
  border-top-right-radius: 8px
}

.custom-legend {
  flex: none;
  height: 170px
}

.grid-data {
  height: 170px;
  flex: none
}

@media only screen and (max-height: 750px) {
  .grid-data {
    height: 110px;
    flex: none
  }

  .custom-legend {
    flex: none;
    height: 55px
  }
}

.report-status-small {
  display: none;
}

.report-status {
  display: flex;
}

@media only screen and (max-width: 1420px) {
  .report-status-small {
    display: block;
  }

  .report-status {
    display: none;
  }
}
</style>
