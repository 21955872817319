<template>
  <v-dialog
      v-model="updateOptionDialog"
      width="800"
      persistent
  >
    <v-card class="d-flex flex-column fill-height">
      <v-overlay :value="loading" absolute>
        <v-progress-circular
            indeterminate
            size="64"
        ></v-progress-circular>
      </v-overlay>
      <v-card-title class="form-header">
        <span style="font-size: 16px">UPDATE OPTION</span>
        <v-spacer/>
        <v-btn :disabled="loading" icon small @click="updateOptionDialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text class="pa-5 overflow-y-hidden" style="height: 58vh">
        <v-form v-model="valid" ref="formData" class="fill-height">
          <v-layout class="fill-height" column>
            <div style="flex: none; height: 80px; border-bottom: 1px solid lightgray">
              <v-layout align-center class="fill-height">
                <div style="flex: 1" class="flex pr-1">
                  <span style="font-size: 14px; font-weight: bold" class="mt-1">Name</span>
                  <div style="width: 100%;">
                    <v-text-field
                        class="mt-1"
                        outlined
                        dense
                        v-model="currentOption.name"
                        label="Name"
                        :rules="[rules.requiredName]">
                    </v-text-field>
                  </div>
                </div>
                <div style="flex: 1" class="flex pl-1">
                  <span style="font-size: 14px; font-weight: bold" class="mt-1">Reference</span>
                  <div style="width: 100%;">
                    <v-autocomplete
                        disabled
                        @change="getOptionDetail"
                        class="mt-1 select-box"
                        outlined
                        dense
                        v-model="currentOption.ref_id"
                        label="Option"
                        :items="allOption"
                        item-text="name"
                        item-value="id">
                    </v-autocomplete>
                  </div>
                </div>
                <div style="width: 48px; height: 100%; flex: none">
                  <v-layout class="fill-height" align-center justify-center>
                    <v-btn title="Save" v-if="currentOption.type !== 'system'" color="primary" class="ml-1" width="40" min-width="0" @click="saveOption">
                      <v-icon>mdi-content-save</v-icon>
                    </v-btn>
                  </v-layout>
                </div>
              </v-layout>
            </div>
            <div style="flex: none; height: 60px;">
              <v-layout class="fill-height" align-center>
                <div style="width: 200px">
                  <v-text-field outlined v-model="search" label="Search" append-icon="mdi-magnify" hide-details dense></v-text-field>
                </div>
                <v-spacer/>
                <v-btn color="info" class="ml-1" width="160.27" @click="addChoice">Add Choice</v-btn>
              </v-layout>
            </div>
            <div style="flex: none; height: 30px; width: 100%">
              <v-layout align-center class="fill-height">
<!--                <div style="width: 60px; height: 100%; flex: none; text-align: center" class="fill-height" v-if="currentOption.type !== 'system'">-->
<!--                  <v-layout class="fill-height" align-center>-->
<!--                    <span style="font-size: 14px; font-weight: bold" class="mt-0">Active</span>-->
<!--                  </v-layout>-->
<!--                </div>-->
                <div style="flex: 1;" :class="currentOption.ref_id ? 'pr-1' : ''" class="d-flex justify-center flex-column">
                  <span style="font-size: 14px; font-weight: bold" class="mt-0">Choices</span>
                </div>
                <div style="flex: 1;" class="pl-1 d-flex justify-center flex-column" v-if="currentOption.ref_id">
                  <span style="font-size: 14px; font-weight: bold" class="mt-0">Reference</span>
                </div>
                <div style="width: 70px; height: 100%; flex: none" v-if="currentOption.type !== 'system'">
                  <v-layout align-center justify-end>
                  </v-layout>
                </div>
              </v-layout>
            </div>
            <div class="flex d-flex fill-height flex-column overflow-y-auto overflow-x-hidden">
              <v-data-iterator
                  :items="currentOption.choices"
                  item-key="id"
                  :items-per-page="10"
                  :page="page"
                  hide-default-footer
              >
                <template v-slot:default="{ items }">
                  <v-layout v-for="(item, index) of items" :key="index" align-center >
<!--                    <div style="width: 60px; height: 100%; flex: none" class="fill-height"-->
<!--                         v-if="items.length > 1 && currentOption.type !== 'system'">-->
<!--                      <v-layout align-center justify-center class="fill-height">-->
<!--                        <v-checkbox-->
<!--                            :readonly="!item.editing"-->
<!--                            class="mt-0"-->
<!--                            v-model="item.active"-->
<!--                        ></v-checkbox>-->
<!--                      </v-layout>-->
<!--                    </div>-->
                    <div style="flex: 1;" :class="item.ref_id ? 'pr-1' : ''" class="d-flex justify-center flex-column">
                      <div style="width: 100%;">
                        <v-layout>
                          <v-text-field
                              :readonly="!item.editing"
                              class="mt-1"
                              outlined
                              dense
                              v-model="item.name"
                              :label="'Choice ' + ((page -1) * 10 + index + 1)"
                              :rules="[rules.requiredItem, rules.uniqueValue]">
                          </v-text-field>
                        </v-layout>
                      </div>
                    </div>
                    <div style="flex: 1;" class="pl-1 d-flex justify-center flex-column" v-if="currentOption.ref_id">
                      <div style="width: 100%;">
                        <v-layout>
                          <v-autocomplete
                              :readonly="!item.editing"
                              deletable-chips
                              small-chips
                              class="mt-1"
                              outlined
                              dense
                              multiple
                              v-model="item.ref_ids"
                              :items="optionDetail"
                              label="Reference"
                              item-text="name"
                              item-value="id"
                              :rules="[rules.requiredItems]"
                          ></v-autocomplete>
                        </v-layout>
                      </div>
                    </div>
                    <div style="width: 70px; height: 100%; flex: none; margin-top: -18px" v-if="currentOption.type !== 'system'">
                      <v-layout class="fill-height" align-center justify-end>
                        <v-btn icon small color="primary" @click="saveCurrentOption(item)">
                          <v-icon v-if="item.editing">mdi-content-save</v-icon>
                          <v-icon v-else>mdi-pencil</v-icon>
                        </v-btn>
                        <v-btn v-if="items.length > 1" icon small color="error" @click="removeOption(item)">
                          <v-icon>mdi-delete</v-icon>
                        </v-btn>
                      </v-layout>
                    </div>
                  </v-layout>
                </template>
              </v-data-iterator>
            </div>
          </v-layout>
        </v-form>
      </v-card-text>
      <v-divider/>
      <v-card-actions class="justify-center">
        <v-pagination
            v-if="currentOption.choices"
            class="mx-3 mt-1 custom-pagination"
            :total-visible="5"
            v-model="page"
            :length="Math.ceil( currentOption.choices.length / 10)">
        </v-pagination>
      </v-card-actions>
      <DeleteItemOption ref="deleteItem" @removeChoice="removeChoice"/>
    </v-card>
  </v-dialog>
</template>

<script>
import {
  addItemOption,
  getOptionDetail, saveItemOption, updateOption
} from "@/backend"
import {debounce} from "lodash"
import DeleteItemOption from "./DeleteItemOption"

export default {
  name: "PopupUpdateOption",
  components: {DeleteItemOption},
  data() {
    return {
      search: undefined,
      loading: false,
      valid: false,
      page: 1,
      currentOption: {},
      optionDetail: [],
      referenceId: undefined,
      optionName: undefined,
      rules: {
        requiredName: value => (!!value && !!value.trim()) || 'Name is required',
        requiredItem: value => (!!value && !!value.trim()) || 'Item is required',
        uniqueValue: value => this.checkUnique(value) || 'Option name already exists',
        requiredItems: value => (!!value.length) || 'Item is required',
      },
      updateOptionDialog: false
    }
  },
  props: {
    allOption: {type: Array, default: () => []},
  },
  computed: {},
  mounted() {
  },
  watch: {
    search (val) {
      this.searchData()
    },
    updateOptionDialog (val) {
      if (!val) {
        this.page = 1
        this.optionDetail = []
        this.currentOption = {}
        this.referenceId = undefined
      }
    }
  },
  methods: {
    searchData: debounce(function async (e) {
      this.fetchChoiceData(this.currentOption.id)
    }, 500),
    async openDialog(currentOption) {
      this.updateOptionDialog = true
      this.currentOption = JSON.parse(JSON.stringify(currentOption))
      await this.getOptionDetail()
      await this.fetchChoiceData(currentOption.id)
      this.fetchData()
    },
    checkUnique (optionName) {
      return (this.currentOption.choices.filter(choice => String(choice.name) === String(optionName)).length <= 1)
    },
    fetchData() {
      let tmpOption = JSON.parse(JSON.stringify(this.currentOption))
      this.currentOption = []
      this.currentOption = tmpOption
    },
    async addChoice() {
      try {
        this.loading = true
        const data = {name: 'Name of option' + this.currentOption.choices.length, ref_ids: this.optionDetail.length ? [this.optionDetail[0].id] : []}
        await addItemOption({id: this.currentOption.id, data: data})
        this.$store.commit('message/SHOW_SUCCESS', 'Success')
        await this.fetchChoiceData(this.currentOption.id)
        this.fetchData()
      } catch (e) {
      } finally {
        this.loading = false
      }
    },
    async fetchChoiceData (id) {
      this.currentOption.choices = await this.getDetail(id)
    },
    async getDetail(id) {
      try {
        this.loading = true
        const res = await getOptionDetail({id: id, data: {per_page: 'all', 'sort': '-created_at', 'search': this.search}})
        for (let i = 0; i < res.data.length; i++) {
          res.data[i]['editing'] = false
        }
        return res.data
      } catch (e) {
      } finally {
        this.loading = false
      }
    },
    async getOptionDetail() {
      if (!this.currentOption.ref_id) return
      this.optionDetail = await this.getDetail(this.currentOption.ref_id)
    },
    removeOption(currentItem) {
      this.$refs.deleteItem.openDialog(currentItem, this.currentOption.id)
    },
    removeChoice(currentOption) {
      let index = this.currentOption.choices.findIndex(val => val.id === currentOption.id)
      if (index >= 0) this.currentOption.choices.splice(index, 1)
      this.fetchData()
    },
    async saveCurrentOption(currentOption) {
      if (!currentOption.editing) return (currentOption.editing = true)
      try {
        this.loading = true
        const res = await saveItemOption({
          choiceId: currentOption.id,
          id: this.currentOption.id,
          data: {
            name: currentOption.name,
            ref_ids: currentOption.ref_ids,
            active: currentOption.active
          }
        })
        this.$store.commit('message/SHOW_SUCCESS', 'Success')
        currentOption.editing = false
      } catch (e) {
      } finally {
        this.loading = false
      }
    },
    async saveOption() {
      this.$refs.formData.validate()
      if (!this.valid) return
      try {
        this.loading = true
        await updateOption({id: this.currentOption.id, data: {name: this.currentOption.name}})
        this.$store.commit('message/SHOW_SUCCESS', 'Success')
        this.$emit('getOption')
      } catch (e) {
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style scoped>
.create-content {
  height: 100%;
  border-radius: 8px;
  border: 1px solid rgba(137, 63, 242, 0.1);
  box-shadow: inset 0 0 4px var(--v-tab-base);
  background-color: #ffffff;
}

/deep/
.edit-input .v-input__control {
  min-height: 0 !important;
  height: 28px !important;
}

/deep/
.custom-pagination .v-pagination__navigation {
  width: 25px !important;
  height: 25px !important;
}

/deep/
.v-pagination__item {
  min-width: 25px !important;
  height: 25px !important;
}
</style>
