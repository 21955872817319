const date = {
  dateFormatted(date) {
    if (!date) return ''
    let currentDate = new Date(date)
    let day = currentDate.getDate() < 10 ? '0' + currentDate.getDate() : currentDate.getDate()
    let month = currentDate.getMonth() + 1 < 10 ? '0' + (currentDate.getMonth() + 1) : currentDate.getMonth() + 1
    let year = currentDate.getFullYear()
    return year + '-' + month + '-' + day
  },
}
export default date
