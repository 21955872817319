<template>
  <v-layout style="width: 100%; height: 100%">
    <Unauthorized/>
  </v-layout>
</template>

<script>
import Unauthorized from '@/components/Unauthorized'

export default {
    components: { Unauthorized }
}
</script>
