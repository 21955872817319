<template>
  <v-layout style="width: 100%; height: 100%">
    <Profile/>
  </v-layout>
</template>

<script>
import Profile from '@/components/home/management/user/myUser/Profile'

export default {
    components: { Profile }
}
</script>
