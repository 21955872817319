<template>
  <v-layout align-center justify-center style="height: 100vh" class="pa-5">
    <v-overlay :value="loading">
      <v-progress-circular
          indeterminate
          size="64"
      ></v-progress-circular>
    </v-overlay>
    <div class="d-flex flex flex-column px-5 pt-5 fill-height user-info">
      <div class="profile-header">
        <v-layout class="fill-height" justify-center align-center column>
          <div style="width: 130px; height: 130px; position: relative">
            <img
                :src="imgBase64 ? imgBase64 : currentUser.avatar ? transformUrl(currentUser.avatar) : require('@/assets/images/default-avatar.jpg')"
                height="100%" width="100%"
                style="border-radius: 50%; border: 2px solid var(--v-primary-base); padding: 4px" alt="icon"/>
            <!--            <v-btn-->
            <!--                @click="uploadDialog = true"-->
            <!--                style="position: absolute; bottom: 10px; right: 5px"-->
            <!--                x-small-->
            <!--                color="primary"-->
            <!--                fab-->
            <!--                class="elevation-0">-->
            <!--              <v-icon>mdi-pencil-outline</v-icon>-->
            <!--            </v-btn>-->
          </div>
          <h3 class="mt-3">{{ currentUser.email }}</h3>
        </v-layout>
      </div>
      <v-form v-model="valid" ref="formData" class="d-flex flex-column fill-height">
        <v-layout column class="fill-height py-5">
          <v-layout class="fill-height">
            <div class="flex pr-4" style="flex: 1; border-right: 1px solid lightgray">
              <div style="height: 60px; width: 100%; flex: none">
                <v-layout class="fill-height" align-center>
                  <h2>User information</h2>
                </v-layout>
              </div>
              <span style="font-size: 14px; font-weight: bold;">Name</span>
              <div style="width: 100%;" class="pt-1">
                <v-text-field
                    class="input-login"
                    background-color="white"
                    dense
                    outlined
                    v-model="profile.name"
                    label="Name"
                    placeholder="Name"
                    style="color: black !important;"
                    :rules="[rules.requiredUserName]"
                >
                </v-text-field>
              </div>
              <span style="font-size: 14px; font-weight: bold;">Phone Number</span>
              <div style="width: 100%;" class="pt-1">
                <v-text-field
                    class="input-login"
                    background-color="white"
                    dense
                    outlined
                    type="number"
                    v-model="profile.phone"
                    label="Phone number"
                    placeholder="Phone number"
                    style="color: black !important;"
                    :rules="phoneRules"
                >
                </v-text-field>
              </div>
            </div>
            <div class="flex pl-4" style="flex: 1">
              <div style="height: 60px; width: 100%; flex: none">
                <v-layout class="fill-height" align-center>
                  <v-checkbox hide-details v-model="isChangePassWord" label="Change password"/>
                </v-layout>
              </div>
              <v-card flat :disabled="!isChangePassWord">
                <span style="font-size: 14px; font-weight: bold;">Password</span>
                <div style="width: 100%;" class="pt-1">
                  <v-text-field
                      class="input-login"
                      dense
                      outlined
                      background-color="white"
                      v-model="password"
                      label="Password"
                      :rules="isChangePassWord ?  [rules.requiredPassword] : []"
                      :type="isShowPassword ? 'text' : 'password'"
                      :append-icon="isShowPassword ? 'mdi-eye-off' : 'mdi-eye'"
                      @click:append="isShowPassword = !isShowPassword"
                  >
                  </v-text-field>
                </div>
                <span style="font-size: 14px; font-weight: bold;">Confirm Password</span>
                <div style="width: 100%;" class="pt-1">
                  <v-text-field
                      class="input-login"
                      dense
                      outlined
                      background-color="white"
                      v-model="confirmPassword"
                      label="Confirm Password"
                      :rules="isChangePassWord ?  [rules.requiredConfirmPassword] : []"
                      :type="isShowPassword ? 'text' : 'password'"
                      :disabled="!this.password"
                      :append-icon="isShowPassword ? 'mdi-eye-off' : 'mdi-eye'"
                      @click:append="isShowPassword = !isShowPassword">
                  </v-text-field>
                </div>
              </v-card>
            </div>
          </v-layout>
        </v-layout>
      </v-form>
      <div style="flex: none; height: 55px; border-top: 1px solid lightgray">
        <v-layout class="fill-height" justify-center align-center>
          <v-btn color="accent" width="180" class="mr-2" @click="$router.push({ path: '/app' })">
            <v-icon class="mr-1">mdi-home</v-icon>
            Dashboard
          </v-btn>
          <v-spacer/>
          <v-btn color="primary" width="180" class="ml-2" @click="saveProfile">
            <v-icon class="mr-1">mdi-content-save</v-icon>
            Save profile
          </v-btn>
        </v-layout>
      </div>
    </div>
    <v-dialog
        v-model="uploadDialog"
        width="500"
        persistent
    >
      <v-card>
        <v-card-text>
          <v-layout style="height: 70px" align-center justify-center>
            <h2>Upload image</h2>
            <v-btn
                @click="uploadDialog = false"
                icon
                small
                style="position: absolute; top: 10px; right: 10px">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-layout>
          <UploadForm @change="updateAvatar" accept=".jpg, .jpeg, .png"/>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script>
import {mapState} from "@/store/ults";
import {checkToken, updateProfile} from "@/backend";
import Store from "@/store"
import config from '@/config.json'
import UploadForm from "@/components/UploadForm";

export default {
  name: "Profile",
  components: {UploadForm},
  data() {
    return {
      file: undefined,
      imgBase64: undefined,
      uploadDialog: false,
      isChangePassWord: false,
      loading: false,
      valid: false,
      popupForgot: false,
      rules: {
        requiredUserName: value => (!!value && !!value.trim()) || 'Name is required',
        requiredPassword: value => (!!value && !!value.trim()) || 'Password is invalid',
        requiredConfirmPassword: value => (!!value && !!value.trim() && value.trim() === this.password.trim()) || 'Password is invalid'
      },
      phoneRules: [
        v => !!v || 'Phone number is required',
        v => /[0-9]{8,}$/.test(v) || 'Phone number must be more than 7 characters and include only numbers'
      ],
      confirmPassword: undefined,
      password: undefined,
      isShowPassword: false,
      profile: {}
    }
  },
  computed: {
    ...mapState('auth', ['currentUser']),
  },
  mounted() {
    this.profile = JSON.parse(JSON.stringify(this.currentUser))
  },
  methods: {
    toBase64() {
      return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsDataURL(this.file)
        reader.onload = () => resolve(reader.result)
        reader.onerror = error => reject(error)
      })
    },

    async updateAvatar(avatar) {
      this.file = avatar
      this.imgBase64 = await this.toBase64()
      this.uploadDialog = false
    },
    transformUrl(url) {
      return config.host + url
    },
    async saveProfile() {
      try {
        this.$refs.formData.validate()
        if (!this.valid) return
        this.loading = true
        if (this.file) {
          if (this.file.size > 25000000) return (this.$store.commit('message/SHOW_ERROR', 'Image size <= 25MB'))
          const formData = new FormData()
          formData.append('avatar', this.file)
          formData.append('name', this.profile.name)
          formData.append('phone', this.profile.name,)
          if (this.isChangePassWord) {
            formData.append('password', this.password)
            formData.append('password_confirmation', this.confirmPassword)
          }
          await updateProfile(formData)
        } else {
          await updateProfile({
            name: this.profile.name,
            phone: this.profile.phone,
            password: this.isChangePassWord ? this.password : undefined,
            password_confirmation: this.isChangePassWord ? this.confirmPassword : undefined
          })
        }
        this.$store.commit('message/SHOW_SUCCESS', 'Success')
      } catch (e) {
      } finally {
        this.getMe()
        this.loading = false
      }
    },
    async getMe() {
      try {
        this.loading = true
        let res = await checkToken()
        Store.commit('auth/SET_USER', res.data)
      } catch (e) {
        localStorage.removeItem('jwt_token')
        window.location.href = `${location.origin}/signIn`
      } finally {
        this.profile = JSON.parse(JSON.stringify(this.currentUser))
        this.loading = false
      }
    }
  }
}
</script>

<style scoped>
.profile-header {
  flex: none;
  height: 220px;
  border-bottom: 1px solid lightgray
}

.user-info {
  max-width: 1040px;
  border: 1px solid lightgray;
  border-radius: 8px;
  overflow-y: auto;
  border: 1px solid rgba(137, 63, 242, 0.1);
  box-shadow: inset 0 0 3px var(--v-tab-base);
}

@media only screen and (max-height: 750px) {
  .profile-header {
    flex: none;
    height: 175px;
    border-bottom: 1px solid lightgray
  }
}
</style>
