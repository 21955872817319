<template>
<v-dialog
  v-model="editDialog"
  persistent
  width="500px">
  <v-card v-if="currentField">
    <v-card-title class="popup-header">
      <span style="font-size: 16px">UPDATE: {{currentField.name}}</span>
      <v-spacer/>
      <v-btn icon small @click="editDialog = false"><v-icon>mdi-close</v-icon></v-btn>
    </v-card-title>
    <v-card-text class="pa-3">
      <v-layout column class="fill-height">
        <DatePicker
            outlined
            v-if="currentField.type === 'date'"
            :label="currentField.name"
            v-model="currentField.value"/>
        <v-select
            v-else-if="currentField.type === 'boolean'"
            outlined
            dense
            v-model="currentField.value"
            :items="['true', 'false']"
            :label="currentField.name"
            return-object
            class="select-box"
        ></v-select>
        <v-text-field
            v-else
            outlined
            dense
            :rules="currentField.rules.required ? [rules.required] : []"
            :type="getTypeOfInput"
            :min="currentField.rules.min"
            :max="currentField.rules.max"
            v-model="currentField.value"
            :label="currentField.name">
        </v-text-field>
      </v-layout>
    </v-card-text>
    <v-divider/>
    <v-card-actions class="popup-footer">
      <v-spacer/>
      <v-btn :loading="loading" width="120" color="primary" @click="saveField" dark>
        <v-icon class="mr-1">mdi-content-save</v-icon>
        Submit
      </v-btn>
    </v-card-actions>
  </v-card>
</v-dialog>
</template>

<script>
import { updateField } from '@/backend'
import DatePicker from "@/components/DatePicker";
export default {
  name: "EditField",
  components: {DatePicker},
  data () {
    return {
      loading: false,
      systemFields: [],
      currentField: undefined,
      editDialog: false,
      rules: {
        required: v => !!v || 'Item is required',
      },
      types: ['text', 'numeric', 'int', 'email', 'date', 'boolean']
    }
  },
  props: {
    currentRecord: {type: Object, default: () => {}}
  },
  computed: {
    getTypeOfInput() {
      if ([ 'numeric', 'int' ].includes(this.currentField.type) || this.currentField.rules.number_only) return 'number'
      else return 'text'
    }
  },
  methods: {
    openDialog (field) {
      this.currentField = JSON.parse(JSON.stringify(field))
      this.systemFields = (this.currentField.system_fields && this.currentField.system_fields.length) ? this.currentField.system_fields : []
      this.editDialog = true
    },
    async saveField () {
      try {
        this.loading = true
        await updateField({
          surveyId: this.currentRecord.survey_id,
          id: this.currentRecord.id,
          data: {
            field: this.currentField.alias_name,
            value: this.currentField.value
          }
        })
        this.$emit('refreshData')
        this.editDialog = false
      } catch (e) {
      } finally {
        this.loading = false
      }
    }
  },
}
</script>

<style scoped>

</style>
