<template>
  <div class="d-flex fill-height justify-center flex-column pa-2" style="width: 100%" v-if="currentUser.is_admin">
    <div style="width: 100%; height: 120px; border-bottom: 1px solid lightgrey; flex: none">
      <v-layout
          align-center
          justify-center
          column
          class="fill-height"
          style="background-color: var(--v-bar-base);border-radius: 6px""
      >
        <v-layout
            class="pt-2"
            style="font-size: 32px; font-weight: bold; width: 100%; color: #fafafa; text-transform: uppercase"
            justify-center
            align-center
        >
          <v-icon color="white" size="40" class="mr-1">mdi-form-select</v-icon>
          Option management
        </v-layout>
        <v-container fluid class="d-flex overflow-y-hidden flex-column" style="height: 100%; max-width: 1550px">
          <v-layout justify-end align-center class="fill-height">
            <v-menu
                :close-on-content-click="false"
                absolute
                offset-y
                style="width: 300px"
            >
              <template v-slot:activator="{ on, attrs }">
                <div class="mr-2">
                  <div style="border: #fafafa 2px solid; border-radius: 4px; margin-top: -4px" class="mr-1">
                    <v-btn
                        :disabled="$attrs['loading']"
                        v-bind="attrs"
                        v-on="on"
                        width="122"
                        min-width="0"
                        color="info"
                        class="elevation-0">
                      <v-icon class="mr-1">mdi-microsoft-excel</v-icon>
                      Example
                    </v-btn>
                  </div>
                </div>
              </template>

              <v-list dense color="backgroundForm" width="230">
                <v-hover v-slot="{ hover }" >
                  <v-list-item color="#893FF2" :style="{'background-color': hover ? 'var(--v-backgroundList-base)' : ''}">
                    <v-list-item-title >Template:</v-list-item-title>
                    <v-list-item-action>
                      <v-layout class="pr-8">
                        <v-tooltip bottom color="#00000099">
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn v-bind="attrs" v-on="on" color="primary" fab x-small class="mr-1 elevation-0" @click="downloadData('option_1_template', 'csv')">
                              <v-icon size="16" color="white">mdi-file-excel-outline</v-icon>
                            </v-btn>
                          </template>
                          <span>*.csv</span>
                        </v-tooltip>
                        <v-tooltip bottom color="#00000099">
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn v-bind="attrs" v-on="on" color="primary" fab x-small class="ml-1" @click="downloadData('option_1_template', 'xlsx')" >
                              <v-icon size="16" color="white" >mdi-microsoft-excel</v-icon>
                            </v-btn>
                          </template>
                          <span>*.xlsx</span>
                        </v-tooltip>
                      </v-layout>
                    </v-list-item-action>
                  </v-list-item>
                </v-hover>
              </v-list>
            </v-menu>
            <popup-upload-option :loading="loading" :allOption.sync="allOption" @getOption="fetchData"/>
            <popupCreateOption :loading="loading" :allOption.sync="allOption" @getOption="fetchData"/>          </v-layout>
        </v-container>
      </v-layout>
    </div>
    <v-container fluid class="d-flex flex-column overflow-y-hidden" style="height: 100%; max-width: 1550px">
      <v-layout fill-height style="overflow-y: auto">
        <v-data-table
              height="100%"
              fixed-header
              style="height: 100%; width: 100%"
              :headers="optionHeaders"
              :items="listOption"
              :search="search"
              :loading="loading"
              :items-per-page="10"
              class="row-pointer order-table d-flex flex-column"
              hide-default-footer
              :server-items-length.sync="totalItem"
              loading-text="Loading... Please wait"
              :options.sync="options"
              :page.sync="page"
          >
            <template v-slot:top>
              <v-layout class="flex-wrap py-2">
                <v-layout class="pb-2">
                  <div style="flex: 5">
                  </div>
                  <div style="flex: 1">
                    <v-text-field
                        class="ml-1"
                        outlined
                        dense
                        hide-details
                        v-model="search"
                        @input="debounceInput"
                        append-icon="mdi-magnify"
                        label="Search"
                    ></v-text-field>
                  </div>
                </v-layout>
              </v-layout>
            </template>
            <template v-slot:[`item.created_at`]="{ item }">
              {{convertDate(item.created_at)}}
            </template>
            <template v-slot:[`item.updated_at`]="{ item }">
              {{convertDate(item.updated_at)}}
            </template>
            <template v-slot:[`item.type`]="{ item }">
              <v-chip :color="item.type === 'system' ? 'tab' : 'warning'" small style="width: 65px" class="text-capitalize">{{item.type}}</v-chip>
            </template>
            <template v-slot:[`item.action`]="{ item }">
              <v-btn title="Edit" icon min-height="0" min-width="0" width="30" height="30" color="green" @click="() => $refs.updateSurveyForm.openDialog(item)"><v-icon size="21">mdi-circle-edit-outline</v-icon></v-btn>
              <v-btn title="Delete" :disabled="item.type === 'system'" icon min-height="0" min-width="0" width="30" height="30" color="red" class="ml-2" @click="() => $refs.deleteSurveyForm.openDialog(item)"><v-icon size="21">mdi-delete-outline</v-icon></v-btn>
            </template>
          </v-data-table>
      </v-layout>
        <div class="text-center pa-3" style="flex: none">
          <v-pagination :total-visible="5" v-model="page" :length="pageCount" id="task_pagination"></v-pagination>
        </div>
    </v-container>
    <PopupUpdate :allOption.sync="allOption" @getOption="fetchData" ref="updateSurveyForm"/>
    <PopupDelete @getOption="fetchData" ref="deleteSurveyForm"/>
  </div>
</template>

<script>
import {
  updateSurvey, getOption
} from "@/backend";
import {mapState} from "@/store/ults"
import {debounce} from "lodash"
import convertDate from "@/ultis/convertDate"
import popupCreateOption from "./CreateOptionForm"
import PopupUpdate from "./UpdateOptionForm"
import PopupDelete from "./DeleteOptionForm"
import PopupUploadOption from "./UploadOptionForm"
import config from "@/config.json"
export default {
  components: {
    PopupUploadOption,
    PopupDelete,
    PopupUpdate,
    popupCreateOption
  },
  data() {
    return {
      firstLoad: true,
      options: {},
      allOption: [],
      fieldOptions: [],
      defaultConfig: [],
      page: 1,
      search: '',
      totalItem: 100,
      pageCount: 0,
      loading: false,
      config: [],
      optionHeaders: [
        { text: "Name", align: "left", sortable: true, value: "name" },
        { text: "Type", align: "center", sortable: false, value: "type" },
        { text: "Created at", align: "left", sortable: true, value: "created_at" },
        { text: "Updated at", align: "left", sortable: true, value: "updated_at" },
        { text: "", value: "action", sortable: false, align: "end" }
      ],
      listOption: []
    };
  },
  computed: {
    ...mapState("auth", ["currentUser"])
  },
  watch: {
    options: {
      handler () {
        if (!this.firstLoad) this.getOption()
      },
      deep: true
    }
  },
  mounted() {
    this.getOption()
    this.getAllOption()
  },
  created() {
    if (!this.currentUser.is_admin) this.$router.push({ path: '/app' })
  },
  methods: {
    fetchData () {
      this.getOption()
      this.getAllOption()
    },
    async getOption () {
      try {
        this.loading = true
        this.listOption = []
        const res = await getOption(this.getParam())
        this.listOption = res.data.data
        this.totalItem = res.data.total
        this.pageCount = res.data.last_page
      } catch (e) {
      } finally {
        this.firstLoad = false
        this.loading = false
      }
    },
    async getAllOption () {
      try {
        this.loading = true
        this.allOption = []
        const res = await getOption({per_page: 'all'})
        this.allOption = res.data
      } catch (e) {
      } finally {
        this.loading = false
      }
    },
    getParam () {
      let sort
      if (this.options.sortBy.length && this.options.sortBy.length) {
        if (this.options.sortDesc[0]) sort = this.options.sortBy[0]
        else sort = '-' + this.options.sortBy[0]
      }
      return {per_page: this.options.itemsPerPage, page: this.options.page, sort: sort, search: this.search}
    },
    debounceInput: debounce(function (e) {
      this.getOption()
    }, 500),
    downloadData(name, extension) {
      let newWindow = window.open(config.host + '/imports/' + name + '.' + extension, '_blank')
      newWindow.focus()
    },
    convertDate (date) {
      return convertDate.dateFormatted(date)
    }
  },
};
</script>
<style scoped>
/deep/
.v-chip__content {
  width: 180px !important;
}
/deep/
.v-data-table > .v-data-table__wrapper tbody tr.v-data-table__expanded__content {
  box-shadow: inset 0px 4px 6px -5px rgb(50 50 50 / 75%),
  inset 0px -4px 8px -5px rgb(50 50 50 / 0%);
}
/deep/
.order-table th {
  background-color: var(--v-primary-base) !important;
  color: white !important;
}
/deep/
.list-image th {
  background-color: #6686a3 !important;
}
/deep/
.v-data-table__wrapper {
  border: 1px lightgrey solid;
  border-radius: 8px;
}
</style>
