<template>
  <v-dialog
      v-model="createUserDialog"
      max-width="500"
      persistent
  >
    <template v-slot:activator="{ on, attrs }">
      <div style="border: #fafafa 2px solid; border-radius: 4px; margin-top: -4px" class="mr-1" id="btn-create-user">
        <v-btn
            v-bind="attrs"
            v-on="on"
            width="250"
            min-width="0"
            color="info"
            class="elevation-0">
          <v-icon class="mr-1">mdi-account-plus-outline</v-icon>
          Create new user
        </v-btn>
      </div>
    </template>
    <user-form :loading.sync="loading" ref="userForm" :passwordRequired="true" :roles="roles" :submit="submit" :closeDialog="closeDialog" title="Create user"/>
  </v-dialog>
</template>

<script>


import UserForm from "@/components/home/management/user/userManagement/UserForm";
import {createUser} from "@/backend";

export default {
  name: "PopupCreateUser",
  components: {UserForm},
  data() {
    return {
      createUserDialog: false,
      loading: false
    }
  },
  props: {
    roles: {type: Array, default: () => []},
  },
  methods: {
    async submit(data) {
      try {
        this.loading = true
        await createUser(data)
        this.$store.commit('message/SHOW_SUCCESS', 'Success')
        this.$emit('getUser')
        this.$refs.userForm.resetData()
        this.createUserDialog = false
      } catch (e) {
      } finally {
        this.loading = false
      }
    },
    closeDialog() {
      this.createUserDialog = false
    }
  }
}
</script>

<style scoped>

</style>
